import { Theme } from "common";

export const outputTableStyles = {
  fixHeader: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
  },
  blueHeader: {
    "& .MuiTableCell-head": {
      backgroundColor: Theme.palette.primary.light,
      color: Theme.palette.text.primary,
      fontWeight: 600,
    },
    "& .MuiTableCell-root": {
      alignSelf: "left",
    },
  },
  actionButton: {
    "& .MuiButtonRoot": {
      height: "40px",
    },
  },
  buttonLink: {
    textDecoration: "none",
  },
};
