import { useTranslation } from "react-i18next";
import {
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Link as MuiLink,
  Paper as MuiPaper,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  TableCell as MuiTableCell,
} from "@mui/material";
import { tableStyes } from "./Table.styles";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";

/**
 * Generate Table.
 *
 * @param {label} props
 *   Table Aria Label.
 * @param {header} props
 *   Array of Header Elements.
 * @param {body} props
 *   Object containing key and object of rows data.
 * @param {isElementLinkable} props
 *   XXX - documentaiton needed
 * @param {tableClass} props
 *   Table class for adding additional style from parent component.
 * @param {tooltipColIndex} props
 *   Prop to show tootltip on the column index given.
 * @param {sticky} props
 *   Prop to make table header sticky.
 * @param {objectFilter} props
 *   Prop to make know if table row data is object, with key to get the row data.
 * @param {loading} props
 *   Diaplays a loading indicator.
 */
const CustomTable = (props) => {
  const { t } = useTranslation();

  let header = null;
  if (props.header) {
    header = Object.entries(props.header).map((resHeader) => {
      return <MuiTableCell key={resHeader[0]}>{resHeader[1]}</MuiTableCell>;
    });
  }

  let body = null;
  if (props.loading) {
    let cols = props.header || { x: "" };
    body = (
      <MuiTableCell colSpan={Object.keys(cols).length} align="center">
        {t("globals.list.messages.fetchingInfo")}
      </MuiTableCell>
    );
  }
  if (!props.loading && props.body) {
    /* Table Rows */
    body = Object.entries(props.body).map((row, rowIndex, arr) => {
      let rowKey = row[0],
        rowVal = row[1];
      return (
        <MuiTableRow key={rowKey}>
          {/* Table Cells */}
          {Object.entries(rowVal).map((col, colIndex) => {
            let colKey = col[0],
              colVal = col[1];
            /* Check if tootlip index given */
            let elementValue = colVal, // the int, wrapped
              cellValue = colVal, // the int
              linkWhenZero = false;

            // Get The object value if objectFilter is passed in props.
            if (props.objectFilter && props.objectFilter[colKey]) {
              if (colVal.toolTipText) {
                elementValue = wrapWithToolTip(
                  colVal[props.objectFilter[colKey]],
                  colVal.toolTipText
                );
              } else {
                elementValue = colVal[props.objectFilter[colKey]];
              }
              linkWhenZero = !!colVal.linkWhenZero;
              cellValue = colVal[props.objectFilter[colKey]];
            } else if (
              props.tooltipColIndex &&
              colIndex === +props.tooltipColIndex
            ) {
              elementValue = wrapWithToolTip(colVal, colVal);
            }

            return (
              <MuiTableCell key={colIndex}>
                {/* Check if Value is Integer, Not 0, Not last row and Elements are linkable */}
                {Number.isInteger(cellValue) &&
                (cellValue > 0 || linkWhenZero) &&
                props.label !== "summary" &&
                arr.length - 1 !== rowIndex &&
                props.isElementLinkable ? (
                  <MuiLink
                    component="button"
                    onClick={() =>
                      props.isElementLinkable(rowKey, colKey, props.label, row)
                    }
                    sx={{ textDecoration: "none" }}
                  >
                    {elementValue}
                  </MuiLink>
                ) : (
                  elementValue
                )}
              </MuiTableCell>
            );
          })}
        </MuiTableRow>
      );
    });
  }

  return (
    <MuiTableContainer sx={tableStyes.container} component={MuiPaper}>
      <MuiTable
        stickyHeader={props.sticky}
        size="small"
        aria-label={props.label}
        sx={props.tableClass ? props.tableClass : null}
      >
        <MuiTableHead sx={whiteTableHeaderLabel}>
          <MuiTableRow>{header}</MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>{body}</MuiTableBody>
      </MuiTable>
      {props.loading && (
        <MuiBox sx={{ width: "100%" }}>
          <MuiLinearProgress />
        </MuiBox>
      )}
    </MuiTableContainer>
  );
};

const wrapWithToolTip = (text, toolTipText, placement = "right") => {
  return (
    <MuiTooltip title={toolTipText} placement={placement}>
      <MuiTypography
        variant="body2"
        sx={{ fontSize: "14px" }}
        component="span"
        noWrap
      >
        {text}
      </MuiTypography>
    </MuiTooltip>
  );
};

export default CustomTable;
