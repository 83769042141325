import { Theme } from "common";

export const sendInvitationStyles = {
  stepperWrapper: { width: "100%" },

  stepContentWrapper: {
    width: "100%",
  },

  dialogTitleStyle: {
    padding: "16px 24px 0px 24px",
  },

  dialogContentStyle: {
    padding: "16px 24px",
  },

  stepperStyle: {
    padding: "14px 24px 14px 5px",
    marginRight: "0px",
    marginTop: "15px",
    backgroundColor: Theme.palette.common.white,
  },

  tableContainerStyle: {
    "& .MuiTableCell-head": {
      backgroundColor: Theme.palette.primary.light,
      color: Theme.palette.text.primary,
    },
  },

  dialogPaperWidthStyle: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "700px",
      maxHeight: "100%",
      backgroundColor: "#f0f0f0",
    },
  },

  tableMaxHeight: {
    margin: "0px",
  },

  tableHeaderBackgroundColor: {
    "& .MuiTableCell-stickyHeader": {
      background: Theme.palette.primary.light,
    },
    "& .MuiTableCell-head": {
      color: Theme.palette.text.primary,
      padding: "16px 16px",
      fontSize: "15px",
      fontWeight: "600",
      whiteSpace: "pre-line",
    },
    "& .MuiTableCell-root": {
      padding: "10px 16px",
    },
  },

  dialogActionsStyle: {
    padding: "16px 24px 16px 0px",
    "& .MuiButton-root": {
      width: "84px",
      lineHeight: "1.25",
    },
  },

  dialogActionsButtonsFlexDiv: {
    display: "flex",
    flex: "1 0 0",
  },

  topEmailDivStyled: {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px",
  },
  emailFormDivStyled: {
    display: "grid",
    rowGap: "20px",
    justifyContent: "space-between",
    gridTemplateColumns: "1fr",
  },
  singleFieldStyle: {
    width: "50%",
  },

  inputStyle: {
    backgroundColor: Theme.palette.common.white,
    borderRadius: "4px",
  },
  helperTextStyle: {
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        backgroundColor: "#f0f0f0",
      },
    },
  },

  disabledTextField: {
    width: "50%",
    "& .MuiInputBase-root.Mui-disabled": {
      color: Theme.palette.text.primary,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: Theme.palette.text.secondary,
    },
  },
  emailFieldsDivPreviewStyle: {
    padding: "10px",
  },
  emailMessagePreviewStyle: {
    height: "501.73px",
    margin: 0,
  },
  previewMessageLoadingStyle: {
    paddingTop: "16px",
  },

  emailFieldListPreviewStyle: {
    display: "grid",
    gridTemplateColumns: "max-content auto",
    margin: 0,
  },
  emailFieldLabelPreviewStyle: {
    gridColumnStart: "1",
  },
  emailFieldValuePreviewStyle: {
    gridColumnStart: "2",
    fontWeight: "bold",
  },
};
