import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid2 as MuiGrid,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  RemoveCircle as RemoveCircleIcon,
  CloudDownload as MuiCloudDownloadIcon,
  Search as MuiSearchIcon,
} from "@mui/icons-material";
/** Custom Components */
import { FacultyDetails } from "./FacultyDetails";
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
import { useSetLoading } from "app/shared/ui/loadingState";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
import { Spinner } from "app/shared/ui/Loading/Spinner";
import { sharedStyles } from "app/shared/ui/sharedStyles";
/** Services */
import {
  loadFacultyList,
  saveFacultySelection,
  getFacultyExportData,
  getSubmissionsList,
  deleteParticipatingFaculty,
} from "app/services/facultyService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useFacultyDemographicsContext } from "app/services/facultyDemographicsContext";
import { getSubmission } from "app/services/submissionService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";

// ************************** Faculty Demographics **************************
export const Faculty = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // Submission Autocomplete
  const [submissionList, setSubmissionList] = useState([]);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmissionId = getSelectedSubmission();
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  // Participating Faculty Autocomplete
  const [facultyList, setFacultyList] = useState([]);
  const [summary, setSummary] = useState("");
  const [hasFacultyAccess, setFacultyAccess] = useState({
    add: false,
    remove: false,
  });

  const [data, setData] = useState([]);
  const [snackbar, setSnackBar] = useState({ show: false, message: "" });

  const { setAlert, clearAlert } = useAlerts();
  //eslint-disable-next-line
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [exportLoading, setExportLoading] = useState(false);
  const [confirmation, setConfirmation] = useState({
    type: "",
    confirm: false,
    url: "",
    name: "",
    submissionName: "",
  });

  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  const {
    facultyPreference,
    setFacultyPreference,
    updatePreferencesForSubmission,
  } = useFacultyDemographicsContext();

  // *** On Page load, populate Submissions Dropdown Options ***
  useEffect(() => {
    getSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  /*
   * Fetch the details for selected Submission
   * and assign Add/Remove Participating access
   */

  useEffect(() => {
    if (selectedSubmissionId)
      getSubmission(
        selectedSubmissionId,
        setFacultyAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Faculty",
        getSelectedSubmissionName,
        t
      );
    // eslint-disable-next-line
  }, [selectedSubmissionId, setFacultyAccess]);

  // **** Submission Dropdown Value Change ****
  const handleChangeSubmission = () => {
    updatePreferencesForSubmission(selectedSubmissionId);
  };

  // **** Add Participating Faculty Autocomplete Value ****
  const handleChangeAutoComplete = (event) => {
    let facultyVal = event.target.value.trim();

    loadFacultyList(
      facultyVal,
      setFacultyList,
      setAlert,
      clearAlert,
      setNoOptionsText,
      t,
      selectedSubmissionId
    );
  };

  // **** Save Faculty on Selecting Value from Add Participating Faculty ****
  const handleFacultySelection = (e, value) => {
    setSnackBar({ show: false, message: "" });
    if (value) {
      saveFacultySelection(
        setSnackBar,
        setFacultyPreference,
        facultyPreference,
        value.value.universityId,
        getSelectedSubmission(),
        setAlert,
        clearAlert,
        setLoadingItems,
        t
      );
    }
    setFacultyList([]);
    setNoOptionsText(t("globals.autocomplete.helperText"));
  };

  // *** Export CSV file of Table Data ***
  const handleExport = () => {
    getFacultyExportData(
      getSelectedSubmission(),
      facultyPreference,
      setAlert,
      clearAlert,
      summary.totalCount,
      setExportLoading
    );
  };

  // **** Delete Participating Faculty(s) after confirmation ****
  const handleDeleteAction = () => {
    deleteParticipatingFaculty(
      confirmation.type,
      confirmation.url,
      setSnackBar,
      setFacultyPreference,
      facultyPreference,
      setAlert,
      clearAlert,
      setLoadingItems,
      t
    );
    setConfirmation({
      type: "",
      confirm: false,
      url: "",
      name: "",
      submissionName: "",
    });
  };

  const getSubmissionName = () => {
    let selectedSubmissionObject = submissionList.find((submission) => {
      return (
        submission._links.self.href.split("/").pop() === selectedSubmissionId
      );
    });
    return `${selectedSubmissionObject.name}`;
  };
  // **** Function to open confirmation dialog, on click of Delete Icon/Remove All Button ****
  const handleDelete = (deleteType, url, name) => {
    if (deleteType === "All") {
      let selectedSubmissionObject = submissionList.find((submission) => {
        return (
          submission._links.self.href.split("/").pop() === selectedSubmissionId
        );
      });
      name = `${selectedSubmissionObject.name}, ${selectedSubmissionObject.year}`;
    }
    const submissionName = getSubmissionName();
    setSnackBar({ show: false, message: "" });
    setConfirmation({
      type: deleteType,
      confirm: true,
      url: url,
      name: name,
      submissionName: submissionName,
    });
  };

  const dialogConfirmLabel = "faculty.delete.confirmationBox";

  const displayText = (option) => {
    const displaytextParts = [];

    displaytextParts.push(option.value.rank);
    if (option.value.department) {
      displaytextParts.push(option.value.department.name);
    }
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    return displaytextParts.join(", ");
  };

  return (
    <MuiGrid container wrap="nowrap" justify="flex-start" direction="column">
      {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
      <Spinner
        size="50px"
        visible={loading}
        style={sharedStyles.pageSpinnerStyles}
      />
      <MuiGrid size={12} pb="16px">
        <MuiGrid size={3.5}>
          <SubmissionSelect
            submissionList={submissionList}
            handleChangeSubmission={handleChangeSubmission}
          />
        </MuiGrid>
      </MuiGrid>
      <MuiGrid size={12} pb="16px">
        <MuiGrid container direction="row" justify="space-between">
          <MuiGrid size={3.5}>
            <MuiAutocomplete
              id="participating-faculty-combo"
              value={null}
              blurOnSelect={true}
              disabled={!hasFacultyAccess.add}
              options={facultyList}
              filterOptions={(option, state) => option}
              getOptionDisabled={(option) => option.isDummy}
              disabledItemsFocusable={true}
              getOptionLabel={(option) => ""}
              isOptionEqualToValue={(option, value) =>
                parseInt(option.value.universityId) ===
                parseInt(value.value.universityId)
              }
              noOptionsText={noOptionsText}
              onChange={(e, value) => handleFacultySelection(e, value)}
              onClose={() => {
                setFacultyList([]);
              }}
              popupIcon={<MuiSearchIcon />}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t("faculty.mainView.participatingFaculty")}
                  onChange={handleChangeAutoComplete}
                  variant="outlined"
                />
              )}
              renderOption={(props, state, { inputValue }) => (
                <AutocompleteOption
                  option={props}
                  inputValue={inputValue}
                  state={state}
                >
                  {displayText(state)}
                </AutocompleteOption>
              )}
              sx={{
                "& .MuiAutocomplete-popupIndicatorOpen": {
                  // Stop MUI from rotating the icon by rotating it back
                  rotate: "180deg",
                },
                "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                  // Move dropdown arrow and clear X up to horizontal center
                  height: "auto",
                },
                "& .MuiInputLabel-root, .MuiInputBase-input": {
                  fontSize: "14.5px",
                },
              }}
            />
          </MuiGrid>
          <MuiGrid size="grow" display="flex" justifyContent="end">
            {hasFacultyAccess.remove ? (
              <MuiButton
                variant="outlined"
                color="secondary"
                sx={{
                  height: "40px",
                  alignSelf: "flex-end",
                  marginLeft: "24px",
                }}
                disabled={!(data && data.length > 0)}
                onClick={() =>
                  handleDelete(
                    "All",
                    `/submissions/${getSelectedSubmission()}/faculty`
                  )
                }
              >
                <RemoveCircleIcon />
                &nbsp;{t("faculty.delete.deleteAllButtonText")}
              </MuiButton>
            ) : null}

            <MuiButton
              size="small"
              variant="outlined"
              color="secondary"
              sx={{
                height: "40px",
                alignSelf: "flex-end",
                marginLeft: "24px",
              }}
              onClick={handleExport}
              title={summary.exportMessage}
              disabled={exportLoading || loading || summary.totalCount === 0}
            >
              <Spinner
                visible={exportLoading}
                style={sharedStyles.exportSpinnerStyles}
              />
              <MuiCloudDownloadIcon />
              &nbsp;{t("faculty.export.exportButtonText")}
            </MuiButton>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
      <MuiGrid pb="16px">
        <FacultyDetails
          handleDelete={handleDelete}
          navigate={navigate}
          setAlert={setAlert}
          clearAlert={clearAlert}
          setLoading={setLoadingItems}
          data={data}
          setData={setData}
          summary={summary}
          setSummary={setSummary}
        />
      </MuiGrid>

      <ConfirmationDialog
        handleCancel={() =>
          setConfirmation({
            ...confirmation,
            confirm: false,
          })
        }
        handleOk={() => handleDeleteAction()}
        open={confirmation.confirm}
        title={t(
          `${dialogConfirmLabel}.delete${
            confirmation.type === "All" ? "All" : ""
          }Record.title`
        )}
        message={t(
          `${dialogConfirmLabel}.delete${
            confirmation.type === "All" ? "All" : ""
          }Record.body`,
          {
            name: confirmation.name,
          }
        )}
        okLabel={t(
          `${dialogConfirmLabel}.delete${
            confirmation.type === "All" ? "All" : ""
          }Record.okLabel`
        )}
        cancelLabel={t(
          `${dialogConfirmLabel}.delete${
            confirmation.type === "All" ? "All" : ""
          }Record.cancelLabel`
        )}
      />
    </MuiGrid>
  );
};
