import {
  AppBar as MuiAppBar,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Typography as MuiTypography,
  Box as MuiBox,
} from "@mui/material";
import PropTypes from "prop-types";
import { tabStyles } from "./Tabs.styles";

/**
 * Generate Tab Panels.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MuiBox>
          <MuiTypography component="div">{children}</MuiTypography>
        </MuiBox>
      )}
    </div>
  );
}

/**
 * Table Pane Prop validation using prop-types integeration.
 */
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

/**
 * Mandatory Props to be passed with each tab.
 */
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

/**
 * Generic UI Tab Function overriding Material UI Tabs.
 *
 * @param {value} value
 *   Current Tab Selected Value.
 * @param {handleChange} handleChange
 *   Tab Change handler event.
 * @param {data} data
 *   Data array to generate the tabs.
 */
const CustomTabs = ({ data, handleChange, value }) => {
  let tabs = [];
  let tabPanels = [];
  if (data) {
    for (let value in data) {
      tabs.push(value);
      tabPanels.push(data[value]);
    }
  }

  return (
    <>
      <MuiAppBar position="static" sx={tabStyles.root}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="Output Table 1"
        >
          {tabs.map((tab, index) => {
            return <MuiTab key={index} label={tab} {...a11yProps(index)} />;
          })}
        </MuiTabs>
      </MuiAppBar>
      {tabPanels.map((tabPanel, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {tabPanel}
          </TabPanel>
        );
      })}
    </>
  );
};

export default CustomTabs;
