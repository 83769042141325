import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid2 as MuiGrid,
  CircularProgress as MuiCircularProgress,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  CloudDownload as MuiCloudDownloadIcon,
  Search as MuiSearchIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
/** Custom Components */
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { TraineeDetails } from "./TraineeDetails/TraineeDetails";
import { useSetLoading } from "app/shared/ui/loadingState";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
import { RemoveTrainees } from "./DeleteTrainees";
import { Spinner } from "app/shared/ui/Loading/Spinner";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  traineeTypeDropDown,
} from "app/shared/constants";
/** Services */
import {
  setTraineeSubmissions,
  postAddTrainee,
  getTraineeList,
  getTraineeExportData,
} from "app/services/traineeService";
import { useTraineeContext } from "app/services/traineeContext";
import { getSubmission } from "app/services/submissionService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import {
  useTraineesSelectedContext,
  SelectAllStates,
} from "app/services/traineesSelectedContext";
/* Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { traineeStyles } from "../Trainees/Trainees.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

export const Trainees = (props) => {
  const navigate = useNavigate();
  const [submissionList, setSubmissionList] = useState([]);
  const [snackbar, setSnackbar] = useState({ show: false, message: "" });

  const { t } = useTranslation();

  const [refreshTraineesID, setRefreshedTraineeID] = useState(0);

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmissionId = getSelectedSubmission();
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { NONE_SELECTED } = SelectAllStates;
  const [, setError] = useState(false);
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [addOptionsloading, setAddOptionsloading] = useState(false);
  const [key, setKey] = useState(`add-${new Date().toString()}`);

  const [hasAddTraineeAccess, setHasAddTraineeAccess] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const {
    traineePreferences,
    setTraineePreferences,
    updatePreferencesForSubmission,
  } = useTraineeContext();
  const { resetAllSelections } = useTraineesSelectedContext();
  const [summary, setSummary] = useState({});

  const [setTraineeList] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const { setAlert, clearAlert } = useAlerts();
  const { traineesSelected } = useTraineesSelectedContext();
  const [openRemoveTraineesDialog, setOpenRemoveTraineesDialog] =
    useState(false);
  const [traineesRemoved, toggleTraineesRemoved] = useState(false);
  let timeoutID; // ID for API debounce timeout

  useEffect(
    () => {
      setTraineeSubmissions(setSubmissionList, setAlert, clearAlert);
    },
    // eslint-disable-next-line
    []
  );

  //New getSubmission implementation
  useEffect(() => {
    resetAllSelections();
    if (selectedSubmissionId)
      getSubmission(
        selectedSubmissionId,
        setHasAddTraineeAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Trainee",
        getSelectedSubmissionName,
        t
      );
    // eslint-disable-next-line
  }, [selectedSubmissionId, setHasAddTraineeAccess]);
  const handleChangeSubmission = () => {
    // To reload the Filters on the basis of Updated Submission Id
    updatePreferencesForSubmission(selectedSubmissionId);
  };

  const handleChangeAutoComplete = (event) => {
    const traineeVal = event.target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(() => {
      if (traineeVal.length >= AUTOCOMPLETE_MIN_INPUT) {
        setTrainees([]);

        getTraineeList(
          traineeVal,
          15,
          setTrainees,
          setAlert,
          clearAlert,
          setAddOptionsloading,
          selectedSubmissionId,
          "trainees",
          t
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };

  const handleTraineeSelection = (e, value) => {
    setSnackbar({ show: false, message: "" });
    if (value) {
      setError(false);
      postAddTrainee(
        setSnackbar,
        getSelectedSubmission(),
        value.value,
        setRefreshedTraineeID,
        setTraineePreferences,
        traineePreferences,
        setAlert,
        clearAlert,
        setLoadingItems,
        setKey,
        t
      );
    }
  };

  const handleExport = () => {
    getTraineeExportData(
      getSelectedSubmission(),
      traineePreferences,
      summary.totalCount,
      setAlert,
      clearAlert,
      setExportLoading
    );
  };
  const disableAllTrainees = (e) => {
    clearAlert();
    setOpenRemoveTraineesDialog(true);
  };

  const displayText = (option) => {
    const traineeStartYear = new Date(
      option.value?.appointmentStartDate
    ).getFullYear();
    const traineeEndYear = new Date(
      option.value?.appointmentEndDate
    ).getFullYear();

    const displaytextParts = [];

    displaytextParts.push(t(traineeTypeDropDown[option.value.traineeType]));
    if (option.value.department) {
      displaytextParts.push(option.value.department.name);
    }
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    if (traineeStartYear && traineeEndYear) {
      return `${displaytextParts.join(
        ", "
      )} (${traineeStartYear}-${traineeEndYear})`;
    }

    return displaytextParts.join(", ");
  };

  return (
    <>
      {openRemoveTraineesDialog && (
        <RemoveTrainees
          openRemoveTraineesDialog={openRemoveTraineesDialog}
          setOpenRemoveTraineesDialog={setOpenRemoveTraineesDialog}
          toggleTraineesRemoved={toggleTraineesRemoved}
          setLoading={setLoadingItems}
          setTraineeList={setTraineeList}
          totalCount={summary.totalActiveCount}
        />
      )}

      <MuiGrid container wrap="nowrap" justify="flex-start" direction="column">
        {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
        <Spinner
          size="50px"
          visible={loading}
          style={sharedStyles.pageSpinnerStyles}
        />
        <MuiGrid size={12} pb="16px">
          <MuiGrid size={3.5}>
            <SubmissionSelect
              submissionList={submissionList}
              handleChangeSubmission={handleChangeSubmission}
            />
          </MuiGrid>
        </MuiGrid>

        <MuiGrid pb="16px">
          <MuiGrid container direction="row" justify="space-between">
            <MuiGrid size={3.5}>
              <MuiAutocomplete
                key={key}
                blurOnSelect={true}
                clearOnBlur={true}
                openOnFocus={false}
                value={null}
                id="add-trainee-combo"
                disabled={!hasAddTraineeAccess || loading}
                loading={addOptionsloading}
                options={trainees.count ? trainees.values : []}
                filterOptions={(option, state) => option}
                getOptionDisabled={(option) => option.isDummy}
                disabledItemsFocusable={true}
                getOptionLabel={(option) => option.value.fullName}
                noOptionsText={t("globals.autocomplete.helperText")}
                defaultValue={null}
                onChange={(e, value) => handleTraineeSelection(e, value)}
                onClose={() => {
                  setTrainees([]);
                }}
                popupIcon={<MuiSearchIcon />}
                sx={sharedStyles.autocompleteRoot}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t("trainee.mainView.addTrainee")}
                    variant="outlined"
                    onChange={handleChangeAutoComplete}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addOptionsloading ? (
                            <MuiCircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, state, { inputValue }) => (
                  <AutocompleteOption
                    option={props}
                    inputValue={inputValue}
                    state={state}
                  >
                    {displayText(state)}
                  </AutocompleteOption>
                )}
              />
            </MuiGrid>
            <MuiGrid size="grow" display="flex" justifyContent="end">
              <MuiButton
                variant="outlined"
                color="secondary"
                sx={traineeStyles.exportButton}
                onClick={() => handleExport()}
                title={summary.exportMessage}
                disabled={exportLoading || loading || summary.totalCount === 0}
              >
                <MuiCloudDownloadIcon sx={traineeStyles.downloadIcon} />
                <Spinner
                  visible={exportLoading}
                  style={sharedStyles.exportSpinnerStyles}
                />
                &nbsp;{t("faculty.export.exportButtonText")}
              </MuiButton>
              <MuiButton
                variant="contained"
                sx={traineeStyles.button}
                disabled={
                  (traineesSelected.selectAllState === NONE_SELECTED &&
                    Object.keys(traineesSelected?.individualSelections ?? [])
                      .length === 0) ||
                  (traineesSelected.selectAllState !== NONE_SELECTED &&
                    Object.keys(traineesSelected?.individualSelections ?? [])
                      .length === summary.totalActiveCount)
                }
                onClick={(e) => disableAllTrainees(e)}
              >
                &nbsp;&nbsp;
                {t("trainee.mainView.removeAll.label")}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid>
          <TraineeDetails
            refreshTrainees={refreshTraineesID}
            filterData={props.filterData}
            navigate={navigate}
            setAlert={setAlert}
            clearAlert={clearAlert}
            traineesRemoved={traineesRemoved}
            hasAddTraineeAccess={hasAddTraineeAccess}
            summary={summary}
            setSummary={setSummary}
            setTraineeList={setTraineeList}
            setLoadingItems={setLoadingItems}
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
