export const tabStyles = {
  root: {
    backgroundColor: "#F4F4F4",
    "& .MuiTab-wrapper": {
      letterSpacing: 2,
    },
    "& .MuiTab-root": {
      fontWeight: 700,
      color: "rgba(0, 0, 0,0.54)",
      fontSize: 14,
      letterSpacing: 2,
    },
    "& .Mui-selected": {
      color: "#007C92",
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#007C92",
    },
  },
};
