import { Theme } from "common";

export const outputTableStyles = {
  blueHeader: {
    "& .MuiTableCell-head": {
      backgroundColor: Theme.palette.primary.light,
      color: Theme.palette.text.primary,
      fontWeight: 700,
    },
    "& tbody tr:last-child td.noData": {
      fontWeight: Theme.typography.fontWeightRegular,
      backgroundColor: Theme.palette.background.paper,
    },
    "& tbody tr:last-child td": {
      fontWeight: Theme.typography.fontWeightBold,
      backgroundColor: Theme.palette.grey[300],
    },
  },
  fixHeader: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
    height: "fit-content",
  },
  actionButton: {
    "& button": {
      height: "40px",
    },
  },
};
