import {
  Dialog as MuiDialog,
  Button as MuiButton,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
} from "@mui/material";

export const ConfirmationDialog = (props) => {
  const { open, title, message, okLabel, cancelLabel, handleCancel, handleOk } =
    props;

  return (
    <MuiDialog open={open} maxWidth="xs">
      <MuiDialogTitle id="alert-dialog-title">{title || ""}</MuiDialogTitle>
      <MuiDialogContent>
        <MuiDialogContentText
          id="alert-dialog-description"
          sx={{ whiteSpace: "pre-line" }}
          component="div"
        >
          {message || ""}
        </MuiDialogContentText>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={handleCancel} color="primary">
          {cancelLabel || "CANCEL"}
        </MuiButton>
        <MuiButton onClick={handleOk} color="primary">
          {okLabel || "OK"}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
