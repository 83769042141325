import { Theme } from "common";

export const grantDetailsStyles = {
  actionButton: {
    width: "30px",
    padding: "5px 0px 0px 0px",
    "& .MuiIconButton-root": {
      fontSize: "0.5rem",
    },
  },
  disableRow: {
    color: Theme.palette.grey[300],
  },
  noData: {
    textAlign: "center",
    color: Theme.palette.grey[500],
  },
  collapseWidth: {
    width: "48%",
  },
  collapseIcon: {
    padding: "0px 0px 2px 0px",
  },
  collapseRowBackgroundColor: {
    backgroundColor: Theme.palette.primary.light,
    "& .MuiTableCell-head": {
      color: Theme.palette.grey[700],
      fontSize: 13,
      fontWeight: 600,
    },
    "& .MuiTableCell-body": {
      borderBottom: "unset",
      fontWeight: 500,
    },
  },
  scroll30: {
    width: "30px",
  },
  scroll80: {
    width: "80px",
  },
  scroll90: {
    width: "90px",
  },
  scroll246: {
    width: "246px",
  },
  scroll84: {
    width: "84px",
  },
  scroll72: {
    width: "72px",
  },
  scroll154: {
    width: "154px",
  },
  scroll60: {
    width: "60px",
  },
  scroll108: {
    width: "108px",
  },
  scroll103: {
    width: "103px",
  },
};
