import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid2 as MuiGrid,
  Tooltip as MuiTooltip,
  CircularProgress as MuiCircularProgress,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  CloudDownload as MuiCloudDownloadIcon,
  Search as MuiSearchIcon,
  Email as MuiEmailIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
/** Custom Components */
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { OutcomesDetails } from "./OutcomesDetails/OutcomesDetails";
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
import { SendInvitation } from "./SendInvitation";
import { DeleteOutcomes } from "./DeleteOutcomes";
import { useSetLoading } from "app/shared/ui/loadingState";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  traineeTypeDropDown,
} from "app/shared/constants";
import { Spinner } from "app/shared/ui/Loading/Spinner";
/** Services */
import { getTraineeList } from "app/services/traineeService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { getSubmission } from "app/services/submissionService";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "app/services/outcomesSelectedContext";
import {
  setOutcomeSubmissions,
  postAddTraineeToOutcome,
  getOutcomeExportData,
} from "app/services/outcomesService";
import { useOutcomesContext } from "app/services/outcomesContext";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { outcomesStyles } from "./Outcomes.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

export const Outcomes = (props) => {
  const navigate = useNavigate();
  const [submissionList, setSubmissionList] = useState([]);
  const [summary, setSummary] = useState({});
  const [snackbar, setSnackBar] = useState({ show: false, message: "" });

  const { t } = useTranslation();
  const [refreshOutcomesID, setRefreshedOutcomeID] = useState(0);

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmissionId = getSelectedSubmission();
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [, setError] = useState(false);

  const [hasAddOutcomeAccess, setHasAddOutcomeAccess] = useState(false);
  const [openDeleteOutcomesDialog, setOpenDeleteOutcomesDialog] =
    useState(false);
  const [OutcomesDelete, toggleDeleteOutcomes] = useState(false);
  const {
    outcomesPreferences,
    setOutcomesPreferences,
    updatePreferencesForSubmission,
  } = useOutcomesContext();

  const { resetAllSelections } = useOutcomesSelectedContext();
  const [trainees, setTrainees] = useState([]);
  const { setAlert, clearAlert } = useAlerts();

  const { outcomesSelected } = useOutcomesSelectedContext();
  const { ALL_SELECTED, NONE_SELECTED } = SelectAllStates;
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [exportLoading, setExportLoading] = useState(false);
  const [addOptionsloading, setAddOptionsloading] = useState(false);
  const [key, setKey] = useState(`add-${new Date().toString()}`);

  const [openSendInvitationDialog, setOpenSendInvitationDialog] =
    useState(false);

  const [invitationsSent, toggleInvitationsSent] = useState(false);

  let timeoutID; // ID for API debounce timeout

  // **** Set Submission Value for Outcomes Submission DropDown ****
  useEffect(
    () => {
      setOutcomeSubmissions(setSubmissionList, setAlert, clearAlert);
    },
    // eslint-disable-next-line
    []
  );

  //New getSubmission implementation
  useEffect(() => {
    resetAllSelections();
    if (selectedSubmissionId)
      getSubmission(
        selectedSubmissionId,
        setHasAddOutcomeAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Outcome",
        getSelectedSubmissionName,
        t
      );
    // eslint-disable-next-line
  }, [selectedSubmissionId, setHasAddOutcomeAccess]);

  const handleChangeAutoComplete = (event) => {
    const traineeVal = event.target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(() => {
      if (traineeVal.length >= AUTOCOMPLETE_MIN_INPUT) {
        getTraineeList(
          traineeVal,
          15,
          setTrainees,
          setAlert,
          clearAlert,
          setAddOptionsloading,
          selectedSubmissionId,
          "outcomes",
          t
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };

  const handleExport = () => {
    getOutcomeExportData(
      getSelectedSubmission(),
      outcomesPreferences,
      summary.totalCount,
      setAlert,
      clearAlert,
      setExportLoading
    );
  };

  /* Update Submission and Re-render the Page,
   * Also reload the Filters on the basis of Updated Submission Id
   */
  const handleChangeSubmission = () => {
    updatePreferencesForSubmission(selectedSubmissionId);
  };

  const handleTraineeSelection = (e, value) => {
    setSnackBar({ show: false, message: "" });
    if (value) {
      setError(false);
      postAddTraineeToOutcome(
        setSnackBar,
        getSelectedSubmission(),
        value.value,
        setRefreshedOutcomeID,
        setOutcomesPreferences,
        outcomesPreferences,
        setAlert,
        clearAlert,
        setLoadingItems,
        setKey,
        t
      );
    }
  };

  const handleOpenSendInvitationDialog = (e) => {
    clearAlert();
    setOpenSendInvitationDialog(true);
  };
  const handleOpenDeleteDialog = (e) => {
    clearAlert();
    setOpenDeleteOutcomesDialog(true);
  };

  const displayText = (option) => {
    const traineeStartYear = new Date(
      option.value?.appointmentStartDate
    ).getFullYear();
    const traineeEndYear = new Date(
      option.value?.appointmentEndDate
    ).getFullYear();

    const displaytextParts = [];
    displaytextParts.push(t(traineeTypeDropDown[option.value.traineeType]));
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    if (traineeStartYear && traineeEndYear) {
      return `${displaytextParts.join(
        ", "
      )} (${traineeStartYear}-${traineeEndYear})`;
    }

    return displaytextParts.join(", ");
  };

  return (
    <>
      {openSendInvitationDialog && (
        <SendInvitation
          openSendInvitationDialog={openSendInvitationDialog}
          setOpenSendInvitationDialog={setOpenSendInvitationDialog}
          toggleInvitationsSent={toggleInvitationsSent}
          outcomesPreferences={outcomesPreferences}
          setOutcomesPreferences={setOutcomesPreferences}
        />
      )}
      {openDeleteOutcomesDialog && (
        <DeleteOutcomes
          openDeleteOutcomesDialog={openDeleteOutcomesDialog}
          setOpenDeleteOutcomesDialog={setOpenDeleteOutcomesDialog}
          toggleDeleteOutcomes={toggleDeleteOutcomes}
          totalCount={summary.totalActiveCount}
        />
      )}
      <MuiGrid container justifyContent="flex-start" direction="column">
        {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
        <Spinner
          size="50px"
          visible={loading}
          style={sharedStyles.pageSpinnerStyles}
        />
        <MuiGrid size={12} pb="16px">
          <MuiGrid size={3.5}>
            <SubmissionSelect
              submissionList={submissionList}
              handleChangeSubmission={handleChangeSubmission}
            />
          </MuiGrid>
        </MuiGrid>
        <MuiGrid pb="16px">
          <MuiGrid container direction="row" justify="space-between">
            <MuiGrid size={3.5}>
              <MuiAutocomplete
                key={key}
                blurOnSelect={true}
                clearOnBlur={true}
                openOnFocus={false}
                value={null}
                id="add-graduated-trainee-combo"
                disabled={!hasAddOutcomeAccess || loading}
                loading={addOptionsloading}
                options={trainees.count ? trainees.values : []}
                filterOptions={(option, state) => option}
                getOptionDisabled={(option) => option.isDummy}
                disabledItemsFocusable={true}
                getOptionLabel={(option) => option.value.fullName}
                noOptionsText={t("globals.autocomplete.helperText")}
                defaultValue={null}
                onChange={(e, value) => {
                  handleTraineeSelection(e, value);
                }}
                onClose={() => {
                  setTrainees([]);
                }}
                popupIcon={<MuiSearchIcon />}
                sx={sharedStyles.autocompleteRoot}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t("outcomes.mainView.addGraduatedTrainee")}
                    variant="outlined"
                    onChange={handleChangeAutoComplete}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addOptionsloading ? (
                            <MuiCircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, state, { inputValue }) => (
                  <AutocompleteOption
                    option={props}
                    inputValue={inputValue}
                    state={state}
                  >
                    {displayText(state)}
                  </AutocompleteOption>
                )}
              />
            </MuiGrid>
            <MuiGrid
              gap="8px"
              size="grow"
              display="flex"
              justifyContent="end"
              alignItems="end"
            >
              <span>
                <MuiButton
                  variant="outlined"
                  color="secondary"
                  sx={outcomesStyles.exportButton}
                  onClick={handleExport}
                  title={summary.exportMessage}
                  disabled={
                    exportLoading || loading || summary.totalCount === 0
                  }
                >
                  <Spinner
                    visible={exportLoading}
                    style={sharedStyles.exportSpinnerStyles}
                  />
                  <MuiCloudDownloadIcon />
                  &nbsp;{t("faculty.export.exportButtonText")}
                </MuiButton>
              </span>

              {hasAddOutcomeAccess && (
                <MuiTooltip
                  title={t("outcomes.mainView.sendInformationRequest.tooltip")}
                >
                  <span>
                    <MuiButton
                      variant="contained"
                      sx={outcomesStyles.button}
                      disabled={
                        outcomesSelected.selectAllState !== ALL_SELECTED &&
                        Object.keys(
                          outcomesSelected?.individualSelections ?? []
                        ).length === 0
                      }
                      onClick={(e) => handleOpenSendInvitationDialog(e)}
                    >
                      <MuiEmailIcon />
                      &nbsp;&nbsp;
                      {t("outcomes.mainView.sendInformationRequest.label")}
                    </MuiButton>
                  </span>
                </MuiTooltip>
              )}
              <span>
                <MuiButton
                  variant="contained"
                  sx={outcomesStyles.button}
                  disabled={
                    (outcomesSelected.selectAllState === NONE_SELECTED &&
                      Object.keys(outcomesSelected?.individualSelections ?? [])
                        .length === 0) ||
                    (outcomesSelected.selectAllState !== NONE_SELECTED &&
                      Object.keys(outcomesSelected?.individualSelections ?? [])
                        .length === summary.totalActiveCount)
                  }
                  onClick={(e) => handleOpenDeleteDialog(e)}
                >
                  <MuiEmailIcon />
                  &nbsp;&nbsp;
                  {t("outcomes.mainView.removeOutcomes.label")}
                </MuiButton>
              </span>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid>
          <OutcomesDetails
            refreshOutcomes={refreshOutcomesID}
            filterData={props.filterData}
            navigate={navigate}
            setAlert={setAlert}
            clearAlert={clearAlert}
            invitationsSent={invitationsSent}
            OutcomesDelete={OutcomesDelete}
            hasAddOutcomeAccess={hasAddOutcomeAccess}
            setSummary={setSummary}
            setLoadingItems={setLoadingItems}
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
