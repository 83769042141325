import axios from "axios";
import {
  SUB_OPERATION_STATUS,
  MAX_CSV_EXPORT_RECORDS,
} from "app/shared/constants";

/**
 * API call to get submission List
 * @param {*} setSubmissionList
 */
export const getSubmissionsList = async (
  setSubmissionList,
  setLoadingSubmission,
  setAlert,
  clearAlert
) => {
  try {
    setLoadingSubmission(true);
    clearAlert();
    const request = {
      url: `/submissions`,
      params: {
        vw: "brief",
        ps: 500,
      },
    };
    const response = await axios(request);
    const { values = [], count = 0 } = response.data;
    if (values.length > 0 && count > 0) {
      // Filter only the submissions with operation status as READY
      const readySubmissions = values.filter(
        (sub) => sub.operationStatus === SUB_OPERATION_STATUS.READY
      );
      setSubmissionList(readySubmissions);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoadingSubmission(false);
  }
};

export const getFacultyNames = async (submissionId, setFacultyNames) => {
  try {
    const request = {
      url: `/submissions/${submissionId}/faculty/faculty-names`,
    };

    const response = await axios(request);

    setFacultyNames(response.data);
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {*} setData
 * @param {*} setTotalCount
 * @param {*} setIsLoading
 * @param {*} selectedSubmission
 * @param {*} grantsPreference
 * @param {*} history
 */
export const getGrantsDetails = async (
  setData,
  setSummary,
  setIsLoading,
  selectedSubmission,
  grantsPreference,
  history,
  setAlert,
  clearAlert,
  setMetaData,
  getSelectedSubmissionName,
  t
) => {
  const { filters, pagination, sort } = grantsPreference.grants;
  const {
    title,
    grantNumber,
    projectStartDateMin: projectStartDateFrom,
    projectStartDateMax: projectStartDateTo,
    projectEndDateMin: projectEndDateFrom,
    projectEndDateMax: projectEndDateTo,
    principal,
    grantRole,
  } = filters;
  const { page, pageSize } = pagination;
  const { orderBy, order } = sort;
  try {
    clearAlert();
    setIsLoading(true);

    let request = "";
    let response = "";
    if (selectedSubmission) {
      request = {
        url: `/submissions/${selectedSubmission}/grants`,
        params: {
          title: title?.title ?? "",
          grantNumber,
          projectStartDateFrom,
          projectStartDateTo,
          projectEndDateFrom,
          projectEndDateTo,
          principal,
          grantRole,
          p: page + 1,
          ps: pageSize || 10,
          s: `${orderBy ? `${orderBy}` : `title`}:${
            order ? `${order}` : `asc`
          }`,
        },
      };
      response = await axios(request);

      setMetaData(response.data.meta);
      setData(response.data.values ?? []);
      const exportMessage =
        response.data.totalCount > MAX_CSV_EXPORT_RECORDS
          ? t("globals.metaDataInfo.exportTitleTooMany")
          : t("globals.metaDataInfo.exportTitleNormal");
      setSummary({
        totalCount: response.data.totalCount,
        exportMessage: exportMessage,
      });
    } else {
      setMetaData(false);
      setData([]);
      setSummary({
        totalCount: 0,
        exportMessage: t("globals.metaDataInfo.noData"),
      });
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setIsLoading(false);
  }
};

/**
 *
 * @param {*} setTitleAwardList
 * @param {*} setProjectPeriodList
 * @param {*} setGrantPrincipal
 * @param {*} setGrantRoleList
 * @param {*} selectedSubmission
 */
export const getGrantsFilterOptionsValue = async (
  setTitleList,
  setAwardList,
  setProjectPeriodList,
  selectedSubmission,
  history,
  setAlert,
  clearAlert,
  getSelectedSubmissionName,
  t
) => {
  try {
    clearAlert();
    if (selectedSubmission) {
      const response = await axios({
        url: `/submissions/${selectedSubmission}/grants/filters`,
        method: "GET",
      });

      const { grantNumbers, grants, grantsProjectPeriod } = response.data;
      setTitleList(grants?.values ?? []);
      setAwardList(grantNumbers?.values ?? []);
      setProjectPeriodList(grantsProjectPeriod);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  }
};

export const getGrantsById = async (
  submissionId,
  grantId,
  setGrant,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const request = {
      url: `/submissions/${submissionId}/grants/${grantId}`,
    };

    const response = await axios(request);
    if (response.data) {
      setGrant(response.data);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const addRemoveGrantAction = async (
  selectedSubmission,
  grantData,
  setAlert,
  clearAlert,
  setIsLoading,
  t
) => {
  try {
    setIsLoading(true);
    clearAlert();
    const grantID = grantData._links.addGrant.href.split("/").pop();
    const grantTitle = `"${grantData.title}"`;

    const request = {
      method: "patch",
      url: `/submissions/${selectedSubmission}/grants`,
      params: {
        submissionGrantId: grantID,
      },
      data: grantData,
    };

    const response = await axios(request);
    if (
      response.data._links.removeSubmissionGrant.href.split("/").pop() ===
        grantID &&
      !response.data.isActive
    ) {
      setAlert(
        "success",
        t(`grant.mainView.removeGrantMessage`, { grantName: grantTitle })
      );
    }
    if (
      response.data._links.removeSubmissionGrant.href.split("/").pop() ===
        grantID &&
      response.data.isActive
    ) {
      setAlert(
        "success",
        t(`grant.mainView.addGrantMessage`, { grantName: grantTitle })
      );
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setIsLoading(false);
  }
};

/**
 * API call to Export CSV
 * @param {*} submission
 * @param {*} grantsPreference
 */
export const getGrantsExportData = async (
  submission,
  grantsPreference,
  totalCount,
  clearAlert,
  setAlert,
  setExportLoading
) => {
  setExportLoading(true);
  clearAlert();
  try {
    const { filters, sort } = grantsPreference.grants;
    const {
      title,
      grantNumber,
      projectStartDateMin: projectStartDateFrom,
      projectStartDateMax: projectStartDateTo,
      projectEndDateMin: projectEndDateFrom,
      projectEndDateMax: projectEndDateTo,
      principal,
      grantRole,
    } = filters;
    const { orderBy, order } = sort;

    const request = {
      url: `/submissions/${submission}/grants`,
      params: {
        title,
        grantNumber,
        projectStartDateFrom,
        projectStartDateTo,
        projectEndDateFrom,
        projectEndDateTo,
        principal,
        grantRole,
        p: 1,
        ps: totalCount,
        s: `${orderBy ? `${orderBy}` : `title`}:${order ? `${order}` : `asc`}`,
      },
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
        "Content-type": "application/vnd.ms-excel",
      },
    };

    const response = await axios(request);

    if (response.data) {
      const currentDate = new Date();
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const date = ("0" + currentDate.getDate()).slice(-2);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Export_Grants_${currentDate.getFullYear()}${month}${date}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setExportLoading(false);
  }
};

/**
 * PUT call to update grants
 */

export const updateGrantAction = async (
  selectedSubmission,
  grantID,
  requestData,
  setResPost,
  navigate,
  t,
  titleLabel,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    if (requestData) {
      const request = {
        method: "patch",
        url: `/submissions/${selectedSubmission}/grants/${grantID}`,
        data: requestData,
      };

      const response = await axios(request);
      setResPost(response);
      navigate({
        pathname: "/grants",
      });
      setAlert(
        "success",
        t("grant.notification.updateGrants", {
          title: titleLabel,
        }),
        true
      );
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};
