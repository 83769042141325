import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid2 as MuiGrid,
  Button as MuiButton,
  Paper as MuiPaper,
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  Typography as MuiTypography,
} from "@mui/material";
import { CloudDownload as MuiCloudDownloadIcon } from "@mui/icons-material";
/** Custom Components */
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { Spinner } from "app/shared/ui/Loading/Spinner";
/** Services */
import {
  getOutputSubmissionsList,
  getOutputTable8A,
  getExportData,
} from "app/services/outputService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { outputTableStyles } from "./OutputTable8.styles";
import { StyledTableCell } from "app/shared/ui/Table/Table.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

export const Table8A = (props) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { handleSubmissionUpdate } = useSelectedSubmission();
  const [table8Data, setTable8Data] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const { setAlert, clearAlert } = useAlerts();
  const selectedSubmission = getSelectedSubmission();

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
  }, [clearAlert, setAlert]);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    handleSubmissionUpdate();
  };

  // Build Tab Data
  useEffect(() => {
    getOutputTable8A(
      selectedSubmission,
      setTable8Data,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [selectedSubmission, setAlert, clearAlert]);

  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      "table_8A",
      "table-eight-a",
      selectedSubmission,
      setAlert,
      clearAlert,
      setExportLoading
    );
  };

  return (
    <MuiGrid container direction="column">
      <Spinner
        size="50px"
        visible={loading}
        style={sharedStyles.pageSpinnerStyles}
      />
      <MuiGrid sx={sharedStyles.outputAutoCompleteWrapper}>
        {/* Submission Select Box */}
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid container justifyContent="flex-end">
        <MuiButton
          variant="outlined"
          color="secondary"
          onClick={handleExport}
          disabled={exportLoading || table8Data.length === 0}
        >
          <Spinner
            visible={exportLoading}
            style={sharedStyles.exportSpinnerStyles}
          />
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}
        </MuiButton>
      </MuiGrid>
      <MuiGrid sx={outputTableStyles.blueHeader}>
        {/* Table 8 Output */}
        <MuiTableContainer
          sx={outputTableStyles.container}
          component={MuiPaper}
        >
          <MuiTable
            stickyHeader
            sx={outputTableStyles.table1}
            size="small"
            area-label="PREDOC"
          >
            <>
              <TableHeadPredoc />
              <TableBodyPredoc table8Data={table8Data} loading={loading} />
            </>
          </MuiTable>
        </MuiTableContainer>
      </MuiGrid>
    </MuiGrid>
  );
};

const TableHeadPredoc = (props) => {
  const { t } = useTranslation();

  return (
    <MuiTableHead>
      <MuiTableRow>
        <StyledTableCell width="15%">
          {t("output.mainView.list.tableColumns.table8.predoctoral.trainee")}
        </StyledTableCell>
        <StyledTableCell width="15%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.facultyMember"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t("output.mainView.list.tableColumns.table8.predoctoral.startDate")}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.summaryOfSupport"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.terminalDegreeReceivedYear"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.topicOfResearchProject"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.initialPosition"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.currentPosition"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.subsequentGrant"
          )}
        </StyledTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

const TableBodyPredoc = (props) => {
  const { table8Data, loading } = props;
  const { t } = useTranslation();

  return (
    <MuiTableBody>
      {loading ? (
        <MuiTableRow>
          <StyledTableCell colSpan="9">
            <MuiTypography variant="body2" color="inherit" align="center">
              {t("globals.list.messages.fetchingInfo")}
            </MuiTypography>
          </StyledTableCell>
        </MuiTableRow>
      ) : table8Data.length > 0 ? (
        table8Data.map((outcome) => (
          <MuiTableRow key={outcome.submissionOutcomeId}>
            <StyledTableCell width="15%">{outcome.traineeName}</StyledTableCell>
            <StyledTableCell width="15%">
              <OutcomeMultiValueSummary items={outcome.facultyMembers} />
            </StyledTableCell>
            <StyledTableCell width="10%">{outcome.startDate}</StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary items={outcome.trainingSupportYears} />
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary items={outcome.terminalDegrees} />
            </StyledTableCell>
            <StyledTableCell width="10%">
              {outcome.researchTopic}
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary
                items={outcome.initialPositionSummary}
              />
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary
                items={outcome.currentPositionSummary}
              />
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary items={outcome.subsequentGrants} />
            </StyledTableCell>
          </MuiTableRow>
        ))
      ) : (
        <MuiTableRow>
          <MuiTableCell
            colSpan="9"
            variant="body2"
            color="inherit"
            align="center"
          >
            {t("globals.list.messages.noData")}
          </MuiTableCell>
        </MuiTableRow>
      )}
    </MuiTableBody>
  );
};

const OutcomeMultiValueSummary = (props) => {
  const { items } = props;

  if (items) {
    return (
      <ul style={outputTableStyles.unorderedList}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  } else {
    return "";
  }
};
