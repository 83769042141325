import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormControl as MuiFormControl } from "@mui/material";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { format, isValid, parseISO } from "date-fns";
import { Autocomplete } from "app/shared/ui/Autocomplete/Autocomplete";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
import { ResetFilter } from "app/shared/ui/FilterPanel/ResetFilter";
import {
  traineeTypeDropDown,
  isActiveOptions,
  STORAGE_TYPE,
  STORAGE_NAMES,
} from "app/shared/constants";
/** Services */
import { getOutcomesFilters } from "app/services/outcomesService";
import { useOutcomesContext } from "app/services/outcomesContext";
import { useOutcomesSelectedContext } from "app/services/outcomesSelectedContext";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useBrowserStorage } from "app/services/StorageService/StorageHelper";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { outcomesFilterStyles } from "./OutcomesFilter.styles";
import { sharedDatePickerStyle } from "app/shared/ui/sharedStyles";

const initialOutcomesFilterValues = {
  traineeName: "",
  facultyName: "",
  traineeType: "",
  currentTrainee: "",
  trainingStartDateFrom: "",
  trainingStartDateTo: "",
  trainingEndDateFrom: "",
  trainingEndDateTo: "",
  isActive: isActiveOptions[0],
};

const OutcomesFilterDef = () => {
  const { t } = useTranslation();

  const { saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);

  const { outcomesPreferences, setOutcomesPreferences } = useOutcomesContext();
  const { resetAllSelections } = useOutcomesSelectedContext();
  const initialFilterValues = outcomesPreferences.values.filters;

  const [traineeType, setTraineeType] = useState([]);

  const [selectedStartFromDate, setSelectedStartFromDate] = useState(
    initialFilterValues.trainingStartDateFrom
      ? parseISO(initialFilterValues.trainingStartDateFrom)
      : null
  );
  const [selectedStartToDate, setSelectedStartToDate] = useState(
    initialFilterValues.trainingStartDateTo
      ? parseISO(initialFilterValues.trainingStartDateTo)
      : null
  );

  const [selectedEndFromDate, setSelectedEndFromDate] = useState(
    initialFilterValues.trainingEndDateFrom
      ? parseISO(initialFilterValues.trainingEndDateFrom)
      : null
  );
  const [selectedEndToDate, setSelectedEndToDate] = useState(
    initialFilterValues.trainingEndDateTo
      ? parseISO(initialFilterValues.trainingEndDateTo)
      : null
  );

  const [maxDateStart, setMaxDateStart] = useState(null);
  const [maxDateEnd, setMaxDateEnd] = useState(null);

  const [minDateStart, setMinDateStart] = useState(null);
  const [minDateEnd, setMinDateEnd] = useState(null);

  const [startDateFromError, setStartDateFromError] = useState(false);
  const [startDateToError, setStartDateToError] = useState(false);
  const [endDateFromError, setEndDateFromError] = useState(false);
  const [endDateToError, setEndDateToError] = useState(false);

  const { setAlert, clearAlert } = useAlerts();

  const [outcomesFilterSelectedValues, setOutcomesFilterSelectedValues] =
    useState({ ...initialFilterValues });

  const resetFilterValues = () => {
    saveItem(
      STORAGE_NAMES.TGDS_OUTCOME_DASHBOARD_FILTERS,
      initialOutcomesFilterValues
    );
    setOutcomesFilterSelectedValues({ ...initialOutcomesFilterValues });
    setSelectedEndToDate(null);
    setSelectedEndFromDate(null);
    setSelectedStartFromDate(null);
    setSelectedStartToDate(null);

    setFilterPreference({ ...initialOutcomesFilterValues });
    resetAllSelections();
    changeFilterData(initialOutcomesFilterValues);
  };

  const [resStartEndDate, setResStartEndDate] = useState([]);

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { changeFilterData, submissionUpdate } = useSelectedSubmission();
  const selectedSubmission = getSelectedSubmission();

  // ** Reset Filter Values on a component event **
  useEffect(() => {
    getOutcomesFilters(
      setResStartEndDate,
      setTraineeType,
      selectedSubmission,
      setAlert,
      clearAlert,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [selectedSubmission, outcomesPreferences.values.addCount]);
  if (Object.keys(resStartEndDate).length && !minDateEnd && !minDateStart) {
    setMinDateEnd(parseISO(resStartEndDate.trainingEndDateMin));
    setMinDateStart(parseISO(resStartEndDate.trainingStartDateMin));
    setMaxDateStart(parseISO(resStartEndDate.trainingStartDateMax));
    setMaxDateEnd(parseISO(resStartEndDate.trainingEndDateMax));
  }

  // *** Helper Function to Update Context Object, on Field Value Update ***
  const setFilterPreference = (fieldUpdate) => {
    setOutcomesPreferences({
      ...outcomesPreferences,
      values: {
        ...outcomesPreferences.values,
        filters: {
          ...outcomesPreferences.values.filters,
          ...fieldUpdate,
        },
        pagination: {
          page: 0,
          pageSize: 0,
        },
        sort: {
          order: "",
          orderBy: "",
        },
      },
    });
  };

  // **** On Dropdown Value change, update search results ****
  const handleFilterValueUpdate = (fieldName, fieldValue, updateFilterData) => {
    const updatedFilterValues = {
      ...outcomesFilterSelectedValues,
      [fieldName]: fieldValue,
    };

    if (updateFilterData) {
      setFilterPreference(updatedFilterValues);
      setOutcomesFilterSelectedValues(updatedFilterValues);
    }
    resetAllSelections();
  };

  useEffect(() => {
    if (submissionUpdate) {
      resetFilterValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionUpdate]);

  return (
    <>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="traineesNameAutoComplete"
          key="trainees-names"
          getOptionLabel={(option) => option?.displayText ?? ""}
          value={outcomesFilterSelectedValues.traineeName}
          label={t("trainee.mainView.filters.traineeName")}
          onChange={(e, value) => {
            handleFilterValueUpdate("traineeName", value || "", true);
          }}
          autoCompleteUrlPrefix={`/submissions/${selectedSubmission}/trainees/autocomplete?context=outcomes`}
          filterOptions={(options) => options}
          renderOption={(props, state, { inputValue }) => (
            <AutocompleteOption
              option={props}
              inputValue={inputValue}
              state={state}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="facultyNameAutoComplete"
          key="faculty-names"
          getOptionLabel={(option) => option?.displayText ?? ""}
          value={outcomesFilterSelectedValues.facultyName}
          label={t("trainee.mainView.filters.faculty")}
          onChange={(e, value) => {
            handleFilterValueUpdate("facultyName", value || "", true);
          }}
          autoCompleteUrlPrefix={`/submissions/${selectedSubmission}/faculty/autocomplete?context=outcomes`}
          filterOptions={(options) => options}
          renderOption={(props, state, { inputValue }) => (
            <AutocompleteOption
              option={props}
              inputValue={inputValue}
              state={state}
            />
          )}
        />
      </MuiFormControl>
      <MuiFormControl sx={sharedDatePickerStyle}>
        <label style={outcomesFilterStyles.fieldTextStyle}>
          {t("trainee.mainView.filters.trainingStartDate")}
        </label>
        <div style={outcomesFilterStyles.datePickerFormControl}>
          <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
            <MuiDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={minDateStart || new Date("01/01/1970")}
              maxDate={
                selectedStartToDate || maxDateStart || new Date("01/01/2100")
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                },
                "& .MuiIconButton-root": {
                  padding: 0,
                  margin: 0,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              id="date-picker-inline-start-from"
              margin="normal"
              value={selectedStartFromDate}
              onChange={(value, { validationError }) => {
                setSelectedStartFromDate(value);
                handleFilterValueUpdate(
                  "trainingStartDateFrom",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
                if (validationError === null) setStartDateFromError(null);
              }}
              onError={(error, value) => {
                if (error === "maxDate") {
                  setStartDateFromError(
                    t("trainee.edit.traineeForm.maxDateMessage")
                  );
                } else if (error === "invalidDate") {
                  setStartDateFromError(
                    t("trainee.edit.traineeForm.invalidDateFormat")
                  );
                } else if (!error) {
                  setStartDateFromError(null);
                } else {
                  setStartDateToError(null);
                }
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error: Boolean(startDateFromError),
                  helperText: startDateFromError,
                  size: "small",
                },
              }}
            />
            <span style={outcomesFilterStyles.dateSeparator}>
              {t("trainee.mainView.filters.dateSeparator")}
            </span>
            <MuiDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={
                selectedStartFromDate || minDateStart || new Date("01/01/1970")
              }
              maxDate={maxDateEnd || maxDateStart || new Date("01/01/2100")}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                },
                "& .MuiIconButton-root": {
                  padding: 0,
                  margin: 0,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              id="date-picker-inline-start-to"
              margin="normal"
              value={selectedStartToDate}
              onChange={(value, { validationError }) => {
                setSelectedStartToDate(value);
                handleFilterValueUpdate(
                  "trainingStartDateTo",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
                if (validationError === null) setStartDateToError(null);
              }}
              onError={(error, value) => {
                if (error === "minDate") {
                  setStartDateToError(
                    t("trainee.edit.traineeForm.minDateMessage")
                  );
                } else if (error === "invalidDate") {
                  setStartDateToError(
                    t("trainee.edit.traineeForm.invalidDateFormat")
                  );
                } else if (!error) {
                  setStartDateToError(null);
                } else {
                  setStartDateFromError(null);
                }
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error: Boolean(startDateToError),
                  helperText: startDateToError,
                  size: "small",
                },
              }}
            />
          </MuiLocalizationProvider>
        </div>
      </MuiFormControl>
      <MuiFormControl sx={sharedDatePickerStyle}>
        <label style={outcomesFilterStyles.fieldTextStyle}>
          {t("trainee.mainView.filters.trainingEndDate")}
        </label>
        <div style={outcomesFilterStyles.datePickerFormControl}>
          <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
            <MuiDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={minDateStart || new Date("01/01/1970")}
              maxDate={
                selectedEndToDate || maxDateStart || new Date("01/01/2100")
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                },
                "& .MuiIconButton-root": {
                  padding: 0,
                  margin: 0,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              margin="normal"
              id="date-picker-inline-end-from"
              value={selectedEndFromDate}
              onChange={(value, { validationError }) => {
                setSelectedEndFromDate(value);
                handleFilterValueUpdate(
                  "trainingEndDateFrom",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
                if (validationError === null) setEndDateFromError(null);
              }}
              onError={(error, value) => {
                if (error === "maxDate") {
                  setEndDateFromError(
                    t("trainee.edit.traineeForm.maxDateMessage")
                  );
                } else if (error === "invalidDate") {
                  setEndDateFromError(
                    t("trainee.edit.traineeForm.invalidDateFormat")
                  );
                } else if (!error) {
                  setEndDateFromError(null);
                } else {
                  setEndDateToError(null);
                }
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error: Boolean(endDateFromError),
                  helperText: endDateFromError,
                  size: "small",
                },
              }}
            />
            <span style={outcomesFilterStyles.dateSeparator}>
              {t("trainee.mainView.filters.dateSeparator")}
            </span>
            <MuiDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              margin="normal"
              minDate={
                selectedEndFromDate || minDateStart || new Date("01/01/1970")
              }
              maxDate={maxDateEnd || maxDateStart || new Date("01/01/2100")}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                },
                "& .MuiIconButton-root": {
                  padding: 0,
                  margin: 0,
                },
              }}
              invalidDateMessage={t(
                "trainee.mainView.filters.invalidDateFormat"
              )}
              id="date-picker-inline-end-to"
              value={selectedEndToDate}
              onChange={(value, { validationError }) => {
                setSelectedEndToDate(value);
                handleFilterValueUpdate(
                  "trainingEndDateTo",
                  (isValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
                if (validationError === null) setEndDateToError(null);
              }}
              onError={(error, value) => {
                if (error === "minDate") {
                  setEndDateToError(
                    t("trainee.edit.traineeForm.minDateMessage")
                  );
                } else if (error === "invalidDate") {
                  setEndDateToError(
                    t("trainee.edit.traineeForm.invalidDateFormat")
                  );
                } else if (!error) {
                  setEndDateToError(null);
                } else {
                  setEndDateFromError(null);
                }
              }}
              slotProps={{
                textField: {
                  variant: "outlined",
                  error: Boolean(endDateToError),
                  helperText: endDateToError,
                  size: "small",
                },
              }}
            />
          </MuiLocalizationProvider>
        </div>
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="traineeTypeFilterSelect"
          options={traineeType || []}
          getOptionLabel={(option) => t(traineeTypeDropDown[option])}
          value={
            outcomesFilterSelectedValues.traineeType
              ? t(outcomesFilterSelectedValues.traineeType)
              : ""
          }
          clientSide={true}
          label={t("trainee.mainView.filters.traineeType")}
          onChange={(e, value) => {
            handleFilterValueUpdate("traineeType", value || "", true);
          }}
        />
      </MuiFormControl>

      <MuiFormControl variant="outlined">
        <Autocomplete
          id="isActive"
          options={isActiveOptions}
          getOptionLabel={(option) => option && t(option.label)}
          clearOnEscape={true}
          value={outcomesFilterSelectedValues.isActive}
          clientSide={true}
          label={t("outcomes.mainView.filters.isActive")}
          onChange={(e, value) => {
            handleFilterValueUpdate("isActive", value ?? null, true);
          }}
        />
      </MuiFormControl>
      <ResetFilter resetFn={resetFilterValues} />
    </>
  );
};

export const OutcomesFilter = OutcomesFilterDef;
