import { useTranslation } from "react-i18next";
import {
  TableBody as MuiTableBody,
  Typography as MuiTypography,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MUiCheckbox,
} from "@mui/material";
import { format, parseISO } from "date-fns";
/** Static Dropdown Values */
import {
  traineeTypeDropDown,
  OutcomeTerminalDegreeTypeEnums,
  OutcomeTrainingSupportSourceEnums,
  OutcomeResultingDegreeTypeEnums,
  OutcomesInvitationStatusEnums,
} from "app/shared/constants";
import { getTrainingTypeNameByTrainingTypeCode } from "app/shared/utils";
import AddRemoveActionIcons from "app/shared/ui/Layout/AddRemoveActionIcons/AddRemoveActionIcons";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "app/services/outcomesSelectedContext";
import { AuditInfo } from "app/shared/ui/AuditInfo/AuditInfo";
/** Styles */
import { outcomeDetailsStyles } from "./OutcomesDetails.styles";
import { Theme } from "common";

const OutcomesDetailsTableBody = ({
  outcomesList,
  handleConfirmation,
  toggleActive,
}) => {
  const { t } = useTranslation();
  const outcomesDetailsTableColumns = "outcomes.mainView.list.tableColumns";

  const { outcomesSelected, handleSelectionChange } =
    useOutcomesSelectedContext();

  // If row is inactive, put the disableRow class
  const getRowClass = (isRowActive) =>
    !isRowActive ? outcomeDetailsStyles.disableRow : {};

  const getStatusClass = (row) => {
    if (row.invitationStatus in OutcomesInvitationStatusEnums) {
      return outcomeDetailsStyles[
        OutcomesInvitationStatusEnums[row.invitationStatus].displayClass
      ];
    }
    return "";
  };

  const getTrainingSupportYear = (traineeType, training) => {
    if (training.trainingTypeCode === "NIH_HHS") {
      const result = t(
        "outcomes.mainView.list.trainingSupportYearNihHhsDisplay",
        {
          trainingYearNumber: training.trainingYearNumber,
          nihHhsComponentAndActivity: training.nihHhsComponentAndActivity,
        }
      );
      return result;
    } else {
      const trainingSource =
        (training.trainingSourceCode &&
          t(
            `${
              OutcomeTrainingSupportSourceEnums[training.trainingSourceCode]
            }.shortName`
          )) ||
        "";

      const trainingType = getTrainingTypeNameByTrainingTypeCode(
        training.trainingTypeCode,
        "shortName",
        t
      );

      const result = t(
        "outcomes.mainView.list.trainingSupportYearNonNihHhsDisplay",
        {
          trainingYearNumber: training.trainingYearNumber,
          trainingSource: trainingSource,
          trainingType: trainingType,
        }
      );
      return result;
    }
  };

  const isSelected = (outcomesSelected, row) => {
    if (!(row.traineeEmail && row.traineeEmail.trim())) return false;
    if (!row.isActive) return false;

    const rowId = row._links.self.href;

    if (outcomesSelected.selectAllState === SelectAllStates.ALL_SELECTED) {
      return true;
    } else if (
      outcomesSelected.selectAllState === SelectAllStates.NONE_SELECTED
    ) {
      return !!outcomesSelected.individualSelections[rowId];
    } else {
      return outcomesSelected.individualSelections[rowId] !== false;
    }
  };

  const getResultingDegree = (resultingDegree) => {
    const degree =
      resultingDegree.degreeCode !== "OTHER_D"
        ? t(OutcomeResultingDegreeTypeEnums[resultingDegree.degreeCode])
        : resultingDegree.otherDegree;

    const degreeYear = resultingDegree.degreeYear || "";

    return `${degree} ${degreeYear}`;
  };

  return (
    <MuiTableBody>
      {outcomesList.values && outcomesList.values.length > 0 ? (
        outcomesList.values.map((row, i) => (
          <MuiTableRow
            key={row._links.self.href}
            sx={{
              color: row.isActive && Theme.palette.grey[300],
            }}
          >
            <MuiTableCell
              align="left"
              sx={outcomeDetailsStyles.tableCellHead}
              title={row.traineeEmail ? "" : "No email on record"}
            >
              <MUiCheckbox
                color="secondary"
                size="small"
                checked={isSelected(outcomesSelected, row)}
                disabled={
                  !row.traineeEmail || !row.isActive || !row._links.addOutcome
                }
                onChange={(event) => handleSelectionChange(event, row)}
              />
            </MuiTableCell>
            <MuiTableCell
              align="left"
              sx={getRowClass(row.isActive)}
              title={row.traineeEmail ? "" : "No email on record"}
            >
              {row.traineeName ? row.traineeName : ""}
              {row.traineeName && row.traineeEmail ? <br /> : ""}
              {row.traineeEmail ? row.traineeEmail : ""}
              <AuditInfo
                isEdited={row.traineeNameMod || row.traineeEmailMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell
              align="left"
              sx={{
                ...outcomeDetailsStyles.invitationStatusTableCell,
                ...getRowClass(row.isActive),
              }}
            >
              {row.lastInvitedDate
                ? format(parseISO(row.lastInvitedDate), "MM/dd/yyyy")
                : ""}
              {row.lastInvitedDate && row.invitationStatus ? <br /> : ""}
              {
                <div sx={getStatusClass(row)}>
                  {row.invitationStatus &&
                    t(
                      OutcomesInvitationStatusEnums[row.invitationStatus]
                        .translationKey
                    )}
                </div>
              }
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {t(traineeTypeDropDown[row.traineeType])}
              <AuditInfo
                isEdited={row.traineeTypeMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              <ul
                style={{
                  listStyleType: "none",
                  paddingInlineStart: "unset",
                  marginBlockStart: "unset",
                  marginBlockEnd: "unset",
                }}
              >
                {row.terminalDegrees &&
                  row.terminalDegrees.map((terminalDegree, i) => {
                    return (
                      <li key={terminalDegree.degreeCode}>
                        {t(
                          OutcomeTerminalDegreeTypeEnums[
                            terminalDegree.degreeCode
                          ]
                        )}{" "}
                        {terminalDegree.degreeYear}
                      </li>
                    );
                  })}
              </ul>
              <AuditInfo
                isEdited={row.traineeDegreesMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {row.submissionOutcomeFaculty &&
                row.submissionOutcomeFaculty
                  .map((traineeFaculty) => traineeFaculty["fullName"])
                  .join("; ")}{" "}
              <AuditInfo
                isEdited={row.submissionOutcomeFacultyMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              <div>
                {row.trainingStartDate &&
                  format(parseISO(row.trainingStartDate), "MM/dd/yyyy")}

                {row.trainingStartDate || row.trainingEndDate
                  ? " - "
                  : t(`${outcomesDetailsTableColumns}.notAvailable`)}
                {row.trainingEndDate &&
                  format(parseISO(row.trainingEndDate), "MM/dd/yyyy")}
                <AuditInfo
                  isEdited={row.trainingStartEndDatesMod}
                  isActive={row.isActive}
                />
              </div>
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              <ul
                style={{
                  listStyleType: "none",
                  paddingInlineStart: "unset",
                  marginBlockStart: "unset",
                  marginBlockEnd: "unset",
                }}
              >
                {row.trainingSupportYears &&
                  row.trainingSupportYears.map((training, i) => {
                    return (
                      <li key={training.trainingYearNumber}>
                        {getTrainingSupportYear(row.traineeType, training)}
                      </li>
                    );
                  })}
              </ul>

              <AuditInfo
                isEdited={row.trainingSupportYearsMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              <ul
                style={{
                  listStyleType: "none",
                  paddingInlineStart: "unset",
                  marginBlockStart: "unset",
                  marginBlockEnd: "unset",
                }}
              >
                {row.resultingDegrees &&
                  row.resultingDegrees.map((resultingDegree, i) => {
                    return (
                      <li key={i}>{getResultingDegree(resultingDegree)}</li>
                    );
                  })}
              </ul>
              <AuditInfo
                isEdited={row.resultingDegreesMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {row.researchTopic}
              <AuditInfo
                isEdited={row.researchTopicMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left">
              <AddRemoveActionIcons
                row={row}
                isRowActive={row.isActive}
                rowId={row._links.self.href.split("/").pop()}
                editPermission={row._links.editSubmissionOutcomeDetails}
                removePermission={row._links.removeSubmissionOutcome}
                addPermission={row._links.addOutcome}
                toggleActive={toggleActive}
                handleConfirmation={handleConfirmation}
                editPageUrl={`/outcomes/${row._links.self.href
                  .split("/")
                  .pop()}/edit`}
              />
            </MuiTableCell>
          </MuiTableRow>
        ))
      ) : (
        <MuiTableRow>
          <MuiTableCell colSpan="11">
            <MuiTypography variant="body2" color="inherit" align="center">
              {t("outcomes.mainView.list.noData")}
            </MuiTypography>
          </MuiTableCell>
        </MuiTableRow>
      )}
    </MuiTableBody>
  );
};

export default OutcomesDetailsTableBody;
