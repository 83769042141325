import { Theme } from "common";

export const outcomeDetailsStyles = {
  tableCellHead: {
    padding: "16px 1px 25px 5px",
    "&:first-of-type": {
      paddingLeft: "10px",
    },
    "&:last-of-type": {
      paddingRight: "10px",
    },
  },
  noDataTableCell: {
    verticalAlign: "middle",
    textAlign: "center",
    padding: "0",
    color: Theme.palette.grey[500],
  },
  invitationStatusTableCell: {
    overflowWrap: "normal",
  },

  unorderedBulletList: {
    paddingInlineStart: "15px",
    marginBlockStart: "unset",
    marginBlockEnd: "unset",
  },
  unorderedList: {
    paddingInlineStart: "unset",
    marginBlockStart: "unset",
    marginBlockEnd: "unset",
  },
  statusHide: {
    visibility: "hidden",
  },
  statusBad: {
    color: "#982727",
  },
  statusGood: {
    color: "#3c8b3c",
  },
  statusInfo: {
    color: "inherit",
  },
  selectBox: {
    top: "-.2em",
  },
  selectBoxHead: {
    "& .MuiSvgIcon-root": {
      color: Theme.palette.common.white,
    },
  },
  actionButton: {
    padding: "10px 0px 0px 0px",
    "& .MuiIconButton-root": {
      fontSize: "0.5rem",
      padding: "8px",
    },
  },
  disableRow: {
    "&.MuiCheckbox-root .MuiSvgIcon-root": {
      color: Theme.palette.grey[300],
    },
    "& $statusBad, & $statusGood, & $statusInfo": {
      color: Theme.palette.grey[300],
    },
    color: Theme.palette.grey[300],
  },
};
