import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography as MuiTypography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { dialogStyles } from "./Dialog.styles";

/**
 * Generate custom Dialog box.
 *
 * @param {closeButtonText} props
 *   Close button text.
 * @param {title} props
 *   Dialog box title.
 * @param {scroll} props
 *   Dialog box scroll type, refer to Material UI Dialog API.
 * @param {open} props
 *   Prop to check if modal box is open or closed.
 * @param {handleClose} props
 *   Callback function to close the popup box.
 * @param {children} props
 *   Content for dialog box.
 * @param {showGeneralHelpSection} props
 *   shows General help section if True
 */
const CustomDialog = (props) => {
  const { t } = useTranslation();
  const scroll = props.scroll ? props.scroll : "paper";

  return (
    <MuiDialog
      open={props.open}
      onClose={props.handleClose}
      scroll={scroll}
      sx={dialogStyles.root}
    >
      <MuiDialogTitle sx={dialogStyles.dialogTitle}>
        {!!props.description ? (
          <>
            <MuiTypography variant="h2" sx={{ fontSize: "20px" }}>
              {props.title}
            </MuiTypography>
            <MuiTypography variant="body2" sx={{ fontSize: "14px" }}>
              {props.description}
            </MuiTypography>
          </>
        ) : (
          props.title
        )}
      </MuiDialogTitle>
      <MuiDialogContent
        sx={dialogStyles.dialogContent}
        dividers={scroll === "paper"}
      >
        {props.children}
      </MuiDialogContent>
      {props.showGeneralHelpSection && (
        <MuiDialogContent sx={dialogStyles.dialogTitle}>
          <h2 className="MuiTypography-root MuiTypography-h6">
            {t("globals.metaDataInfo.generalHelpTitle")}
          </h2>
          <p>{t("globals.metaDataInfo.generalHelpDescription")}</p>
        </MuiDialogContent>
      )}
      <MuiDialogActions sx={dialogStyles.dialogAction}>
        <MuiButton onClick={props.handleClose}>
          {props.closeButtonText}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default CustomDialog;
