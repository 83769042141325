import { useEffect, useState, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";
import { usePagingSortingContext } from "./pagingSortingContext";
import { useBrowserStorage } from "./StorageService/StorageHelper";
import { STORAGE_NAMES, STORAGE_TYPE } from "app/shared/constants";

export const GrantsContext = createContext();

export const GrantsProvider = ({ children }) => {
  const location = useLocation();
  const { grantsPagingSortingStored, setGrantsPagingSortingStored } =
    usePagingSortingContext().value;
  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  const filterValues = getItem(
    STORAGE_NAMES.TGDS_GRANTS_DASHBOARD_FILTERS,
    Object
  );
  const [grantsPreference, setGrantsPreferenceInternal] = useState(
    initialGrantsState({
      location: location,
      pageSort: grantsPagingSortingStored,
      filterValues,
    })
  );

  const setGrantsPreference = (preference) => {
    preference = preference || {};

    const locPageSort = {
      pagination: preference.grants?.pagination || {},
      sort: preference.grants?.sort || {},
    };

    setGrantsPagingSortingStored(locPageSort);
    setGrantsPreferenceInternal(preference);
    saveItem(STORAGE_NAMES.TGDS_GRANTS_DASHBOARD_FILTERS, preference);
  };

  useEffect(() => {
    setGrantsPagingSortingStored({
      pagination: grantsPreference.grants?.pagination || {},
      sort: grantsPreference.grants?.sort || {},
    });
  }, [setGrantsPagingSortingStored, grantsPreference]);

  const updatePreferencesForSubmission = (selectedSubmission) => {
    setGrantsPreference({ ...initialGrantsState({}) });
  };

  return (
    <GrantsContext.Provider
      value={{
        grantsPreference,
        setGrantsPreference,
        updatePreferencesForSubmission,
      }}
    >
      {children}
    </GrantsContext.Provider>
  );
};

export const initialGrantsState = ({ location, pageSort, filterValues }) => {
  pageSort = pageSort || {};
  const { filters, pagination, sort } = filterValues?.grants ?? {};

  return {
    grants: {
      filters: {
        title: filters?.title || "",
        grantNumber: filters?.grantNumber || "",
        projectStartDateMin: filters?.projectStartDateMin || "",
        projectStartDateMax: filters?.projectStartDateMax || null,
        projectEndDateMin: filters?.projectEndDateMin || null,
        projectEndDateMax: filters?.projectEndDateMax || null,
        principal: filters?.principal || "",
        grantRole: filters?.grantRole || "",
      },
      sort: {
        order: sort?.order || "",
        orderBy: sort?.orderBy || "",
        ...pageSort.sort,
      },
      pagination: {
        page: pagination?.page || "",
        pageSize: pagination?.pageSize || "",
        ...pageSort.pagination,
      },
    },
  };
};

export const useGrantsContext = () => {
  return useContext(GrantsContext);
};
