import { Route, Routes } from "react-router-dom";
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from "@mui/material";
import { AccessDenied, useUsers } from "common";
import { Trans, useTranslation } from "react-i18next";
import { Page } from "app/shared/ui/Page";
import { ManageSubmission } from "app/components/administration/submission/ManageSubmission/ManageSubmission";
import { SubmissionFilter } from "app/components/administration/submission/ManageSubmission/SubmissionFilter";
import { CreateEditSubmissionForm } from "app/components/administration/submission/CreateEditSubmissionForm/CreateEditSubmissionForm";
/** Service Providers */
import { ManageSubmissionsProvider } from "app/services/manageSubmissionsContext";
import { FacultyDemographicsProvider } from "app/services/facultyDemographicsContext";
import { FacultyFilter } from "app/components/Faculty/FacultyFilter";
import { Faculty } from "app/components/Faculty/Faculty";
import { EditFaculty } from "app/components/Faculty/EditFacultyForm";
import { Grants } from "./components/Grants/Grants";
import { GrantFilter } from "./components/Grants/GrantFilter";
import { EditGrants } from "./components/Grants/EditGrants";
import { GrantsProvider } from "./services/grantsContext";
import { TraineeProvider } from "./services/traineeContext";
import { TraineesFilter } from "./components/Trainees/TraineesFilter";
import { Trainees } from "./components/Trainees/Trainees";
import { EditTrainee } from "./components/Trainees/EditTrainee";
import { TraineesSelectedProvider } from "./services/traineesSelectedContext";
import { CreateApplicant } from "./components/Applicants/CreateApplicant";
import { submissionType } from "./shared/constants";
import { Applicants } from "./components/Applicants/Applicants";
import { ApplicantsFilter } from "./components/Applicants/ApplicantsFilter/ApplicantsFilter";
import { ApplicantProvider } from "./services/applicantContext";
import { ApplicantsSelectedProvider } from "./services/applicantsSelectedContext";
import { OutcomesFilter } from "./components/Outcomes/OutcomesFilter";
import { Outcomes } from "./components/Outcomes/Outcomes";
import { EditOutcome } from "./components/Outcomes/EditOutcome";
import { OutcomesSelectedProvider } from "./services/outcomesSelectedContext";
import { OutcomesProvider } from "./services/outcomesContext";
import { Table1 } from "./components/Output/OutputTable1";
import { Table2 } from "./components/Output/OutputTable2";
import { Table3 } from "./components/Output/OutputTable3";
import { OutputTable4 } from "./components/Output/OutputTable4";
import { Table6A } from "./components/Output/OutputTable6A";
import { Table6B } from "./components/Output/OutputTable6B";
import { Table8A } from "./components/Output/OutputTable8A";
import { Table8B } from "./components/Output/OutputTable8B";

export const AppRoutes = () => {
  const { accessDenied } = useUsers();
  const { t } = useTranslation();

  // Prevent access if the user is not authorized to use the application
  if (accessDenied) {
    return <AccessDenied supportEmail={t(`app.supportEmail`)} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <FacultyDemographicsProvider>
            <Page
              title={t("globals.title.faculty")}
              filter={<FacultyFilter />}
              main={<Faculty />}
              metaDataDescription={
                <Trans i18nKey="globals.metaDataInfo.faculty.modalDescription" />
              }
            />
          </FacultyDemographicsProvider>
        }
      />
      {/* Routes for Admins */}
      <Route
        path="/admin/submissions"
        element={
          <ManageSubmissionsProvider>
            <Page
              title={t("globals.title.submissions")}
              filter={<SubmissionFilter />}
              main={<ManageSubmission />}
            />
          </ManageSubmissionsProvider>
        }
      />
      <Route
        path="/admin/submissions/new"
        element={
          <ManageSubmissionsProvider>
            <Page
              main={<CreateEditSubmissionForm type={submissionType.create} />}
            />
          </ManageSubmissionsProvider>
        }
      />
      <Route
        path="/admin/submissions/:submissionId/edit"
        element={
          <ManageSubmissionsProvider>
            <Page
              main={<CreateEditSubmissionForm type={submissionType.edit} />}
            />
          </ManageSubmissionsProvider>
        }
      />
      {/* Routes for Participating Faculty */}
      <Route
        path="/faculty"
        element={
          <FacultyDemographicsProvider>
            <Page
              title={t("globals.title.faculty")}
              filter={<FacultyFilter />}
              main={<Faculty />}
              metaDataDescription={
                <Trans i18nKey="globals.metaDataInfo.faculty.modalDescription" />
              }
            />
          </FacultyDemographicsProvider>
        }
      />
      <Route
        path="/faculty/:facultyId/edit"
        element={
          <FacultyDemographicsProvider>
            <Page main={<EditFaculty />} />
          </FacultyDemographicsProvider>
        }
      />
      {/* Routes for Training Grants */}
      <Route
        path="/grants"
        element={
          <GrantsProvider>
            <Page
              title={t("globals.title.grants")}
              filter={<GrantFilter />}
              main={<Grants />}
              metaDataDescription={
                <Trans i18nKey="globals.metaDataInfo.grants.modalDescription" />
              }
            />
          </GrantsProvider>
        }
      />
      <Route
        path="/grants/:grantId/edit"
        element={
          <GrantsProvider>
            <Page main={<EditGrants />} />
          </GrantsProvider>
        }
      />
      {/* Routes for Trainees */}
      <Route
        path="/trainees"
        element={
          <TraineeProvider>
            <TraineesSelectedProvider>
              <Page
                title={t("globals.title.trainees")}
                filter={<TraineesFilter />}
                main={<Trainees />}
                metaDataDescription={
                  <Trans i18nKey="globals.metaDataInfo.trainees.modalDescription" />
                }
              />
            </TraineesSelectedProvider>
          </TraineeProvider>
        }
      />
      <Route
        path="/trainees/:trainee/edit"
        element={
          <TraineeProvider>
            <TraineesSelectedProvider>
              <Page main={<EditTrainee />} />
            </TraineesSelectedProvider>
          </TraineeProvider>
        }
      />
      {/* Routes for Applicants */}
      <Route
        path="/applicants"
        element={
          <ApplicantProvider>
            <ApplicantsSelectedProvider>
              <Page
                title={t("globals.title.applicants")}
                filter={<ApplicantsFilter />}
                main={<Applicants />}
                metaDataDescription={
                  <Trans i18nKey="globals.metaDataInfo.applicants.modalDescription" />
                }
              />
            </ApplicantsSelectedProvider>
          </ApplicantProvider>
        }
      />
      <Route
        path="/applicants/:submissionId/edit/:applicantId"
        element={
          <ApplicantProvider>
            <ApplicantsSelectedProvider>
              <Page main={<CreateApplicant type={submissionType.edit} />} />
            </ApplicantsSelectedProvider>
          </ApplicantProvider>
        }
      />
      <Route
        path="/applicants/:submissionId/new"
        element={
          <ApplicantProvider>
            <ApplicantsSelectedProvider>
              <Page main={<CreateApplicant type={submissionType.create} />} />
            </ApplicantsSelectedProvider>
          </ApplicantProvider>
        }
      />
      {/* Routes for Outcomes */}
      <Route
        path="/outcomes"
        element={
          <OutcomesProvider>
            <OutcomesSelectedProvider>
              <Page
                title={t("globals.title.outcomes")}
                filter={<OutcomesFilter />}
                main={<Outcomes />}
                metaDataDescription={
                  <Trans i18nKey="globals.metaDataInfo.outcomes.modalDescription" />
                }
              />
            </OutcomesSelectedProvider>
          </OutcomesProvider>
        }
      />
      <Route
        path="/outcomes/:outcome/edit"
        element={
          <OutcomesProvider>
            <OutcomesSelectedProvider>
              <Page main={<EditOutcome type={submissionType.edit} />} />
            </OutcomesSelectedProvider>
          </OutcomesProvider>
        }
      />
      {/* Routes for Output Tables */}
      <Route
        path="/output/table-1"
        element={
          <Page title={t("globals.title.outputTable1")} main={<Table1 />} />
        }
      />
      <Route
        path="/output/table-2"
        element={
          <Page title={t("globals.title.outputTable2")} main={<Table2 />} />
        }
      />
      <Route
        path="/output/table-3"
        element={
          <Page title={t("globals.title.outputTable3")} main={<Table3 />} />
        }
      />
      <Route
        path="/output/table-4"
        element={
          <Page
            title={t("globals.title.outputTable4")}
            main={<OutputTable4 />}
          />
        }
      />
      <Route
        path="/output/table-6A"
        element={
          <Page title={t("globals.title.outputTable6A")} main={<Table6A />} />
        }
      />
      <Route
        path="/output/table-6B"
        element={
          <Page title={t("globals.title.outputTable6B")} main={<Table6B />} />
        }
      />
      <Route
        path="/output/table-8A"
        element={
          <Page title={t("globals.title.outputTable8A")} main={<Table8A />} />
        }
      />
      <Route
        path="/output/table-8B"
        element={
          <Page title={t("globals.title.outputTable8B")} main={<Table8B />} />
        }
      />
      <Route
        path="*"
        element={
          <MuiAlert severity="error">
            <MuiAlertTitle>{t("common:pageNotFound.title")}</MuiAlertTitle>
            {t("common:pageNotFound.message")}
          </MuiAlert>
        }
      />
    </Routes>
  );
};
