import { useTranslation } from "react-i18next";
/** Material UI Components */
import {
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "app/services/outcomesSelectedContext";
/** Styles */
import { outcomeDetailsStyles } from "./OutcomesDetails.styles";
import {
  tableHeadCheckbox,
  whiteTableHeaderLabel,
} from "app/shared/ui/sharedStyles";

// ************ Display Outcomes Details Table HEAD ****************
const OutcomesDetailsTableHeader = ({
  hasAddOutcomeAccess,
  onRequestSort,
  order,
  orderBy,
}) => {
  const { t } = useTranslation();
  const outcomesDetailsTableColumns = "outcomes.mainView.list.tableColumns";

  const createSortHandler = (property) => (event) =>
    onRequestSort(event, property);

  let { ALL_SELECTED, SOME_SELECTED } = SelectAllStates;
  const { outcomesSelected, handleSelectAllChange } =
    useOutcomesSelectedContext();

  return (
    <MuiTableHead sx={whiteTableHeaderLabel}>
      <MuiTableRow sx={{ verticalAlign: "top" }}>
        <MuiTableCell
          align="left"
          style={{ width: "50px" }}
          sx={outcomeDetailsStyles.noDataTableCell.tableCellHead}
        >
          <MuiCheckbox
            sx={tableHeadCheckbox}
            size="small"
            checked={outcomesSelected.selectAllState === ALL_SELECTED}
            indeterminate={outcomesSelected.selectAllState === SOME_SELECTED}
            onChange={handleSelectAllChange}
            disabled={!hasAddOutcomeAccess}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          style={{ width: "150px" }}
          key="traineeLastName,traineeFirstName"
          sortDirection={
            orderBy === "traineeLastName,traineeFirstName" ? order : "asc"
          }
          sx={outcomeDetailsStyles.tableCellHead}
        >
          <MuiTableSortLabel
            direction={
              orderBy === "traineeLastName,traineeFirstName" ? order : "asc"
            }
            onClick={createSortHandler("traineeLastName,traineeFirstName")}
            active={orderBy === "traineeLastName,traineeFirstName"}
            sx={outcomeDetailsStyles.sortLabel}
          >
            {t(`${outcomesDetailsTableColumns}.trainee`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="lastInvitedDate,invitationStatus"
          style={{ width: "75px" }}
          sortDirection={
            orderBy === "lastInvitedDate,invitationStatus" ? order : "asc"
          }
          sx={outcomeDetailsStyles.tableCellHead}
        >
          <MuiTableSortLabel
            direction={
              orderBy === "lastInvitedDate,invitationStatus" ? order : "asc"
            }
            onClick={createSortHandler("lastInvitedDate,invitationStatus")}
            active={orderBy === "lastInvitedDate,invitationStatus"}
            sx={outcomeDetailsStyles.sortLabel}
          >
            {t(`${outcomesDetailsTableColumns}.lastInvitedDate`)}
            <br />
            {t(`${outcomesDetailsTableColumns}.invitationStatus`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="traineeType"
          style={{ width: "75px" }}
          sortDirection={orderBy === "traineeType" ? order : "asc"}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          <MuiTableSortLabel
            sx={outcomeDetailsStyles.sortLabel}
            active={orderBy === "traineeType"}
            direction={orderBy === "traineeType" ? order : "asc"}
            onClick={createSortHandler("traineeType")}
          >
            {t(`${outcomesDetailsTableColumns}.traineeType`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="degrees"
          style={{ width: "65px" }}
          sortDirection={orderBy === "degrees" ? order : "asc"}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          {t(`${outcomesDetailsTableColumns}.degrees`)}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="faculty"
          style={{ width: "150px" }}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          {t(`${outcomesDetailsTableColumns}.faculty`)}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="trainingPeriod"
          style={{ width: "150px" }}
          sortDirection={orderBy === "trainingStartDate" ? order : "asc"}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("trainingStartDate")}
            active={orderBy === "trainingStartDate"}
            sx={outcomeDetailsStyles.sortLabel}
          >
            {t(`${outcomesDetailsTableColumns}.trainingPeriod`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="supportDuringTraining"
          style={{ width: "12%" }}
          sortDirection={orderBy === "supportDuringTraining" ? order : "asc"}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          {t(`${outcomesDetailsTableColumns}.supportDuringTraining`)}
        </MuiTableCell>

        <MuiTableCell
          align="left"
          key="resultingDegrees"
          style={{ width: "12%" }}
          sortDirection={orderBy === "resultingDegrees" ? order : "asc"}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          {t(`${outcomesDetailsTableColumns}.resultingDegrees`)}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="researchTopic"
          style={{ width: "12%" }}
          sortDirection={orderBy === "researchTopic" ? order : "asc"}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("researchTopic")}
            active={orderBy === "researchTopic"}
            sx={outcomeDetailsStyles.sortLabel}
          >
            {t(`${outcomesDetailsTableColumns}.researchTopic`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          key="actions"
          align="left"
          style={{ width: "85px" }}
          sx={outcomeDetailsStyles.tableCellHead}
        >
          {t(`${outcomesDetailsTableColumns}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default OutcomesDetailsTableHeader;
