import { useState, useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableBody as MuiTableBody,
  TablePagination as MuiTablePagination,
  Tooltip as MuiTooltip,
  Link as MuiLink,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  Paper as MuiPaper,
} from "@mui/material";
import {
  RemoveCircle as RemoveIcon,
  Edit as EditIcon,
  AddCircle as AddCircleIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { format, parseISO } from "date-fns";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { AuditInfo } from "app/shared/ui/AuditInfo/AuditInfo";
import CollapseTable from "app/shared/ui/CollapseTable/CollapseTable";
import GrantsDetailsTableHead from "./GrantsDetailsTableHeader";
/** Services */
import {
  addRemoveGrantAction,
  getGrantsDetails,
} from "app/services/grantsService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useGrantsContext } from "app/services/grantsContext";
/** Context and Component to show the error on UI */
import { Theme, useAlerts } from "common";
/** Styles */
import { grantDetailsStyles } from "./GrantDetails.styles";
import { tableContainerRoot } from "app/shared/ui/sharedStyles";

export const GrantDetails = (props) => {
  const { t } = useTranslation();

  // Context Object
  const { grantsPreference, setGrantsPreference } = useGrantsContext();
  const { setMetaData } = useSelectedSubmission();
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;

  // Pagination
  const pageSize = useMemo(
    () => grantsPreference.grants.pagination.pageSize || 10,
    [grantsPreference]
  );
  const page = useMemo(
    () => grantsPreference.grants.pagination.page || 0,
    [grantsPreference]
  );

  // Sorting
  const order = useMemo(
    () => grantsPreference.grants.sort.order || "asc",
    [grantsPreference]
  );
  const orderBy = useMemo(
    () => grantsPreference.grants.sort.orderBy || "title",
    [grantsPreference]
  );

  const [data, setData] = useState();
  const selectedSubmission = getSelectedSubmission();
  // State to store the data of the grant to be removed
  const [removeGrantData, setRemoveGrantData] = useState({
    row: {},
    openConfirmationDialog: false,
  });

  const { setAlert, clearAlert } = useAlerts();

  const { history, setLoading, summary, setSummary } = props;

  const stringifiedGrantsPreference = JSON.stringify(grantsPreference);

  useEffect(() => {
    getGrantsDetails(
      setData,
      setSummary,
      setLoading,
      selectedSubmission,
      grantsPreference,
      history,
      setAlert,
      clearAlert,
      setMetaData,
      getSelectedSubmissionName,
      t
    );
    // eslint-disable-next-line
  }, [
    page,
    pageSize,
    selectedSubmission,
    orderBy,
    order,
    stringifiedGrantsPreference,
    history,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setGrantsPreference({
      ...grantsPreference,
      grants: {
        ...grantsPreference.grants,
        sort: {
          orderBy: property,
          order: isAsc,
        },
      },
    });
  };
  const handleChangePage = (event, newPage) => {
    setGrantsPreference({
      ...grantsPreference,
      grants: {
        ...grantsPreference.grants,
        pagination: {
          ...grantsPreference.grants.pagination,
          page: newPage,
        },
      },
    });
  };

  const handleChangePageSize = (event) => {
    let pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      summary.totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      pageValue = 0;
    }
    setGrantsPreference({
      ...grantsPreference,
      grants: {
        ...grantsPreference.grants,
        pagination: {
          ...grantsPreference.grants.pagination,
          page: pageValue,
          pageSize: parseInt(event.target.value, 10),
        },
      },
    });
  };

  // Add Grant to the submission
  const handleAddGrant = async (row) => {
    const newGrantsData = { ...row, isActive: true };
    await addRemoveGrantAction(
      selectedSubmission,
      newGrantsData,
      setAlert,
      clearAlert,
      setLoading,
      t
    );
    row.isActive = true;
  };

  // Close the confirmation dialog and reset the state
  const handleConfirmationDialogClose = () => {
    setRemoveGrantData((state) => ({
      row: {},
      openConfirmationDialog: false,
    }));
  };

  // Remove Grant from the submission
  const handleRemoveGrant = async () => {
    const newGrantsData = { ...removeGrantData.row, isActive: false };
    await addRemoveGrantAction(
      selectedSubmission,
      newGrantsData,
      setAlert,
      clearAlert,
      setLoading,
      t
    );
    handleConfirmationDialogClose();
    removeGrantData.row.isActive = false;
  };

  // If row is inactive, put the disableRow class
  const getDisableClass = (isRowActive) =>
    !isRowActive ? grantDetailsStyles.disableRow : {};

  const grantDialogLabel = "grant.mainView.delete.confirmationBox";
  return (
    <>
      <MuiTableContainer component={MuiPaper} sx={tableContainerRoot}>
        <MuiTable
          aria-label="customized table"
          stickyHeader
          sx={{
            tableLayout: "fixed",
            "& .MuiTableCell-root": {
              wordWrap: "break-word",
            },
          }}
        >
          <GrantsDetailsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <MuiTableBody>
            {data && Array.isArray(data) && data.length > 0 ? (
              data.map((row, index) => {
                return (
                  <CollapsibleTable
                    key={row._links.self.href}
                    grantsTableRow={row}
                    getDisableClass={getDisableClass}
                    handleAddGrant={handleAddGrant}
                    setRemoveGrantData={setRemoveGrantData}
                  />
                );
              })
            ) : (
              <MuiTableRow>
                <MuiTableCell colSpan="15">
                  <MuiTypography variant="body2" color="inherit" align="center">
                    {t("grant.mainView.list.noData")}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            )}
          </MuiTableBody>
        </MuiTable>
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={parseInt(summary.totalCount ?? 0)}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
          sx={grantDetailsStyles.table}
        />
      </MuiTableContainer>

      <ConfirmationDialog
        open={removeGrantData.openConfirmationDialog}
        title={t(`${grantDialogLabel}.title`)}
        message={t(`${grantDialogLabel}.body`, {
          name: removeGrantData.row.title,
        })}
        handleOk={handleRemoveGrant}
        handleCancel={handleConfirmationDialogClose}
        okLabel={t(`${grantDialogLabel}.okLabel`)}
        cancelLabel={t(`${grantDialogLabel}.cancelLabel`)}
      />
    </>
  );
};

const CollapsibleTable = ({
  grantsTableRow,
  getDisableClass,
  handleAddGrant,
  setRemoveGrantData,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  let grantsCollapseTableRow = [];
  grantsTableRow.participatingFacultyDetails &&
    grantsTableRow.participatingFacultyDetails.map((response) => {
      let dataObject = {};
      dataObject.univId = response.faculty.univId;
      dataObject.commonsId = response.commonsId;
      dataObject.fullName = response.faculty.fullName;

      dataObject.isParticipatingFaculty = response.isParticipatingFaculty
        ? "Yes"
        : "No";
      dataObject.isPrincipalInvestigator = response.isPrincipalInvestigator
        ? "Yes"
        : "No";
      return grantsCollapseTableRow.push(dataObject);
    });

  const collapsibleTableHeader = {
    empId: "Empl ID",
    commonId: "Commons ID",
    investigatorName: "Investigator Name",
    participatingFaculty: "Participating Faculty",
    principalInvestigator: "Principal Investigator",
  };

  return (
    <>
      <MuiTableRow
        sx={{
          verticalAlign: "top",
          color: grantsTableRow.isActive && Theme.palette.grey[300],
          "& .MuiIconButton-root": {
            height: "auto",
          },
        }}
      >
        <MuiTableCell>
          {grantsCollapseTableRow.length > 0 && grantsTableRow.isActive && (
            <MuiIconButton
              aria-label="expand row"
              size="small"
              sx={{ padding: 0 }}
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <KeyboardArrowUpIcon fontSize="small" />
              ) : (
                <KeyboardArrowDownIcon fontSize="small" />
              )}
            </MuiIconButton>
          )}
        </MuiTableCell>

        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.spoNumber}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.title}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.grantNumber}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {`${
            grantsTableRow.startDate
              ? format(parseISO(grantsTableRow.startDate), "MM/dd/yyyy")
              : ""
          } - ${
            grantsTableRow.endDate
              ? format(parseISO(grantsTableRow.endDate), "MM/dd/yyyy")
              : ""
          }`}
          <AuditInfo
            isEdited={grantsTableRow.startDateMod || grantsTableRow.endDateMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.predocPositionsCount}{" "}
          <AuditInfo
            isEdited={grantsTableRow.predocPositionsCountMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.postdocPositionsCount}{" "}
          <AuditInfo
            isEdited={grantsTableRow.postdocPositionsCountMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.numberOfShortTermPositions}{" "}
          <AuditInfo
            isEdited={grantsTableRow.numberOfShortTermPositionsMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {`${
            grantsTableRow.budgetPeriodStartDate
              ? format(
                  parseISO(grantsTableRow.budgetPeriodStartDate),
                  "MM/yyyy"
                )
              : ""
          } - ${
            grantsTableRow.budgetPeriodEndDate
              ? format(parseISO(grantsTableRow.budgetPeriodEndDate), "MM/yyyy")
              : ""
          }`}
          <AuditInfo
            isEdited={
              grantsTableRow.isbudgetPeriodStartDateMod ||
              grantsTableRow.budgetPeriodEndDateMod
            }
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.budgetPeriodDirectCost
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumSignificantDigits: 9,
              }).format(grantsTableRow.budgetPeriodDirectCost)
            : ""}{" "}
          <AuditInfo
            isEdited={grantsTableRow.currentYearCostMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.budgetPeriodTotalCost
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumSignificantDigits: 9,
              }).format(grantsTableRow.budgetPeriodTotalCost)
            : ""}{" "}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.department ?? ""}
          <AuditInfo
            isEdited={grantsTableRow.departmentMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.grantYear ?? ""}
          <AuditInfo
            isEdited={grantsTableRow.grantYearMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {`${
            grantsTableRow.numberOfParticipatingFaculty
              ? grantsTableRow.numberOfParticipatingFaculty
              : 0
          } (${
            grantsTableRow.numberOfOverlappingFaculty
              ? grantsTableRow.numberOfOverlappingFaculty
              : 0
          })`}{" "}
          <AuditInfo
            isEdited={grantsTableRow.numberOfOverlappingFacultyMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>
        <MuiTableCell
          align="left"
          sx={getDisableClass(grantsTableRow.isActive)}
        >
          {grantsTableRow.namesOfOverlappingFaculty
            ? grantsTableRow.namesOfOverlappingFaculty
                .map((faculty) => faculty["fullName"])
                .join("; ")
            : ""}{" "}
          <AuditInfo
            isEdited={grantsTableRow.namesOfOverlappingFacultyMod}
            isActive={grantsTableRow.isActive}
          />
        </MuiTableCell>

        <MuiTableCell align="left" sx={grantDetailsStyles.actionButton}>
          <span style={{ display: "flex" }}>
            <span>
              {grantsTableRow._links.editGrantDetails && (
                <MuiTooltip title={t("globals.list.actionIcons.edit.tooltip")}>
                  {grantsTableRow.isActive ? (
                    <MuiIconButton>
                      <RouterLink
                        to={`/grants/${grantsTableRow._links.editGrantDetails.href
                          .split("/")
                          .pop()}/edit`}
                      >
                        <EditIcon fontSize="small" />
                      </RouterLink>
                    </MuiIconButton>
                  ) : (
                    <MuiIconButton disabled={!grantsTableRow.isActive}>
                      <EditIcon fontSize="small" />
                    </MuiIconButton>
                  )}
                </MuiTooltip>
              )}
            </span>
            <span>
              {grantsTableRow.isActive ? (
                <MuiTooltip
                  title={t("globals.list.actionIcons.remove.tooltip")}
                >
                  <MuiIconButton
                    onClick={() => {
                      setRemoveGrantData({
                        row: grantsTableRow,
                        openConfirmationDialog: true,
                      });
                    }}
                  >
                    <MuiLink aria-label="remove">
                      {grantsTableRow._links.removeSubmissionGrant && (
                        <RemoveIcon fontSize="small" />
                      )}
                    </MuiLink>
                  </MuiIconButton>
                </MuiTooltip>
              ) : (
                <MuiTooltip title={t("globals.list.actionIcons.add.tooltip")}>
                  <MuiIconButton onClick={() => handleAddGrant(grantsTableRow)}>
                    <MuiLink aria-label="add">
                      {grantsTableRow._links.addGrant && (
                        <AddCircleIcon fontSize="small" />
                      )}
                    </MuiLink>
                  </MuiIconButton>
                </MuiTooltip>
              )}
            </span>
          </span>
        </MuiTableCell>
      </MuiTableRow>

      <MuiTableRow sx={grantDetailsStyles.collapseRowBackgroundColor}>
        <MuiTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: 38,
            paddingRight: 20,
          }}
          colSpan={16}
        >
          <CollapseTable
            label="grants"
            header={collapsibleTableHeader}
            body={grantsCollapseTableRow}
            open={open}
            tableClass={grantDetailsStyles.collapseWidth}
          />
        </MuiTableCell>
      </MuiTableRow>
    </>
  );
};
