import { useEffect, useState, createContext, useContext } from "react";
import { useLocation } from "react-router-dom";
import { usePagingSortingContext } from "./pagingSortingContext";
import { useBrowserStorage } from "./StorageService/StorageHelper";
import {
  STORAGE_NAMES,
  STORAGE_TYPE,
  isActiveOptions,
} from "app/shared/constants";

export const OutcomesContext = createContext();

export const OutcomesProvider = ({ children }) => {
  const location = useLocation();
  const { outcomesPagingSortingStored, setOutcomesPagingSortingStored } =
    usePagingSortingContext().value;
  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  const filterValues = getItem(
    STORAGE_NAMES.TGDS_OUTCOME_DASHBOARD_FILTERS,
    Object
  );

  const [outcomesPreferences, setOutcomesPreferencesInternal] = useState(
    initialOutcomeState({
      location: location,
      pageSort: outcomesPagingSortingStored,
      filterValues,
    })
  );
  const setOutcomesPreferences = (preference) => {
    preference = preference || {};

    const locPageSort = {
      pagination: preference.values?.pagination || {},
      sort: preference.values?.sort || {},
    };

    setOutcomesPagingSortingStored(locPageSort);
    setOutcomesPreferencesInternal(preference);
    saveItem(STORAGE_NAMES.TGDS_OUTCOME_DASHBOARD_FILTERS, preference);
  };

  useEffect(() => {
    setOutcomesPagingSortingStored({
      pagination: outcomesPreferences.values?.pagination || {},
      sort: outcomesPreferences.values?.sort || {},
    });
  }, [setOutcomesPagingSortingStored, outcomesPreferences]);

  const updatePreferencesForSubmission = (selectedSubmission) => {
    const initState = initialOutcomeState({
      filterValues: { values: { filters: { isActive: isActiveOptions[0] } } },
    });
    setOutcomesPreferences({
      ...initState,
      values: {
        ...initState.values,
        submission: selectedSubmission,
      },
    });
  };

  return (
    <OutcomesContext.Provider
      value={{
        outcomesPreferences,
        setOutcomesPreferences,
        updatePreferencesForSubmission,
      }}
    >
      {children}
    </OutcomesContext.Provider>
  );
};

export const initialOutcomeState = ({ location, pageSort, filterValues }) => {
  pageSort = pageSort || {};
  const { filters, pagination, sort } = filterValues?.values ?? {};

  return {
    values: {
      pagination: {
        page: parseInt(pagination?.page, 10) || 0,
        pageSize: parseInt(pagination?.pageSize, 10) || 0,
        ...pageSort.pagination,
      },
      sort: {
        order: sort?.order || "",
        orderBy: sort?.orderBy || "",
        ...pageSort.sort,
      },
      renderCount: 0,
      submission: 0,
      filters: {
        traineeName: filters?.traineeName || "",
        facultyName: filters?.facultyName || "",
        traineeType: filters?.traineeType || "",
        trainingStartDateFrom: filters?.trainingStartDateFrom || "",
        trainingStartDateTo: filters?.trainingStartDateTo || "",
        trainingEndDateFrom: filters?.trainingEndDateFrom || "",
        trainingEndDateTo: filters?.trainingEndDateTo || "",
        isActive: filters?.isActive ?? isActiveOptions[0],
      },
    },
  };
};

export const useOutcomesContext = () => {
  return useContext(OutcomesContext);
};
