import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  userRoles,
} from "app/shared/constants";
import { useAlerts } from "common";

export const usePeopleAPI = () => {
  const { clearAlert, setAlert } = useAlerts();
  const { t } = useTranslation();

  const [debounceTimeoutId, setDebounceTimeoutId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const loadPeopleOptions = async (
    searchQuery,
    setOptions,
    setNoOptionsText
  ) => {
    if (searchQuery.length < AUTOCOMPLETE_MIN_INPUT) {
      setOptions([]);
      setNoOptionsText(t("globals.autocomplete.helperText"));
      return;
    }

    clearTimeout(debounceTimeoutId);

    const timeoutId = setTimeout(async () => {
      try {
        clearAlert();
        setIsLoading(true);

        const response = await axios.get("/people/search", {
          params: {
            q: searchQuery,
          },
        });

        setOptions(response.data);

        if (response.data.length === 0) {
          setNoOptionsText(t("globals.autocomplete.noResultsText"));
        }
      } catch (error) {
        setAlert("error", error.message);
      } finally {
        setIsLoading(false);
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);

    setDebounceTimeoutId(timeoutId);
  };

  const loadPersonDetails = async (id, formik, fieldName) => {
    try {
      clearAlert();
      setIsLoading(true);

      const response = await axios(`/people/${id}`);

      const userRoleEnums = {
        admins: userRoles.submissionAdmin,
        observers: userRoles.observer,
      };

      const newValue = formik.values[fieldName].concat({
        name: response.data.displayName,
        profileId: response.data.profileId,
        role: userRoleEnums[fieldName],
        uid: response.data.uid,
      });

      formik.setFieldValue(fieldName, newValue);
    } catch (error) {
      setAlert("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { loadPeopleOptions, loadPersonDetails, isLoading };
};
