export const navbar = {
  width: 786,
  items: [
    {
      name: "Participating Faculty",
      menuItem: [],
      permName: "VIEW_ALL_SUBMISSIONS_FACULTY_DEMOGRAPHICS",
      route: "/faculty",
    },
    {
      name: "Training Grants",
      menuItem: [],
      permName: "VIEW_ALL_SUBMISSIONS_GRANT_DETAILS",
      route: "/grants",
    },
    {
      name: "Trainees",
      menuItem: [],
      permName: "VIEW_ALL_SUBMISSIONS_TRAINEE_DETAILS",
      route: "/trainees",
    },
    {
      name: "Applicants",
      menuItem: [],
      permName: "VIEW_ALL_SUBMISSIONS_APPLICANT",
      route: "/applicants",
    },
    {
      name: "Outcomes",
      menuItem: [],
      permName: "VIEW_ALL_SUBMISSIONS_OUTCOME",
      route: "/outcomes",
    },
    {
      name: "Output",
      menuItem: [
        {
          name: "Table 1",
          route: "/output/table-1",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
        {
          name: "Table 2",
          route: "/output/table-2",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
        {
          name: "Table 3",
          route: "/output/table-3",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
        {
          name: "Table 4",
          route: "/output/table-4",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
        {
          name: "Table 6A",
          route: "/output/table-6A",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
        {
          name: "Table 6B",
          route: "/output/table-6B",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
        {
          name: "Table 8A",
          route: "/output/table-8A",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
        {
          name: "Table 8B",
          route: "/output/table-8B",
          permName: "VIEW_ALL_SUBMISSIONS_OUTPUT",
        },
      ],
    },
    {
      name: "Admin",
      menuItem: [
        {
          name: "Manage Submissions",
          route: "/admin/submissions",
          permName: "VIEW_ALL_SUBMISSIONS",
        },
      ],
    },
  ],
};
