import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { Typography as MuiTypography } from "@mui/material";
/**  Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import {
  useApplicantsSelectedContext,
  SelectAllStates,
} from "app/services/applicantsSelectedContext";
import { useApplicantsFilterContext } from "app/services/applicantContext";
import { deleteSelectedApplicants } from "app/services/applicantService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";

export const RemoveApplicants = (props) => {
  const {
    openRemoveApplicantsDialog,
    setOpenRemoveApplicantsDialog,
    toggleApplicantsRemoved,
    totalCount,
  } = props;

  const { setAlert, clearAlert } = useAlerts();

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;

  const { applicantsFilterPreferences, setApplicantsFilterPreferences } =
    useApplicantsFilterContext();
  const { resetAllSelections, applicantsSelected } =
    useApplicantsSelectedContext();

  const { t } = useTranslation();

  const handleCancel = () => {
    clearAlert();
    setOpenRemoveApplicantsDialog(false);
  };

  const submissionId = getSelectedSubmission();

  const [countToDelete, setCountToDelete] = useState(0);

  useEffect(() => {
    if (applicantsSelected.selectAllState === SelectAllStates.NONE_SELECTED) {
      setCountToDelete(
        Object.keys(applicantsSelected.individualSelections).filter(
          (key) => applicantsSelected.individualSelections[key] === true
        ).length
      );
    } else {
      setCountToDelete(
        totalCount -
          Object.keys(applicantsSelected.individualSelections).filter(
            (key) => applicantsSelected.individualSelections[key] === false
          ).length
      );
    }
  }, [totalCount, applicantsSelected, setCountToDelete]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openRemoveApplicantsDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openRemoveApplicantsDialog]);

  return (
    <ConfirmationDialog
      open={openRemoveApplicantsDialog}
      message={
        <>
          <MuiTypography variant="body1" style={{ fontWeight: 700 }}>
            {t("applicants.mainView.delete.confirmationBox.body", {
              count: countToDelete,
              plural: countToDelete !== 1 ? "s" : "",
            })}
          </MuiTypography>
          <MuiTypography variant="body1">
            {t("globals.list.messages.wantToContinue")}
          </MuiTypography>
        </>
      }
      title={t("applicants.mainView.delete.confirmationBox.title")}
      handleCancel={() => handleCancel()}
      handleOk={() =>
        deleteSelectedApplicants(
          submissionId,
          setOpenRemoveApplicantsDialog,
          setAlert,
          clearAlert,
          resetAllSelections,
          toggleApplicantsRemoved,
          setApplicantsFilterPreferences,
          applicantsFilterPreferences,
          applicantsSelected,
          t
        )
      }
      okLabel={t("applicants.mainView.delete.confirmationBox.okLabel")}
      cancelLabel={t("applicants.mainView.delete.confirmationBox.cancelLabel")}
    />
  );
};
