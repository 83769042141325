import { Theme } from "common";

export const traineeStyles = {
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  button: {
    height: "40px",
    alignSelf: "flex-end",
    marginLeft: "24px",
    backgroundColor: Theme.palette.primary.main,
    color: Theme.palette.common.white,
    fontSize: "16px",
    "&:hover": {
      borderRadius: "5px",
      background: Theme.palette.primary.dark,
      fontSize: "16px",
      color: "ffffff",
    },
  },
  exportButton: {
    height: "40px",
    alignSelf: "flex-end",
    marginLeft: "24px",
    fontSize: "16px",
  },
  inputPanelWrapper: {
    width: "100%",
  },
  autocompleteOption: {},
  dummyAutocompleteOption: {
    color: Theme.palette.grey[800],
  },
};
