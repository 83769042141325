import { useState, useEffect, useCallback } from "react";
import { Link as RouteLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavigateNext as MuiNavigateNextIcon } from "@mui/icons-material";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  MenuItem as MuiMenuItem,
  Autocomplete as MuiAutocomplete,
  Grid2 as MuiGrid,
} from "@mui/material";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  // DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
/** Custom Components */
import { FormButton } from "app/shared/ui/Button/FormButton";
import {
  applicationTypeDropDown,
  EthnicityValues,
  submissionType,
} from "app/shared/constants";
import { useBackButtonCallback } from "app/shared/ui/useBackButtonCallback";
/** Services */
import {
  getApplicantAutocomplete,
  getApplicantById,
  postCreateApplicant,
  putCreateApplicant,
} from "app/services/applicantService";
import { getEditTraineeCitizenshipValues } from "app/services/traineeService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { getTraineeDepartments } from "app/services/TraineeDepartmentService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { createApplicantStyles } from "./CreateApplicant.styles";
import { sharedStyles } from "app/shared/ui/sharedStyles";

// ************ CreateApplicant Component ***********
const applicantFormInitialValues = {
  applicantName: "",
  applicantLastName: "",
  applicantFirstName: "",
  applicantMiddleName: "",
  applicantType: "",
  applicationYear: "",
  acceptedYear: "",
  degrees: [],
  departments: [],
  ftResearchExperience: 0,
  citizenship: null,
  ethnicity: null,
  priorInstitution: "",
  totalPublications: 0,
  totalPublicationsFirstAuthor: 0,
  isNewEntrant: false,
  isGrantEligible: false,
  isUnderrepresented: false,
  gpa: "",
};
export const RawCreateApplicants = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();

  // *********************** State Values ***********************
  // Initial Form State
  const [applicantFormValues, setApplicantFormValues] = useState(
    applicantFormInitialValues
  );
  const [parameter, setParameter] = useState("degree");
  const [size, setSize] = useState(5);
  const [stateValues, setStateValues] = useState(new Map());
  const [citizenshipList, setCitizenshipList] = useState([]);
  const [citizenshipInputValue, setCitizenshipInputValue] = useState("");
  const [inputValue, setInputValue] = useState(null);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const { submissionId, applicantId } = params;
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [, setLoading] = useState(false);
  const [defaultCitizenshipOptions, setDefaultCitizenshipOptions] = useState(
    []
  );
  const { setAlert, clearAlert } = useAlerts();

  useEffect(() => {
    getApplicantAutocomplete(
      parameter,
      size,
      inputValue,
      setAutoCompleteData,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [parameter, size, inputValue, navigate]);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();

    getTraineeDepartments(
      submissionId,
      () => {
        //onBeforeSend
        clearAlert();
        setLoading(true);
      },
      (error) => {
        //onError
        if (!axios.isCancel(error)) {
          setLoading(false);
          setAlert("error", error.message);
        }
      },
      (responseData) => {
        //onSuccess
        setProgramList(responseData.values || []);
      },
      cancelSource
    );
    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [submissionId, navigate]);

  // **** Pre-fetch Citizenship Dropdown values ****
  useEffect(() => {
    getEditTraineeCitizenshipValues(citizenshipInputValue, setCitizenshipList);
  }, [citizenshipInputValue]);

  useEffect(() => {
    getEditTraineeCitizenshipValues("a", setDefaultCitizenshipOptions);
  }, [setDefaultCitizenshipOptions]);

  // **** Form Validation Schema ****
  const createApplicantFormValidation = Yup.object().shape({
    applicantType: Yup.string().required("Required"),
    applicantLastName: Yup.string().required("Required"),
    applicantFirstName: Yup.string().required("Required"),
    departments: Yup.array().when("applicantType", {
      is: (val) => val === "PRE_DOC",
      then: () => Yup.array().min(1).required("Required"),
    }),
  });

  function isDuplicate(option, arr) {
    return arr.some((el) => {
      return (
        (option.code && el.code === option.code) ||
        (!option.code && el.displayText === option.displayText)
      );
    });
  }

  useEffect(() => {
    if (applicantId) {
      getApplicantById(
        submissionId,
        applicantId,
        setApplicantFormValues,
        setAlert,
        clearAlert,
        setLoading,
        applicationTypeDropDown,
        applicantFormInitialValues
      );
    }
  }, [submissionId, applicantId, setAlert, clearAlert]);

  const checkIfValidDates = (applicationYear, acceptedYear) => {
    return applicationYear && acceptedYear
      ? new Date(acceptedYear).getTime() < new Date(applicationYear).getTime()
      : false;
  };

  /** 
  redirect on cancel button click
  */
  const onCancelHandler = useCallback(() => {
    navigate("/applicants");
  }, [navigate]);

  // Handle back button
  useBackButtonCallback(onCancelHandler);

  /* Handle logic for ethnicity and underrepresented checbox */
  const handleEthnicityChange = (props) => {
    const isUnderrepresented = [
      "AMERICAN_INDIAN_OR_ALASKA_NATIVE",
      "BLACK_OR_AFRICAN_AMERICAN",
      "HISPANIC_OR_LATINO",
      "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER",
    ].includes(props);
    return isUnderrepresented;
  };

  /* Handle logic for citizenship and grant eligible checkbox*/
  const handleCitizenshipChange = (props) => {
    let isGrantEligible = false;
    if (props) {
      isGrantEligible = ["USA"].includes(props.code);
    }
    return isGrantEligible;
  };
  const applicantAddLabel = "applicants.create.applicantForm";
  const applicantEditLabel = "applicants.edit";
  return (
    <MuiGrid
      container
      wrap="nowrap"
      justify="flex-start"
      direction="column"
      spacing={2}
    >
      <MuiGrid>
        <MuiBreadcrumbs
          separator={<MuiNavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={sharedStyles.breadcrumb}
        >
          <RouteLink to="/applicants">{t("applicants.title")}</RouteLink>
          <MuiTypography color="textPrimary">
            {props.type === submissionType.create
              ? t("applicants.create.title")
              : t("applicants.create.edit")}
          </MuiTypography>
        </MuiBreadcrumbs>
      </MuiGrid>
      <MuiGrid size={11}>
        <MuiTypography variant="h5">
          {props.type === submissionType.create
            ? t("applicants.create.new")
            : t("applicants.create.edit")}
        </MuiTypography>
      </MuiGrid>
      <Formik
        initialValues={applicantFormValues}
        enableReinitialize={true}
        validationSchema={createApplicantFormValidation}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          props.type === submissionType.create
            ? postCreateApplicant(
                getSelectedSubmission(),
                values,
                navigate,
                setAlert,
                clearAlert,
                t
              )
            : putCreateApplicant(
                submissionId,
                applicantId,
                values,
                navigate,
                setAlert,
                clearAlert,
                t
              );

          setSubmitting(false);
        }}
      >
        {(props) => {
          const {
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
          } = props;
          const displayPredoc =
            values.applicantType !== null
              ? values.applicantType === "PRE_DOC"
              : null;
          const displayPostdoc =
            values.applicantType !== null
              ? values.applicantType === "POST_DOC"
              : null;
          return (
            <form onSubmit={handleSubmit} style={{ display: "flex" }}>
              <MuiGrid
                container
                direction="column"
                justify="space-between"
                spacing={2}
                sx={{ flex: "0.15 0 auto" }}
              >
                <MuiTextField
                  name="applicantLastName"
                  label={`${t(`${applicantAddLabel}.applicantsLastName`)}`}
                  value={values.applicantLastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  sx={createApplicantStyles.singleFieldStyle}
                  autoComplete="off"
                  inputProps={{ maxLength: 200 }}
                  required
                />
                <MuiTextField
                  name="applicantFirstName"
                  label={`${t(`${applicantAddLabel}.applicantsFirstName`)}`}
                  value={values.applicantFirstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  sx={createApplicantStyles.singleFieldStyle}
                  autoComplete="off"
                  inputProps={{ maxLength: 200 }}
                  required
                />
                <MuiTextField
                  name="applicantMiddleName"
                  label={`${t(`${applicantAddLabel}.applicantsMiddleName`)}`}
                  value={values.applicantMiddleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  sx={createApplicantStyles.singleFieldStyle}
                  inputProps={{ maxLength: 50 }}
                  autoComplete="off"
                />

                <MuiAutocomplete
                  options={Object.keys(applicationTypeDropDown)}
                  getOptionLabel={(option) =>
                    t(applicationTypeDropDown[option])
                  }
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={`${t(`${applicantAddLabel}.applicantType`)}*`}
                      variant="outlined"
                    />
                  )}
                  id="applicantType"
                  value={values.applicantType}
                  clearOnEscape={true}
                  onBlur={handleBlur}
                  onChange={(e, value) => {
                    if (value) {
                      props.setFieldValue("applicantType", value);
                    }
                  }}
                />
                <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
                  <div style={createApplicantStyles.yearStyled}>
                    <MuiTextField
                      select
                      id="applicationYear"
                      label={t(`${applicantAddLabel}.appYr`)}
                      value={values.applicationYear}
                      onChange={handleChange("applicationYear")}
                      InputLabelProps={
                        values.applicationYear
                          ? { shrink: true }
                          : { shrink: false }
                      }
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    >
                      {yearOptions.map((option) => (
                        <MuiMenuItem key={option} value={option}>
                          {option}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </div>
                </MuiLocalizationProvider>

                <MuiLocalizationProvider dateAdapter={MuiAdapterDateFns}>
                  <div style={createApplicantStyles.yearStyled}>
                    <MuiTextField
                      select
                      id="acceptedYear"
                      label={t(`${applicantAddLabel}.acceptYr`)}
                      value={values.acceptedYear}
                      error={checkIfValidDates(
                        values.applicationYear,
                        values.acceptedYear
                      )}
                      helperText={
                        checkIfValidDates(
                          values.applicationYear,
                          values.acceptedYear
                        )
                          ? t(`${applicantEditLabel}.acceptedYearMessage`)
                          : ""
                      }
                      onChange={handleChange("acceptedYear")}
                      InputLabelProps={
                        values.acceptedYear
                          ? { shrink: true }
                          : { shrink: false }
                      }
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    >
                      {yearOptions.map((option) => (
                        <MuiMenuItem key={option} value={option}>
                          {option}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </div>
                </MuiLocalizationProvider>

                <MuiTextField
                  id="priorInstitution"
                  label={`Prior Institution`}
                  value={values.priorInstitution || ""}
                  onChange={(e) => {
                    const prior = e.target.value
                      .trimLeft()
                      .replace(/[ ]{2,}/gi, " ");
                    props.setFieldValue("priorInstitution", prior);
                  }}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  fullWidth
                  autoComplete="off"
                />
                <MuiAutocomplete
                  id="citizenship"
                  name="citizenship"
                  options={
                    citizenshipInputValue
                      ? citizenshipList
                      : defaultCitizenshipOptions
                  }
                  noOptionsText={t(
                    "trainee.edit.traineeForm.citizenshipHelperText"
                  )}
                  getOptionLabel={(option) => option.name ?? ""}
                  value={values.citizenship}
                  clearOnEscape={true}
                  onBlur={handleBlur}
                  onChange={(e, value) => {
                    props.setFieldValue("citizenship", value);
                    props.setFieldValue(
                      "isGrantEligible",
                      handleCitizenshipChange(value)
                    );
                  }}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={t(`${applicantEditLabel}.citizenship`)}
                      variant="outlined"
                      onChange={(e) => {
                        setCitizenshipInputValue(e.target.value);
                      }}
                    />
                  )}
                />
                <MuiAutocomplete
                  options={Object.keys(EthnicityValues)}
                  getOptionLabel={(option) => t(EthnicityValues[option])}
                  id="ethnicity"
                  value={values.ethnicity}
                  clearOnEscape={true}
                  onBlur={handleBlur}
                  onChange={(e, value) => {
                    props.setFieldValue("ethnicity", value);
                    props.setFieldValue(
                      "isUnderrepresented",
                      handleEthnicityChange(value)
                    );
                  }}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={t(`${applicantEditLabel}.ethnicGroup`)}
                      variant="outlined"
                    />
                  )}
                />

                {displayPostdoc && (
                  <>
                    <MuiAutocomplete
                      multiple
                      id="degrees"
                      name="degrees"
                      options={
                        autoCompleteData.count > 0
                          ? autoCompleteData.values.map(
                              (degreeObj) => degreeObj.value
                            )
                          : []
                      }
                      getOptionLabel={(option) => option.code ?? ""}
                      value={values.degrees}
                      clearOnEscape={true}
                      onBlur={handleBlur}
                      onChange={(e, value) => {
                        if (value && displayPostdoc) {
                          props.setFieldValue("degrees", value);
                        }
                      }}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={t(`${applicantEditLabel}.degrees`)}
                          variant="outlined"
                          onChange={(e) => {
                            setParameter("degree");
                            setSize(5);
                            setInputValue(e.target.value);
                          }}
                        />
                      )}
                    />

                    <MuiTextField
                      id="totalPublications"
                      label={t(`${applicantEditLabel}.totalPublication`)}
                      value={
                        isNaN(values.totalPublications)
                          ? ""
                          : values.totalPublications
                      }
                      onChange={(e) => {
                        if (e.target.value >= 0 && displayPostdoc) {
                          props.setFieldValue(
                            "totalPublications",
                            parseInt(e.target.value)
                          );
                        }
                      }}
                      onBlur={handleBlur}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="totalPublicationsFirstAuthor"
                      label={t(
                        `${applicantEditLabel}.totalPublicationFirstAuthor`
                      )}
                      value={
                        isNaN(values.totalPublicationsFirstAuthor)
                          ? ""
                          : values.totalPublicationsFirstAuthor
                      }
                      onChange={(e) => {
                        if (e.target.value >= 0 && displayPostdoc) {
                          props.setFieldValue(
                            "totalPublicationsFirstAuthor",
                            parseInt(e.target.value)
                          );
                        }
                      }}
                      onBlur={handleBlur}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    />
                  </>
                )}
                {displayPredoc && (
                  <>
                    <MuiAutocomplete
                      multiple
                      id="departments"
                      name="departments"
                      options={programList}
                      getOptionLabel={(option) =>
                        option ? `${option.name} (${option.code})` : ""
                      }
                      value={values.departments}
                      clearOnEscape={true}
                      onBlur={handleBlur}
                      onChange={(e, value) => {
                        if (value) {
                          props.setFieldValue("departments", value);
                          setStateValues(
                            (stateValues) =>
                              new Map(stateValues.set("departments", value))
                          );
                        }
                      }}
                      getOptionDisabled={(option) =>
                        isDuplicate(
                          option,
                          stateValues.get("departments") || values.departments
                        )
                      }
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={`${t(
                            `${applicantEditLabel}.participatingDepartment`
                          )}*`}
                          variant="outlined"
                        />
                      )}
                    />
                    <MuiTextField
                      id="ftResearchExperience"
                      label={t(`${applicantEditLabel}.researchExperience`)}
                      value={
                        isNaN(values.ftResearchExperience)
                          ? ""
                          : values.ftResearchExperience
                      }
                      onChange={(e) => {
                        if (e.target.value >= 0 && displayPredoc) {
                          props.setFieldValue(
                            "ftResearchExperience",
                            parseFloat(e.target.value)
                          );
                        }
                      }}
                      onBlur={handleBlur}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="gpa"
                      label={`GPA`}
                      value={isNaN(values.gpa) ? "" : values.gpa}
                      clearOnEscape={true}
                      onChange={(e) => {
                        if (
                          e.target.value >= 0.0 &&
                          e.target.value <= 4.0 &&
                          displayPredoc
                        ) {
                          props.setFieldValue(
                            "gpa",
                            e.target.value
                              .toString()
                              .split(".")
                              .map((el, i) =>
                                i ? el.split("").slice(0, 1).join("") : el
                              )
                              .join(".")
                          );
                        }
                      }}
                      onBlur={handleBlur}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    />
                  </>
                )}

                <div style={createApplicantStyles.checkBoxGroupedDivStyled}>
                  <div style={createApplicantStyles.checkBoxTitleDivStyled}>
                    <MuiFormControlLabel
                      control={
                        <MuiCheckbox
                          name="isNewEntrant"
                          value={values.isNewEntrant}
                          disableRipple={true}
                          onChange={(e) => {
                            props.setFieldValue(
                              "isNewEntrant",
                              e.target.checked
                            );
                          }}
                          checked={values.isNewEntrant}
                          color="primary"
                        />
                      }
                      label={`${t(`${applicantEditLabel}.newEntrant`)}`}
                    />
                  </div>
                  <div style={createApplicantStyles.checkBoxTitleDivStyled}>
                    <MuiFormControlLabel
                      control={
                        <MuiCheckbox
                          name="isGrantEligible"
                          disableRipple={true}
                          value={values.isGrantEligible}
                          onChange={(e) => {
                            props.setFieldValue(
                              "isGrantEligible",
                              e.target.checked
                            );
                          }}
                          checked={values.isGrantEligible}
                          color="primary"
                        />
                      }
                      label={`${t(`${applicantEditLabel}.grantEligible`)}`}
                    />
                  </div>
                  <div style={createApplicantStyles.checkBoxTitleDivStyled}>
                    <MuiFormControlLabel
                      control={
                        <MuiCheckbox
                          name="isUnderrepresented"
                          disableRipple={true}
                          value={values.isUnderrepresented}
                          onChange={(e) => {
                            props.setFieldValue(
                              "isUnderrepresented",
                              e.target.checked
                            );
                          }}
                          checked={values.isUnderrepresented}
                          color="primary"
                        />
                      }
                      label={`${t(`${applicantEditLabel}.underRepresented`)}`}
                    />
                  </div>
                </div>

                <MuiGrid container justifyContent="flex-end">
                  <FormButton
                    onClick={onCancelHandler}
                    name={t("globals.form.actionButtons.cancel")}
                    variant="outlined"
                  />

                  <FormButton
                    name={t("globals.form.actionButtons.save")}
                    type="submit"
                    variant="contained"
                    disabled={
                      isSubmitting ||
                      !dirty ||
                      !isValid ||
                      checkIfValidDates(
                        values.applicationYear,
                        values.acceptedYear
                      )
                    }
                  />
                </MuiGrid>
              </MuiGrid>
            </form>
          );
        }}
      </Formik>
    </MuiGrid>
  );
};

const populateYearDropDown = () => {
  let currentYearPlusOne = new Date().getFullYear() + 1;
  let yearOptions = [];
  for (let i = 0; i <= 20; i++) {
    yearOptions.push(currentYearPlusOne - i);
  }
  return yearOptions;
};

const yearOptions = populateYearDropDown();

export const CreateApplicant = RawCreateApplicants;
