import { useEffect, useState } from "react";
import {
  Box as MuiBox,
  Grid2 as MuiGrid,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Dialog as MuiDialog,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  TableContainer as MuiTableContainer,
  LinearProgress as MuiLinearProgress,
  TablePagination as MuiTablePagination,
  DialogContentText as MuiDialogContentText,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  Sync as MuiSyncIcon,
  Delete as MuiDeleteIcon,
  Warning as MuiWarningIcon,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  userRoles,
  operationStatusEnums,
  SUB_OPERATION_STATUS,
} from "app/shared/constants";
import { Theme, useAlerts } from "common";
import { deleteSubmissionById } from "app/services/submissionService";
import { useManageSubmissionsContext } from "app/services/manageSubmissionsContext";
import { ManageSubmissionTableHeader } from "./ManageSubmissionTableHeader";

export const ManageSubmissionDetails = ({
  loading,
  setLoading,
  setSnackBar,
  tableData,
  totalCount,
}) => {
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();
  const { submissionPreference, setSubmissionPreference } =
    useManageSubmissionsContext();

  // State variables
  const initialSortValues = submissionPreference.manageSubmissions.sort;
  const [orderBy, setOrderBy] = useState(initialSortValues.orderBy || "name");
  const [order, setOrder] = useState(initialSortValues.order || "asc");

  const initialPaginationValues =
    submissionPreference.manageSubmissions.pagination;
  const [page, setPage] = useState(initialPaginationValues.page || 0);
  const [pageSize, setPageSize] = useState(
    initialPaginationValues.pageSize || 10
  );

  const [open, setOpen] = useState();
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const submissionYearMonth = (submissionYear, month) => {
    const submissionMonth = month.toString().length < 2 ? `0${month}` : month;
    return `${submissionMonth}/${submissionYear}`;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrder(isAsc);
    setOrderBy(property);

    setSubmissionPreference({
      ...submissionPreference,
      manageSubmissions: {
        ...submissionPreference.manageSubmissions,
        sort: {
          orderBy: property,
          order: isAsc,
        },
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    setSubmissionPreference({
      ...submissionPreference,
      manageSubmissions: {
        ...submissionPreference.manageSubmissions,
        pagination: {
          ...submissionPreference.manageSubmissions.pagination,
          page: newPage,
        },
      },
    });
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    let pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }

    setSubmissionPreference({
      ...submissionPreference,
      manageSubmissions: {
        ...submissionPreference.manageSubmissions,
        pagination: {
          ...submissionPreference.manageSubmissions.pagination,
          page: pageValue,
          pageSize: parseInt(event.target.value, 10),
        },
      },
    });
  };

  const handleDeleteSubmission = (submissionId) => {
    setOpen(false);
    deleteSubmissionById(
      submissionId,
      setSnackBar,
      submissionPreference,
      setSubmissionPreference,
      setLoading,
      setAlert,
      clearAlert
    );
  };

  // useEffect to update the page number
  useEffect(() => {
    if (page !== submissionPreference.manageSubmissions.pagination.page) {
      setPage(submissionPreference.manageSubmissions.pagination.page);
    }
  }, [page, submissionPreference.manageSubmissions.pagination.page]);

  return (
    <MuiTableContainer component={MuiPaper} sx={{ width: "100%" }}>
      <MuiTable aria-label="customized table" stickyHeader>
        <ManageSubmissionTableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <MuiTableBody>
          {loading ? (
            <MuiTableRow>
              <MuiTableCell colSpan="5">
                <MuiTypography
                  variant="h6"
                  color="textSecondary"
                  align="center"
                  paragraph={false}
                >
                  {t("submission.manage.submissionList.dataLoadingText")}
                </MuiTypography>
              </MuiTableCell>
            </MuiTableRow>
          ) : !loading && tableData?.length > 0 ? (
            tableData.map((row, index) => (
              <MuiTableRow key={row.name + row.year + index}>
                <MuiTableCell component="th" scope="row">
                  {row.name}
                </MuiTableCell>
                <MuiTableCell align="left">
                  {submissionYearMonth(row.year, row.month)}
                </MuiTableCell>
                <MuiTableCell align="left">
                  {(row.operationStatus ===
                    SUB_OPERATION_STATUS.COPY_IN_PROGRESS ||
                    row.operationStatus ===
                      SUB_OPERATION_STATUS.LOAD_IN_PROGRESS) && (
                    <>
                      <MuiSyncIcon
                        fontSize="small"
                        sx={{
                          marginRight: "2px",
                          marginBottom: "-5px",
                          animation: "spin 2s linear infinite",
                          "@keyframes spin": {
                            "0%": {
                              transform: "rotate(360deg)",
                            },
                            "100%": {
                              transform: "rotate(0deg)",
                            },
                          },
                        }}
                      />
                    </>
                  )}
                  {t(operationStatusEnums[row.operationStatus])}
                  {row.operationStatus === SUB_OPERATION_STATUS.FAILED && (
                    <MuiTooltip title={row.failureReason}>
                      <MuiWarningIcon
                        fontSize="small"
                        color="warning"
                        sx={{ marginLeft: "4px", marginBottom: "-5px" }}
                      />
                    </MuiTooltip>
                  )}
                </MuiTableCell>
                <MuiTableCell align="left">
                  {row.grant ? (
                    <MuiTooltip
                      title={`${row.grant.spoNumber}, ${row.grant.grantNumber}, ${row.grant.title}`}
                    >
                      <span>{row.grant.spoNumber}</span>
                    </MuiTooltip>
                  ) : (
                    ""
                  )}
                </MuiTableCell>
                <MuiTableCell align="left">
                  {row.assignees &&
                    row.assignees
                      .filter(
                        (assignee) =>
                          assignee.role === userRoles.submissionAdmin
                      )
                      .map((assignee) => assignee.name)
                      .join(", ")}
                </MuiTableCell>

                <MuiTableCell align="left">
                  {row.assignees &&
                    row.assignees
                      .filter(
                        (assignee) => assignee.role === userRoles.observer
                      )
                      .map((assignee) => assignee.name)

                      .join(", ")}
                </MuiTableCell>

                <MuiTableCell
                  align="left"
                  sx={{
                    width: 30,
                    padding: "5px 0px 0px 0px",
                    "& .MuiIconButton-root": {
                      fontSize: "0.5rem",
                    },
                  }}
                >
                  <span style={{ display: "flex" }}>
                    {row._links.edit ? (
                      <MuiTooltip
                        title={t("globals.list.actionIcons.edit.tooltip")}
                      >
                        <MuiIconButton aria-label="edit">
                          <Link
                            to={`/admin/submissions/${row._links.edit.href.substring(
                              row._links.edit.href.lastIndexOf("/") + 1,
                              row._links.edit.href.length
                            )}/edit`}
                          >
                            <MuiEditIcon fontSize="small" />
                          </Link>
                        </MuiIconButton>
                      </MuiTooltip>
                    ) : (
                      ""
                    )}
                    {row._links.delete ? (
                      <MuiTooltip
                        title={t("globals.list.actionIcons.delete.tooltip")}
                      >
                        <MuiIconButton
                          aria-label="delete"
                          onClick={() => {
                            setOpen(row.name);
                            setSnackBar(false);
                          }}
                        >
                          <MuiDeleteIcon fontSize="small" />
                        </MuiIconButton>
                      </MuiTooltip>
                    ) : (
                      ""
                    )}
                    <MuiDialog
                      sx={{
                        "& .MuiDialogTitle-root": {
                          paddingBottom: 0,
                        },
                        "& span.MuiTypography-root": {
                          color: Theme.palette.text.primary,
                        },
                        "& .MuiDialogActions-root": {
                          paddingTop: 0,
                          "& .MuiButton-root:first-of-type": {
                            marginRight: "25px",
                          },
                        },
                      }}
                      open={row.name === open}
                      onClose={handleCloseDialog}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <MuiDialogTitle id="alert-dialog-title">
                        {t(
                          "globals.list.actionIcons.delete.dialog.dialogTitle"
                        )}
                      </MuiDialogTitle>
                      <MuiDialogContent>
                        <MuiDialogContentText id="alert-dialog-description">
                          {t(
                            "globals.list.actionIcons.delete.dialog.dialogMessage"
                          )}
                          <MuiTypography component="span">
                            {" "}
                            "{row.name}"?
                          </MuiTypography>
                        </MuiDialogContentText>
                      </MuiDialogContent>
                      <MuiDialogActions>
                        <MuiGrid container justifyContent="flex-end">
                          <MuiButton
                            onClick={() => handleCloseDialog()}
                            color="primary"
                          >
                            {t(
                              "globals.list.actionIcons.delete.dialog.dialogCancel"
                            )}
                          </MuiButton>
                          <MuiButton
                            onClick={() =>
                              handleDeleteSubmission(
                                row._links.delete.href.substring(
                                  row._links.delete.href.lastIndexOf("/") + 1,
                                  row._links.delete.href.length
                                )
                              )
                            }
                            color="primary"
                          >
                            {t(
                              "globals.list.actionIcons.delete.dialog.dialogDelete"
                            )}
                          </MuiButton>
                        </MuiGrid>
                      </MuiDialogActions>
                    </MuiDialog>
                  </span>
                </MuiTableCell>
              </MuiTableRow>
            ))
          ) : (
            <MuiTableRow>
              <MuiTableCell colSpan="6">
                <MuiTypography variant="body2" color="inherit" align="center">
                  {t("globals.list.messages.noData")}
                </MuiTypography>
              </MuiTableCell>
            </MuiTableRow>
          )}
        </MuiTableBody>
      </MuiTable>
      {loading && (
        <MuiBox sx={{ width: "100%" }}>
          <MuiLinearProgress />
        </MuiBox>
      )}
      {totalCount > 0 && (
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={pageSize}
          page={parseInt(page) || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
        />
      )}
    </MuiTableContainer>
  );
};
