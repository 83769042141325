import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Dialog as MuiDialog,
  Grid2 as MuiGrid,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
  Paper as MuiPaper,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TextField as MuiTextField,
  Button as MuiButton,
  Box as MuiBox,
} from "@mui/material";
import { OutcomeSendInvitationStepEnums } from "app/shared/constants";
/** Utilities */
import { useFormikContext, Formik } from "formik";
import * as Yup from "yup";
/**  Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useOutcomesContext } from "app/services/outcomesContext";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "app/services/outcomesSelectedContext";
import {
  getOutcomeEmailInvitationRecipients,
  getOutcomeEmailInvitationFields,
  sendOutcomeInvitationEmail,
  previewOutcomeInvitationEmail,
} from "app/services/outcomesService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { sendInvitationStyles } from "./SendInvitation.styles";

export const SendInvitation = (props) => {
  const {
    openSendInvitationDialog,
    setOpenSendInvitationDialog,
    toggleInvitationsSent,
    outcomesPreferences,
    setOutcomesPreferences,
  } = props;

  const [outcomeTraineeList, setOutcomeTraineeList] = useState([]);
  const [emailFields, setEmailFields] = useState({});
  const [emailPreviewFields, setEmailPreviewFields] = useState({});

  const { setAlert, clearAlert } = useAlerts();

  const [activeStep, setActiveStep] = useState(
    OutcomeSendInvitationStepEnums.VERIFY_TRAINEE_LIST.stepIndex
  );

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;

  const { resetAllSelections } = useOutcomesSelectedContext();

  const { t } = useTranslation();
  const getSteps = () => {
    return [
      t(OutcomeSendInvitationStepEnums.VERIFY_TRAINEE_LIST.stepLabel),
      t(OutcomeSendInvitationStepEnums.PROVIDE_EMAIL_DETAILS.stepLabel),
      t(OutcomeSendInvitationStepEnums.PREVIEW_AND_SEND.stepLabel),
    ];
  };
  const steps = getSteps();

  const handleCancel = () => {
    clearAlert();
    setOpenSendInvitationDialog(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = (formik) => {
    if (
      activeStep ===
      OutcomeSendInvitationStepEnums.PROVIDE_EMAIL_DETAILS.stepIndex
    ) {
      setEmailPreviewFields({
        recipientOutcomeIds: outcomeTraineeList[0]._links.self.href
          .split("/")
          .slice(-1),
        fromEmailAddress: formik.values.fromEmailAddress,
        fromName: formik.values.fromName,
        replyToEmailAddress: formik.values.replyToEmailAddress,
        subject: formik.values.subject,
        message: formik.values.message,
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const submissionId = getSelectedSubmission();

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case OutcomeSendInvitationStepEnums.VERIFY_TRAINEE_LIST.stepIndex:
        return (
          <VerifyTraineeList
            outcomeTraineeList={outcomeTraineeList}
            setOutcomeTraineeList={setOutcomeTraineeList}
            submissionId={submissionId}
            setAlert={setAlert}
            clearAlert={clearAlert}
          />
        );
      case OutcomeSendInvitationStepEnums.PROVIDE_EMAIL_DETAILS.stepIndex:
        return (
          <ProvideEmailDetails
            setEmailFields={setEmailFields}
            submissionId={submissionId}
            setAlert={setAlert}
            clearAlert={clearAlert}
          />
        );
      case OutcomeSendInvitationStepEnums.PREVIEW_AND_SEND.stepIndex:
        return (
          <PreviewAndSend
            emailPreviewFields={emailPreviewFields}
            submissionId={submissionId}
            setAlert={setAlert}
            clearAlert={clearAlert}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const initialValues = {
    fromEmailAddress: emailFields.fromEmailAddress || "",
    fromName: emailFields.fromName || "",
    replyToEmailAddress: emailFields.replyToEmailAddress || "",
    subject: emailFields.subject || "",
    message: emailFields.message || "",
  };

  const sendInvitationFormValidation = Yup.object().shape({
    fromEmailAddress: Yup.string()
      .email(t("sendInvitation.notification.email"))
      .required("Required"),
    fromName: Yup.string().required("Required"),
    replyToEmailAddress: Yup.string()
      .email(t("sendInvitation.notification.email"))
      .nullable(),
    subject: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
  });

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openSendInvitationDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openSendInvitationDialog]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={sendInvitationFormValidation}
      validateOnMount={true}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(false);
        const responseStatus = await sendOutcomeInvitationEmail(
          outcomeTraineeList,
          values,
          submissionId,
          setOpenSendInvitationDialog,
          setAlert,
          clearAlert,
          resetAllSelections,
          toggleInvitationsSent,
          t
        );
        if (responseStatus === 200) {
          setOutcomesPreferences({
            ...outcomesPreferences,
            values: {
              ...outcomesPreferences.values,
              renderCount: outcomesPreferences.values.renderCount + 1,
            },
          });
        }
      }}
    >
      {(formik) => {
        return (
          <form>
            <MuiDialog
              open={openSendInvitationDialog}
              maxWidth="sm"
              scroll="paper"
              fullWidth={true}
              sx={sendInvitationStyles.dialogPaperWidthStyle}
              aria-labelledby="send-email-invitation-dialog-title"
            >
              <MuiDialogTitle
                id="send-email-invitation-dialog-title"
                sx={sendInvitationStyles.dialogTitleStyle}
              >
                <MuiGrid container justify="flex-start" direction="column">
                  <MuiGrid sx={sendInvitationStyles.stepperWrapper}>
                    {t("sendInvitation.title", {
                      numberOfTrainees:
                        outcomeTraineeList.length > 0
                          ? outcomeTraineeList.length
                          : "",
                    })}
                  </MuiGrid>
                  <MuiGrid sx={sendInvitationStyles.stepperWrapper}>
                    <MuiStepper
                      activeStep={activeStep}
                      sx={sendInvitationStyles.stepperStyle}
                    >
                      {steps.map((label) => (
                        <MuiStep key={label}>
                          <MuiStepLabel>{label}</MuiStepLabel>
                        </MuiStep>
                      ))}
                    </MuiStepper>
                  </MuiGrid>
                </MuiGrid>
              </MuiDialogTitle>

              <MuiDialogContent
                dividers={true}
                sx={sendInvitationStyles.dialogContentStyle}
              >
                {getStepContent(activeStep)}
              </MuiDialogContent>
              <MuiDialogActions sx={sendInvitationStyles.dialogActionsStyle}>
                <MuiButton
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  color="primary"
                >
                  {t("globals.form.actionButtons.back")}
                </MuiButton>

                <div
                  className={sendInvitationStyles.dialogActionsButtonsFlexDiv}
                />
                <MuiButton
                  onClick={handleCancel}
                  variant="outlined"
                  color="primary"
                >
                  {t("globals.form.actionButtons.cancel")}
                </MuiButton>
                {activeStep === steps.length - 1 ? (
                  <MuiButton
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {t("globals.form.actionButtons.send")}
                  </MuiButton>
                ) : (
                  <MuiButton
                    onClick={() => handleNext(formik)}
                    variant="contained"
                    color="primary"
                    disabled={
                      outcomeTraineeList.length === 0 ||
                      (activeStep ===
                        OutcomeSendInvitationStepEnums.PROVIDE_EMAIL_DETAILS
                          .stepIndex &&
                        !formik.isValid)
                    }
                  >
                    {t("globals.form.actionButtons.next")}
                  </MuiButton>
                )}
              </MuiDialogActions>
            </MuiDialog>
          </form>
        );
      }}
    </Formik>
  );
};

const VerifyTraineeList = (props) => {
  const {
    outcomeTraineeList,
    setOutcomeTraineeList,
    submissionId,
    setAlert,
    clearAlert,
  } = props;

  const { outcomesPreferences } = useOutcomesContext();
  const outcomeFilters = outcomesPreferences.values.filters;
  const { outcomesSelected } = useOutcomesSelectedContext();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOutcomeEmailInvitationRecipients(
      setOutcomeTraineeList,
      submissionId,
      setLoading,
      setAlert,
      clearAlert,
      outcomeFilters,
      outcomesSelected,
      SelectAllStates
    );
  }, [
    setOutcomeTraineeList,
    submissionId,
    outcomeFilters,
    outcomesSelected,
    setAlert,
    clearAlert,
  ]);

  return (
    <MuiTableContainer
      component={MuiPaper}
      sx={sendInvitationStyles.tableMaxHeight}
    >
      <MuiTable
        area-label="customized table"
        stickyHeader
        sx={sendInvitationStyles.tableHeaderBackgroundColor}
      >
        <MuiTableHead>
          <MuiTableRow>
            <MuiTableCell align="left" width="5%">
              #
            </MuiTableCell>
            <MuiTableCell align="left" width="45%">
              Name
            </MuiTableCell>
            <MuiTableCell align="left" width="30%">
              Email
            </MuiTableCell>
            <MuiTableCell align="left" width="20%">
              Status
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {loading ? (
            <MuiTableRow>
              <MuiTableCell colSpan="4" align="center">
                <MuiTypography
                  variant="h2"
                  color="textSecondary"
                  align="center"
                  paragraph={false}
                >
                  {t("globals.list.messages.fetchingInfo")}
                </MuiTypography>
              </MuiTableCell>
            </MuiTableRow>
          ) : outcomeTraineeList.length > 0 ? (
            outcomeTraineeList.map((trainee, index) => (
              <MuiTableRow key={trainee._links.self.href}>
                <MuiTableCell align="left" width="5%">
                  {index + 1}
                </MuiTableCell>
                <MuiTableCell align="left" width="45%">
                  {trainee.traineeName}
                </MuiTableCell>
                <MuiTableCell align="left" width="30%">
                  {trainee.traineeEmail || ""}
                </MuiTableCell>
                <MuiTableCell align="left" width="20%">
                  {trainee.lastInvitedDate ? "Re Send" : "Initial Send"}
                </MuiTableCell>
              </MuiTableRow>
            ))
          ) : (
            <MuiTableRow>
              <MuiTableCell
                colSpan="4"
                align="center"
                sx={sendInvitationStyles.noDataTableCell}
              >
                {t("globals.list.messages.noData")}
              </MuiTableCell>
            </MuiTableRow>
          )}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

const ProvideEmailDetails = (props) => {
  const { t } = useTranslation();

  const { values, handleChange, handleBlur, touched, errors } =
    useFormikContext();

  const { setEmailFields, submissionId, setAlert, clearAlert } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOutcomeEmailInvitationFields(
      setEmailFields,
      submissionId,
      setLoading,
      setAlert,
      clearAlert
    );
  }, [setEmailFields, submissionId, setAlert, clearAlert]);

  return (
    <div style={sendInvitationStyles.topEmailDivStyled}>
      {loading ? (
        <MuiTypography
          variant="h2"
          color="textSecondary"
          align="center"
          paragraph={false}
        >
          {t("globals.list.messages.fetchingInfo")}
        </MuiTypography>
      ) : (
        <div style={sendInvitationStyles.emailFormDivStyled}>
          <MuiTextField
            name="fromEmailAddress"
            label={`${t("sendInvitation.fromEmailAddress")}`}
            value={values.fromEmailAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            sx={{
              ...sendInvitationStyles.singleFieldStyle,
              ...sendInvitationStyles.inputStyle,
              ...sendInvitationStyles.helperTextStyle,
            }}
            autoComplete="off"
            inputProps={{ maxLength: 100 }}
            required
            error={touched.fromEmailAddress && errors.fromEmailAddress}
            helperText={
              touched.fromEmailAddress && errors.fromEmailAddress
                ? t("sendInvitation.notification.email")
                : ""
            }
          />
          <MuiTextField
            name="fromName"
            label={`${t("sendInvitation.fromName")}`}
            value={values.fromName}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            sx={{
              ...sendInvitationStyles.singleFieldStyle,
              ...sendInvitationStyles.inputStyle,
            }}
            autoComplete="off"
            inputProps={{ maxLength: 100 }}
            required
          />
          <MuiTextField
            name="replyToEmailAddress"
            label={`${t("sendInvitation.replyToEmailAddress")}`}
            value={values.replyToEmailAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            sx={{
              ...sendInvitationStyles.singleFieldStyle,
              ...sendInvitationStyles.inputStyle,
              ...sendInvitationStyles.helperTextStyle,
            }}
            autoComplete="off"
            inputProps={{ maxLength: 100 }}
            error={touched.replyToEmailAddress && errors.replyToEmailAddress}
            helperText={
              touched.replyToEmailAddress && errors.replyToEmailAddress
                ? t("sendInvitation.notification.email")
                : ""
            }
          />
          <MuiTextField
            name="subject"
            label={`${t("sendInvitation.subject")}`}
            value={values.subject}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            sx={{
              ...sendInvitationStyles.singleFieldStyle,
              ...sendInvitationStyles.inputStyle,
            }}
            autoComplete="off"
            inputProps={{ maxLength: 100 }}
            required
          />
          <MuiTextField
            name="message"
            label={`${t("sendInvitation.message")}`}
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
            multiline
            rows={18}
            autoComplete="off"
            inputProps={{ maxLength: 2000 }}
            required
            sx={sendInvitationStyles.inputStyle}
          />
        </div>
      )}
    </div>
  );
};

const PreviewAndSend = (props) => {
  const { t } = useTranslation();

  const [emailPreviewMessage, setEmailPreviewMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { emailPreviewFields, submissionId, setAlert, clearAlert } = props;

  useEffect(() => {
    previewOutcomeInvitationEmail(
      emailPreviewFields,
      submissionId,
      setEmailPreviewMessage,
      setLoading,
      setAlert,
      clearAlert
    );
  }, [emailPreviewFields, submissionId, setAlert, clearAlert]);

  return (
    <div className={sendInvitationStyles.topEmailDivStyled}>
      <div className={sendInvitationStyles.emailFormDivStyled}>
        <MuiBox
          bgcolor="background.paper"
          border={1}
          sx={sendInvitationStyles.emailFieldsDivPreviewStyle}
          borderColor="grey.400"
          borderRadius="borderRadius"
        >
          <dl className={sendInvitationStyles.emailFieldListPreviewStyle}>
            <dt
              className={sendInvitationStyles.emailFieldLabelPreviewStyle}
            >{`${t("sendInvitation.fromEmailAddress")}:`}</dt>
            <dd className={sendInvitationStyles.emailFieldValuePreviewStyle}>
              {emailPreviewFields.fromEmailAddress}
            </dd>
            <dt
              className={sendInvitationStyles.emailFieldLabelPreviewStyle}
            >{`${t("sendInvitation.fromName")}:`}</dt>
            <dd className={sendInvitationStyles.emailFieldValuePreviewStyle}>
              {emailPreviewFields.fromName}
            </dd>
            <dt
              className={sendInvitationStyles.emailFieldLabelPreviewStyle}
            >{`${t("sendInvitation.replyToEmailAddress")}:`}</dt>
            <dd className={sendInvitationStyles.emailFieldValuePreviewStyle}>
              {emailPreviewFields.replyToEmailAddress}
            </dd>
            <dt
              className={sendInvitationStyles.emailFieldLabelPreviewStyle}
            >{`${t("sendInvitation.subject")}:`}</dt>
            <dd className={sendInvitationStyles.emailFieldValuePreviewStyle}>
              {emailPreviewFields.subject}
            </dd>
          </dl>
        </MuiBox>
        <MuiBox
          component="div"
          my={2}
          overflow="auto"
          bgcolor="#f0f4f5"
          sx={sendInvitationStyles.emailMessagePreviewStyle}
          border={1}
          borderColor="grey.400"
          borderRadius="borderRadius"
        >
          {loading ? (
            <MuiTypography
              variant="h2"
              color="textSecondary"
              align="center"
              paragraph={false}
              sx={sendInvitationStyles.previewMessageLoadingStyle}
            >
              {t("globals.list.messages.fetchingInfo")}
            </MuiTypography>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: emailPreviewMessage }}
            ></div>
          )}
        </MuiBox>
      </div>
    </div>
  );
};
