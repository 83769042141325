import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid2 as MuiGrid,
  CircularProgress as MuiCircularProgress,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import {
  AddCircle as MuiAddCircleIcon,
  CloudDownload as MuiCloudDownloadIcon,
  Search as MuiSearchIcon,
} from "@mui/icons-material";
/** Custom Components */
import { ApplicantsDetails } from "./ApplicantsDetails";
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { useSetLoading } from "app/shared/ui/loadingState";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
import { sharedStyles } from "app/shared/ui/sharedStyles";
import { Spinner } from "app/shared/ui/Loading/Spinner";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  traineeTypeDropDown,
} from "app/shared/constants";
/** Services */
import {
  getApplicantList,
  getApplicantsExportData,
  getSubmissionsList,
  postSaveApplicant,
} from "app/services/applicantService";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { getSubmission } from "app/services/submissionService";
import {
  useApplicantsSelectedContext,
  SelectAllStates,
} from "app/services/applicantsSelectedContext";
import { useApplicantsFilterContext } from "app/services/applicantContext";
import { RemoveApplicants } from "./RemoveApplicants";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";

export const Applicants = (props) => {
  const navigate = useNavigate();
  const [submissionList, setSubmissionList] = useState([]);
  const [snackbar, setSnackbar] = useState({ show: false, message: "" });

  const { t } = useTranslation();
  const [refreshApplicantID, setRefreshedApplicantID] = useState(0);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmissionId = getSelectedSubmission();
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { NONE_SELECTED } = SelectAllStates;
  const [hasAddApplicantAccess, setHasAddApplicantAccess] = useState(false);
  const [applicant, setApplicants] = useState([]);
  const [summary, setSummary] = useState({});
  const [setApplicantList] = useState([]);
  const {
    applicantsFilterPreferences,
    updatePreferencesForSubmission,
    setApplicantsFilterPreferences,
  } = useApplicantsFilterContext();
  const { resetAllSelections } = useApplicantsSelectedContext();
  const { setAlert, clearAlert } = useAlerts();
  const { applicantsSelected } = useApplicantsSelectedContext();
  const [openRemoveApplicantsDialog, setOpenRemoveApplicantsDialog] =
    useState(false);
  const [applicantsRemoved, toggleApplicantsRemoved] = useState(false);
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [addOptionsloading, setAddOptionsloading] = useState(false);
  const [key, setKey] = useState(`add-${new Date().toString()}`);
  const [exportLoading, setExportLoading] = useState(false);

  let timeoutID; // ID for API debounce timeout

  useEffect(() => {
    getSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    resetAllSelections();
    if (selectedSubmissionId)
      getSubmission(
        selectedSubmissionId,
        setHasAddApplicantAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Applicant",
        getSelectedSubmissionName,
        t
      );
    // eslint-disable-next-line
  }, [getSelectedSubmission(), setHasAddApplicantAccess]);

  const handleExport = () => {
    getApplicantsExportData(
      getSelectedSubmission(),
      applicantsFilterPreferences,
      summary.totalCount,
      setAlert,
      clearAlert,
      setExportLoading
    );
  };

  /* Update Submission and Re-render the Page,
   * Also reload the Filters on the basis of Updated Submission Id
   */
  const handleChangeSubmission = () => {
    updatePreferencesForSubmission(selectedSubmissionId);
  };

  const handleApplicantAutocompleteChange = (event) => {
    const applicantVal = event.target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(() => {
      if (applicantVal.length >= AUTOCOMPLETE_MIN_INPUT) {
        setApplicants([]);

        getApplicantList(
          applicantVal,
          5,
          setApplicants,
          setAlert,
          clearAlert,
          setAddOptionsloading,
          selectedSubmissionId,
          t
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };

  const handleApplicantSelection = (e, value) => {
    setSnackbar({ show: false, message: "" });
    if (value) {
      postSaveApplicant(
        setSnackbar,
        getSelectedSubmission(),
        value.value,
        setRefreshedApplicantID,
        setAlert,
        clearAlert,
        setLoadingItems,
        setKey,
        applicantsFilterPreferences,
        setApplicantsFilterPreferences,
        t
      );
    }
  };

  let createApplicantButton = null;
  if (hasAddApplicantAccess) {
    createApplicantButton = (
      <Link to={`/applicants/${selectedSubmissionId}/new`}>
        <MuiButton variant="contained" color="primary">
          <MuiAddCircleIcon />
          &nbsp;&nbsp;{t("applicants.mainView.createApplicant")}
        </MuiButton>
      </Link>
    );
  }
  const removeAllApplicants = (e) => {
    clearAlert();
    setOpenRemoveApplicantsDialog(true);
  };

  const displayText = (option) => {
    const displaytextParts = [];

    displaytextParts.push(t(traineeTypeDropDown[option.value.traineeType]));
    if (option.value.department) {
      displaytextParts.push(option.value.department.name);
    }
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    if (option.value.applicationYear) {
      return `${displaytextParts.join(", ")} (${option.value.applicationYear})`;
    }

    return displaytextParts.join(", ");
  };

  return (
    <>
      {openRemoveApplicantsDialog && (
        <RemoveApplicants
          openRemoveApplicantsDialog={openRemoveApplicantsDialog}
          setOpenRemoveApplicantsDialog={setOpenRemoveApplicantsDialog}
          toggleApplicantsRemoved={toggleApplicantsRemoved}
          totalCount={summary.totalActiveCount}
        />
      )}
      <MuiGrid container justify="flex-start" direction="column">
        {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
        <Spinner
          size="50px"
          visible={loading}
          style={sharedStyles.pageSpinnerStyles}
        />
        <MuiGrid pb="16px">
          <MuiGrid size={3.5}>
            <SubmissionSelect
              submissionList={submissionList}
              handleChangeSubmission={handleChangeSubmission}
            />
          </MuiGrid>
        </MuiGrid>
        <MuiGrid pb="8px">
          <MuiGrid container direction="row" justify="space-between">
            <MuiGrid size={3.5}>
              <MuiAutocomplete
                key={key}
                blurOnSelect={true}
                clearOnBlur={true}
                openOnFocus={false}
                value={null}
                id="add-applicant-combo"
                disabled={!hasAddApplicantAccess || loading}
                loading={addOptionsloading}
                options={applicant.count > 0 ? applicant.values : []}
                filterOptions={(option, state) => option}
                getOptionDisabled={(option) => option.isDummy}
                disabledItemsFocusable={true}
                getOptionLabel={(option) => option.value && option.displayText}
                noOptionsText={t("globals.autocomplete.helperText")}
                defaultValue={null}
                onChange={(e, value) => handleApplicantSelection(e, value)}
                onClose={() => {
                  setApplicants([]);
                }}
                popupIcon={<MuiSearchIcon />}
                sx={sharedStyles.autocompleteRoot}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t("applicants.mainView.addApplicant")}
                    variant="outlined"
                    onChange={handleApplicantAutocompleteChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addOptionsloading ? (
                            <MuiCircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, state, { inputValue }) => (
                  <AutocompleteOption
                    option={props}
                    inputValue={inputValue}
                    state={state}
                  >
                    {displayText(state)}
                  </AutocompleteOption>
                )}
              />
            </MuiGrid>
            <MuiGrid
              gap="8px"
              size="grow"
              display="flex"
              alignItems="end"
              justifyContent="end"
            >
              <MuiButton
                variant="outlined"
                color="secondary"
                onClick={handleExport}
                title={summary.exportMessage}
                disabled={exportLoading || loading || summary.totalCount === 0}
              >
                <Spinner
                  visible={exportLoading}
                  style={sharedStyles.exportSpinnerStyles}
                />
                <MuiCloudDownloadIcon />
                &nbsp;{t("faculty.export.exportButtonText")}
              </MuiButton>

              {createApplicantButton}

              <MuiButton
                variant="contained"
                disabled={
                  (applicantsSelected.selectAllState === NONE_SELECTED &&
                    Object.keys(applicantsSelected?.individualSelections ?? [])
                      .length === 0) ||
                  (applicantsSelected.selectAllState !== NONE_SELECTED &&
                    Object.keys(applicantsSelected?.individualSelections ?? [])
                      .length === summary.totalActiveCount)
                }
                onClick={(e) => removeAllApplicants(e)}
              >
                &nbsp;&nbsp;
                {t("applicants.mainView.removeAll.label")}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>

        <MuiGrid size="grow">
          <ApplicantsDetails
            refreshApplicants={refreshApplicantID}
            navigate={navigate}
            setAlert={setAlert}
            clearAlert={clearAlert}
            applicantsRemoved={applicantsRemoved}
            setLoading={setLoadingItems}
            summary={summary}
            setSummary={setSummary}
            setApplicantList={setApplicantList}
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
