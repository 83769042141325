import { useTranslation } from "react-i18next";
import {
  Box as MuiBox,
  Link as MuiLink,
  Typography as MuiTypography,
} from "@mui/material";
import { Clear as MuiClearIcon } from "@mui/icons-material";

export const ResetFilter = ({ resetFn }) => {
  // For fetching up the multilingual text object using i18next.
  const { t } = useTranslation();

  return (
    <MuiBox
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "6px",
        alignItems: "center",
      }}
    >
      {/* Reset Filter Container, containing reset interface. */}
      <MuiClearIcon fontSize="small" />
      <MuiLink
        component="button"
        underline="none"
        color="textPrimary"
        /**
         * On click of Reset Filter
         *
         * 1. Calling up the function named resetFilter
         * to reset all of the state values and reloading
         * the data based on that.
         * 2. Definition of reset filter function will
         * vary with different components it belongs to.
         * */
        onClick={() => resetFn()}
      >
        <MuiTypography variant="body2">
          {t("submission.filterPanelItems.reset")}
        </MuiTypography>
      </MuiLink>
    </MuiBox>
  );
};
