import {
  Paper as MuiPaper,
  Table as MuiTable,
  Tooltip as MuiTooltip,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  Typography as MuiTypography,
  TableContainer as MuiTableContainer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Theme } from "common";

// Source Submission Table for Copy Submission Dialog
export const SourceSubmissionTable = ({ sourceSubValue }) => {
  const { t } = useTranslation();

  return (
    <MuiTableContainer component={MuiPaper}>
      <MuiTable aria-label="component-table">
        <MuiTableHead sx={{ background: Theme.palette.primary.light }}>
          <MuiTableRow>
            <MuiTableCell width="140px">
              <MuiTypography variant="subtitle1" noWrap>
                {t("submission.copy.tableColumns.monthYear")}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width="140px">
              <MuiTypography variant="subtitle1" noWrap>
                {t("submission.copy.tableColumns.spo")}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell width="160px">
              <MuiTypography variant="subtitle1" noWrap>
                {t("submission.copy.tableColumns.award")}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography variant="subtitle1" noWrap>
                {t("submission.copy.tableColumns.title")}
              </MuiTypography>
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          <MuiTableRow>
            <MuiTableCell>
              <MuiTypography variant="body2" noWrap>
                {sourceSubValue
                  ? sourceSubValue.monthYear
                  : t("globals.notAvailable")}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTypography variant="body2" noWrap>
                {sourceSubValue?.spo || t("globals.notAvailable")}
              </MuiTypography>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTooltip
                title={sourceSubValue?.award || t("globals.notAvailable")}
              >
                <MuiTypography variant="body2" noWrap>
                  {sourceSubValue?.award || t("globals.notAvailable")}
                </MuiTypography>
              </MuiTooltip>
            </MuiTableCell>
            <MuiTableCell>
              <MuiTooltip
                title={sourceSubValue?.title || t("globals.notAvailable")}
              >
                <MuiTypography
                  variant="body2"
                  noWrap
                  sx={{ maxWidth: "540px" }}
                >
                  {sourceSubValue?.title || t("globals.notAvailable")}
                </MuiTypography>
              </MuiTooltip>
            </MuiTableCell>
          </MuiTableRow>
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};
