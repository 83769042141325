import {
  Backdrop as MuiBackdrop,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";

export const Spinner = ({ visible, style, size }) => (
  <MuiBackdrop open={visible} sx={{ zIndex: 20, ...style }}>
    <MuiCircularProgress size={size} />
  </MuiBackdrop>
);
