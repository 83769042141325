import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { Check as MuiCheckIcon } from "@mui/icons-material";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useBrowserStorage } from "app/services/StorageService/StorageHelper";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
import { STORAGE_TYPE, STORAGE_NAMES } from "app/shared/constants";

export const SubmissionSelect = ({
  submissionList,
  handleChangeSubmission,
}) => {
  const { handleSubmissionUpdate } = useSelectedSubmission();
  const [getSelectedSubmission, setSelectedSubmission] =
    useSelectedSubmission().value;
  const [, setSelectedSubmissionYear] = useSelectedSubmission().year;
  const [, setSelectedSubmissionName] = useSelectedSubmission().name;
  const [, setSelectedSubmissionMonth] = useSelectedSubmission().month;
  const [selectedValue, setSelectedValue] = useState("");
  const [submissionOptions, setSubmissionOptions] = useState([]);

  const { t } = useTranslation();
  const { removeItem } = useBrowserStorage(STORAGE_TYPE.SESSION);

  const handleSubmissionIdChange = () => {
    for (let key in STORAGE_NAMES) {
      if (key !== STORAGE_NAMES.TGDS_MANAGE_SUBMISSION_FILTERS) {
        removeItem(STORAGE_NAMES[key]);
      }
    }
  };

  const handleSubmissionValueChange = (newValue) => {
    setSelectedValue(newValue);
    const { name, year, month } = newValue;
    const id = newValue._links.self.href.split("/").pop();
    // updating local storage with the selected submission
    setSelectedSubmission(id ?? "");
    setSelectedSubmissionName(name ?? "");
    setSelectedSubmissionYear(year ?? "");
    setSelectedSubmissionMonth(month ?? "");
    handleSubmissionUpdate();
    handleSubmissionIdChange();
    if (handleChangeSubmission) {
      handleChangeSubmission();
    }
  };

  useEffect(() => {
    if (submissionList && submissionList.length) {
      const extendedOptionList = submissionList.map((item, index) => {
        const submissionMonth =
          item.month.toString().length < 2 ? `0${item.month}` : item.month;
        return {
          ...item,
          id: `${item.name}-${index}`,
          displayText: `${item.name}, ${submissionMonth}/${item.year}`,
          value: {},
        };
      });

      setSubmissionOptions(extendedOptionList);
    }
  }, [submissionList]);

  useEffect(() => {
    if (submissionOptions.length > 0) {
      // checking if the submission in the local storage is in the list of options
      const existingSubmission = submissionOptions.find(
        (option) =>
          option?._links?.self?.href.split("/").pop() ===
          getSelectedSubmission()
      );
      // if it is, set the selected value to the existing submission else clear the local storage
      if (existingSubmission) {
        setSelectedValue(existingSubmission);
      } else {
        setSelectedSubmission("");
        setSelectedSubmissionName("");
        setSelectedSubmissionYear("");
        setSelectedSubmissionMonth("");
      }
    }
  }, [
    getSelectedSubmission,
    setSelectedSubmission,
    setSelectedSubmissionMonth,
    setSelectedSubmissionName,
    setSelectedSubmissionYear,
    submissionOptions,
  ]);

  return (
    <MuiAutocomplete
      id="submission-selection"
      disableClearable
      autoHighlight
      blurOnSelect
      options={submissionOptions}
      getOptionLabel={(option) => option?.displayText || ""}
      value={selectedValue}
      onChange={(event, newValue) => {
        handleSubmissionValueChange(newValue);
      }}
      renderOption={(props, state, { inputValue, selected }) => (
        <AutocompleteOption
          key={state.id}
          option={props}
          inputValue={inputValue}
          state={state}
        >
          {selected && (
            <MuiCheckIcon
              style={{
                fill: "#009ABB",
                marginLeft: "auto",
                marginRight: "0",
              }}
            />
          )}
        </AutocompleteOption>
      )}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t("faculty.mainView.submission")}
          variant="outlined"
          sx={{
            fontWeight: 500,
            lineHeight: "22.5px",
            width: "100%",
          }}
        />
      )}
      sx={{
        "& .MuiAutocomplete-popupIndicatorOpen": {
          // Stop MUI from rotating the icon by rotating it back
          rotate: "180deg",
        },
        "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
          // Move dropdown arrow and clear X up to horizontal center
          height: "auto",
        },
        "& .MuiInputLabel-root, .MuiInputBase-input": {
          fontSize: "14.5px",
        },
      }}
    />
  );
};
