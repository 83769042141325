import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid2 as MuiGrid, Button as MuiButton } from "@mui/material";
import { CloudDownload as MuiCloudDownloadIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
/** Custom Components */
import { SubmissionSelect } from "app/shared/ui/SubmissionSelect/SubmissionSelect";
import { useSetLoading } from "app/shared/ui/loadingState";
import { GrantDetails } from "./GrantDetails";
import { Spinner } from "app/shared/ui/Loading/Spinner";
/** Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useGrantsContext } from "app/services/grantsContext";
import {
  getSubmissionsList,
  getGrantsExportData,
} from "app/services/grantsService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
/** Styles */
import { sharedStyles } from "app/shared/ui/sharedStyles";

export const Grants = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submissionList, setSubmissionList] = useState([]);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmissionId = getSelectedSubmission();
  const [summary, setSummary] = useState({});
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [exportLoading, setExportLoading] = useState(false);
  const { grantsPreference, updatePreferencesForSubmission } =
    useGrantsContext();

  const { setAlert, clearAlert } = useAlerts();

  useEffect(() => {
    getSubmissionsList(
      setSubmissionList,
      setLoadingSubmission,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, []);

  const handleChangeSubmission = () => {
    updatePreferencesForSubmission(selectedSubmissionId);
  };

  // *** Export CSV file of Table Data ***
  const handleExport = () => {
    getGrantsExportData(
      getSelectedSubmission(),
      grantsPreference,
      summary.totalCount,
      clearAlert,
      setAlert,
      setExportLoading
    );
  };

  return (
    <MuiGrid container direction="column">
      <Spinner
        size="50px"
        visible={loading}
        style={sharedStyles.pageSpinnerStyles}
      />
      <MuiGrid size={3.5}>
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid container justifyContent="flex-end" pb="16px">
        <MuiButton
          variant="outlined"
          color="secondary"
          onClick={handleExport}
          title={summary.exportMessage}
          disabled={exportLoading || loading || summary.totalCount === 0}
        >
          <Spinner
            visible={exportLoading}
            style={sharedStyles.exportSpinnerStyles}
          />
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}
        </MuiButton>
      </MuiGrid>
      <MuiGrid>
        <GrantDetails
          filterData={props.filterData}
          navigate={navigate}
          setAlert={setAlert}
          clearAlert={clearAlert}
          setLoading={setLoadingItems}
          summary={summary}
          setSummary={setSummary}
        />
      </MuiGrid>
    </MuiGrid>
  );
};
