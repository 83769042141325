import { useState } from "react";
import { Snackbar as MuiSnackbar } from "@mui/material";

const PositionedSnackbar = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const handleClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <MuiSnackbar
        style={{ position: "absolute" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={handleClose}
        message={props.message}
        autoHideDuration={props.autoHideDuration}
      />
    </div>
  );
};
export default PositionedSnackbar;
