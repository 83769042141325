import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from "@mui/material";
import {
  ChevronLeft as MuiChevronLeftIcon,
  ChevronRight as MuiChevronRightIcon,
} from "@mui/icons-material";
import { Theme } from "common";

export const FilterPanel = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  // Function to handle the drawer toggle
  function handleDrawerToggle() {
    setOpen(!open);
  }

  return (
    <MuiBox
      id="filterPanel"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: open ? "16px 24px 0px 0px" : "16px 0px",
        borderRight: "1px solid",
        borderRightColor: Theme.palette.grey[400],
      }}
    >
      <MuiIconButton
        id="openDrawerButton"
        onClick={handleDrawerToggle}
        edge="start"
        sx={{
          display: open ? "none" : "block",
        }}
      >
        <MuiChevronRightIcon />
      </MuiIconButton>
      <MuiDrawer
        id="filterPanelDrawer"
        transitionDuration={0}
        variant="persistent"
        open={open}
        anchor="left"
        sx={{
          width: open ? "280px" : 0,
          "& .MuiDrawer-paper": {
            width: "280px",
            zIndex: 0,
            position: "relative",
            border: "none",
          },
          "& .MuiTypography-root": { color: Theme.palette.text.primary },
          "& .MuiGrid-root": { justifyContent: "space-around" },
        }}
      >
        <MuiBox
          id="filterPanelContent"
          sx={{
            display: "grid",
            rowGap: "16px",
            width: "100%",
          }}
        >
          <MuiBox
            id="toolbar"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <MuiTypography variant="h6">
              {t("submission.filterPanelItems.title")}
            </MuiTypography>
            <MuiIconButton onClick={handleDrawerToggle}>
              <MuiChevronLeftIcon />
            </MuiIconButton>
          </MuiBox>
          {children}
        </MuiBox>
      </MuiDrawer>
    </MuiBox>
  );
};
