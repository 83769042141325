import { TableCell as MuiTableCell } from "@mui/material";
import styled from "@emotion/styled";

export const tableStyes = {
  container: {
    "&.MuiTableContainerRoot": {
      width: "100%",
      margin: 0,
      overflow: "visible",
    },
    "& .MuiTableCell-root": {
      padding: "6px 20px",
    },
    "& .MuiTableCell-head": {
      backgroundColor: "#F0F4F5",
      color: "#333333",
      fontWeight: 500,
      verticalAlign: "top",
      "& button": {
        color: "#007C92",
      },
    },
    "& *": {
      fontSize: 14,
      textDecoration: "none",
    },
  },
};

export const StyledTableCell = styled(MuiTableCell)`
  .MuiTableCell-head  {
    backgroundColor: "#F0F4F5",
    color: "#333333",
    fontWeight: 500,
    verticalAlign: "top",
    color: "#333333",
    "& button": {
      color: "#007C92",
    },
  }
`;
