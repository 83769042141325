import { useState } from "react";
import { useTranslation } from "react-i18next";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  TextField as MuiTextField,
  Typography as MuiTypography,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { usePeopleAPI } from "app/services/usePeopleAPI";

export const SubmissionAutocomplete = ({ formik, fieldName }) => {
  const { loadPeopleOptions, loadPersonDetails } = usePeopleAPI();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState("");
  const [adminOptions, setAdminOptions] = useState([]);
  const [observerOptions, setObserverOptions] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  const options = {
    admins: adminOptions,
    observers: observerOptions,
  };

  const setOptions = {
    admins: setAdminOptions,
    observers: setObserverOptions,
  };

  const handleChangeInput = (event) => {
    const searchQuery = event.target.value.trim();

    loadPeopleOptions(searchQuery, setOptions[fieldName], setNoOptionsText);
  };

  const handleChangeSelection = (value) => {
    if (value.length > formik.values[fieldName].length) {
      // Load and add a new person to the Autocomplete
      loadPersonDetails(value[value.length - 1].profileId, formik, fieldName);
    } else {
      formik.setFieldValue(fieldName, value);
    }

    setOptions[fieldName]([]);
    setNoOptionsText(t("globals.autocomplete.helperText"));
  };

  if (!["admins", "observers"].includes(fieldName)) {
    return null;
  }

  return (
    <MuiAutocomplete
      name={fieldName}
      multiple
      value={formik.values[fieldName]}
      inputValue={inputValue}
      options={[...options[fieldName], ...formik.values[fieldName]]}
      filterSelectedOptions={true}
      filterOptions={(option, state) => option}
      getOptionLabel={(option) =>
        `${option.name}${option.uid ? ` (${option.uid})` : ""}`
      }
      isOptionEqualToValue={(option, value) =>
        parseInt(option.profileId) === parseInt(value.profileId)
      }
      noOptionsText={noOptionsText}
      onChange={(event, value) => handleChangeSelection(value)}
      onInputChange={(event, value) => setInputValue(value)}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t(`submission.create.form.fields.${fieldName}.label`)}
          onChange={(event) => handleChangeInput(event)}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(props, state, { inputValue }) => (
        <AutocompleteHighlight
          option={props}
          inputValue={inputValue}
          state={state}
        />
      )}
    />
  );
};

const AutocompleteHighlight = ({ option, inputValue, state }) => {
  const matches = match(state.name, inputValue);
  const parts = parse(state.name, matches);

  const { key, ...optionProps } = option;
  return (
    <li
      key={key}
      {...optionProps}
      style={{
        padding: "4px 16px",
        lineHeight: "18px",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {parts.map((part, index) => {
            return (
              <MuiTypography
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                }}
                variant="body2"
                component="span"
              >
                {part.text}
              </MuiTypography>
            );
          })}
        </div>
        <div>
          <MuiTypography
            style={{
              fontWeight: 400,
              whiteSpace: "pre",
            }}
            variant="caption"
            component="span"
          >
            {state.title}
          </MuiTypography>
        </div>
      </div>
    </li>
  );
};
