import { Theme } from "common";

export const createOutcomeStyles = {
  topCreateOutcomeDivStyled: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginRight: "24px",
  },
  titleStyle: {
    "& a": {
      color: "gray",
      cursor: "pointer",
    },
    paddingTop: "15px",
    paddingBottom: "20px",
  },
  titleNameStyle: {
    paddingBottom: "20px",
  },
  summaryStyle: {
    width: "96%",
    "& .MuiFormControl-root": {
      marginTop: "20px",
    },
  },
  grayLink: {
    color: "gray",
    "&:visited": {
      color: "gray",
    },
  },
  marginZero: {
    margin: 0,
  },
  paddLeft: {
    paddingLeft: "4px",
  },
  singleFieldStyle: {
    width: "46.5%",
  },
  summaryFieldStyle: {
    width: "96%",
  },

  outcomeFormDivStyled: {
    display: "grid",
    rowGap: "20px",
    justifyContent: "space-between",
    gridTemplateColumns: "1fr",
  },
  actionFlexStyled: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "92%",
    alignContents: "flex-end",
    "& .MuiButton-containedPrimary": {
      marginLeft: "27px",
    },
  },
  yearStyled: {
    width: "100%",
  },
  sixtyStyled: {
    width: "60%",
    display: "inline-block",
    padding: "0 10px",
  },
  fortyStyled: {
    width: "40%",
    display: "inline-block",
    padding: "0 10px",
  },
  thStyle: {
    backgroundColor: Theme.palette.primary.light,
  },
  cellPadding: {
    padding: "5px 0px 0px 0px",
  },
  cellEditPadding: {
    padding: "10px 0px 5px 5px",
  },

  cellContainerPadding: {
    padding: "16px 0px 19px 15px",
  },

  disabled: {
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: Theme.palette.text.primary,
    },
  },
};
