import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Grid2 as MuiGrid, Button as MuiButton } from "@mui/material";
import {
  AddCircle as MuiAddCircleIcon,
  ContentCopy as MuiContentCopyIcon,
} from "@mui/icons-material";
/** Custom Components */
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
import {
  SUB_OPERATION_STATUS,
  SUBMISSION_STATUS_SYNC_INTERVAL_MS,
} from "app/shared/constants";
import { CopySubmissionDialog } from "../CopySubmission/CopySubmissionDialog";
import { ManageSubmissionDetails } from "./ManageSubmissionDetails";
/** Services */
import { useUsers, useAlerts } from "common";
import {
  getAllSubmissions,
  getManageSubmissionsList,
} from "app/services/submissionService";
import { useManageSubmissionsContext } from "app/services/manageSubmissionsContext";

// ************************* Manage Submission *************************
export const ManageSubmission = () => {
  const { t } = useTranslation();
  const { setAlert, clearAlert } = useAlerts();
  const { currentUser } = useUsers();
  const { submissionPreference } = useManageSubmissionsContext();

  // State variables
  const [snackbar, setSnackBar] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  // State variable for submissions list loading
  const [loadingSubmissionsList, setLoadingSubmissionsList] = useState(true);
  // State variable for submission list for copy submission dialog
  const [submissionsList, setSubmissionsList] = useState([]);
  // State variable for copy submission dialog
  const [openCopySubmissionDialog, setOpenCopySubmissionDialog] =
    useState(false);
  // Function to close the copy submission dialog and reset the submission list
  const closeCopySubmissionDialog = () => {
    setSubmissionsList([]);
    setOpenCopySubmissionDialog(false);
  };

  // loadingState flag is used to determine if the loading spinner should be shown or not when fetching submissions
  const loadingState = false;

  // Function to fetch submissions after copying a submission
  const fetchSubmissionsAfterCopy = () => {
    getManageSubmissionsList(
      setTableData,
      setTotalCount,
      setLoading,
      setAlert,
      clearAlert,
      submissionPreference,
      t,
      loadingState
    );
  };

  // Fetch submissions on initial load
  useEffect(() => {
    getManageSubmissionsList(
      setTableData,
      setTotalCount,
      setLoading,
      setAlert,
      clearAlert,
      submissionPreference,
      t
    );
  }, [clearAlert, setAlert, submissionPreference, t]);

  // Sync submissions if any submission is not ready
  useEffect(() => {
    let timeoutId;

    const allSubsReady = tableData.every(
      (sub) => sub.operationStatus === SUB_OPERATION_STATUS.READY
    );

    if (!allSubsReady) {
      timeoutId = setTimeout(() => {
        getManageSubmissionsList(
          setTableData,
          setTotalCount,
          setLoading,
          setAlert,
          clearAlert,
          submissionPreference,
          t,
          loadingState
        );
      }, SUBMISSION_STATUS_SYNC_INTERVAL_MS);
    }

    return () => clearTimeout(timeoutId);
  }, [clearAlert, loadingState, setAlert, submissionPreference, t, tableData]);

  return (
    <MuiGrid container justify="flex-start" direction="column" spacing={1}>
      {snackbar && (
        <ShowSnackBar
          message={t("globals.list.actionIcons.delete.successMessage")}
        />
      )}

      <MuiGrid container direction="column" spacing={1}>
        <MuiGrid container direction="row" justifyContent="flex-end" gap="16px">
          {currentUser?.permissions?.CREATE_SUBMISSION && (
            <MuiGrid>
              <MuiButton
                variant="contained"
                color="primary"
                onClick={() => {
                  // Open the copy submission dialog and get all submissions
                  setOpenCopySubmissionDialog(true);
                  getAllSubmissions(
                    setAlert,
                    clearAlert,
                    setSubmissionsList,
                    setLoadingSubmissionsList
                  );
                }}
              >
                <MuiContentCopyIcon fontSize="small" />
                &nbsp; {t("submission.copy.copyButtonText")}
              </MuiButton>
            </MuiGrid>
          )}
          <CopySubmissionDialog
            open={openCopySubmissionDialog}
            onClose={closeCopySubmissionDialog}
            loading={loadingSubmissionsList}
            submissionsList={submissionsList}
            fetchSubmissionsAfterCopy={fetchSubmissionsAfterCopy}
          />
          <MuiGrid>
            {currentUser?.permissions?.CREATE_SUBMISSION && (
              <Link to="/admin/submissions/new">
                <MuiButton variant="contained" color="primary">
                  <MuiAddCircleIcon />
                  &nbsp;{t("submission.manage.ctaTextForCreateSubmission")}
                </MuiButton>
              </Link>
            )}
          </MuiGrid>
        </MuiGrid>
        <MuiGrid>
          <ManageSubmissionDetails
            loading={loading}
            setLoading={setLoading}
            setSnackBar={setSnackBar}
            tableData={tableData}
            totalCount={totalCount}
          />
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
