import { formatInTimeZone } from "date-fns-tz";
import { format as dfnsFormat } from "date-fns";
import enUS from "date-fns/locale/en-US";
import {
  OutcomePreDocTrainingSupportTypeEnums,
  OutcomePostDocTrainingSupportTypeEnums,
  TIME_ZONE,
} from "app/shared/constants";

export const getTrainingTypeNameByTrainingTypeCode = (
  trainingTypeCode,
  nameVariation,
  t
) => {
  switch (true) {
    case Object.keys(OutcomePreDocTrainingSupportTypeEnums).includes(
      trainingTypeCode
    ):
      return t(
        `${OutcomePreDocTrainingSupportTypeEnums[trainingTypeCode]}.${nameVariation}`
      );
    case Object.keys(OutcomePostDocTrainingSupportTypeEnums).includes(
      trainingTypeCode
    ):
      return t(
        `${OutcomePostDocTrainingSupportTypeEnums[trainingTypeCode]}.${nameVariation}`
      );
    default:
      return "";
  }
};

export const normalizeText = (str, defaultValue) => {
  return (str && str.trim && str.trim()) || defaultValue;
};

export const buildYearRange = (yearsInPast, yearsInFuture) => {
  const emptyArray = new Array(yearsInPast + yearsInFuture + 1);
  const earliestYear = new Date().getFullYear() - yearsInPast;

  return Array.from(emptyArray, (_, index) => earliestYear + index);
};

/**
 * Helper to format dates and times based on PST & locale enUS.
 *
 * Utilizes date-fns' `formatInTimeZone` or `format` internally.
 *
 * @param {Date|string} time A date object or full timestamp
 * @param {string} format A date-fns compatible format string
 * @param {object} options A list of options for the formatter
 * @param {boolean} [options.isUtc] Is the given date in UTC time? [Default: true]
 *
 * @returns The formatted date string or "N/A" if an invalid `time` was passed.
 */
export const formatDate = (time, format, options = { isUtc: true }) => {
  // Disabling rule as === tries to match an object to the string
  // eslint-disable-next-line eqeqeq
  if (!time || new Date(time) == "Invalid Date") return "N/A";
  if (options.isUtc) {
    return formatInTimeZone(time, TIME_ZONE.PST, format, { locale: enUS });
  }
  return dfnsFormat(new Date(time), format);
};
