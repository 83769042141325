import { Theme } from "common";

export const createApplicantStyles = {
  topCreateApplicantDivStyled: {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px",

    "& .MuiFormControl-root": {
      fontColor: Theme.palette.grey[500],
    },
    "& .MuiTypography-body1": {
      color: Theme.palette.grey[500],
    },
    "& .MuiTypography-colorTextPrimary": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    "&&": {
      "& .MuiTypography-h5": {
        paddingBottom: "20px",
        color: Theme.palette.text.primary,
      },
    },
    '& button[aria-label="change date"]': {
      padding: "0",
    },
  },
  titleStyle: {
    paddingTop: "15px",
    paddingBottom: "20px",
    "& .MuiFormControlLabel-label": {
      marginTop: "5px",
    },
    "& .MuiCheckbox-root": {
      paddingTop: "0px",
    },
    "& a": {
      color: "gray",
      cursor: "pointer",
    },
  },
  checkBoxGroupedDivStyled: {
    display: "grid",
    rowGap: "8px",
    justifyContent: "space-between",
  },
  checkBoxTitleDivStyled: {
    "& a": {
      color: "gray",
      cursor: "pointer",
    },
    "& .MuiFormControlLabel-root": {
      verticalAlign: "top",
      display: "inline",
    },
    "& .MuiCheckbox-root": {
      paddingTop: "0px",
    },
    "& .MuiTypography-body1": {
      marginTop: "5px",
      color: Theme.palette.text.primary,
    },
  },
  applicantFormDivStyled: {
    display: "grid",
    rowGap: "20px",
    justifyContent: "space-between",
    gridTemplateColumns: "1fr",
    width: "500px",

    "MuiAutocomplete-inputRoot": {
      width: "500px",
    },
    "MuiTypography-root": {
      height: "40px",
    },
    "MuiFormControl-marginNormal": {
      marginTop: "0px",
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: "0",
    },
    "MuiPickersToolbar-toolbar": {
      backgroundColor: Theme.palette.primary.main,
    },
    "MuiPickersDay-daySelected": {
      backgroundColor: Theme.palette.primary.main,
    },
  },
  actionFlexStyled: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "95%",
    alignContents: "flex-end",

    "& .MuiButton-containedPrimary": {
      borderRadius: "5px",
      marginLeft: "27px",
      background: Theme.palette.primary.main,
      color: Theme.palette.common.white,
      textTransform: "capitalize",
      "&:hover": {
        borderRadius: "5px",
        background: Theme.palette.primary.dark,
        fontSize: "16px",
        color: Theme.palette.common.white,
      },
    },
    "& .Mui-disabled": {
      background: Theme.palette.grey[300],
      color: "gray",
    },
    "& .MuiButton-outlinedPrimary": {
      borderRadius: "5px",
      color: Theme.palette.primary.main,
      background: "None",
      border: `1px solid ${Theme.palette.primary.main}`,
    },
  },
  yearStyled: {
    width: "100%",
  },
};
