import { useState, createContext, useContext } from "react";
import { useBrowserStorage } from "common";
import { STORAGE_NAMES, STORAGE_TYPE } from "app/shared/constants";

export const ManageSubmissionsContext = createContext();

// **** Initialize Manage Submission Values ****
const initialSubmissionState = ({ filterValues }) => {
  const { filters, pagination, sort } = filterValues?.manageSubmissions ?? {};

  return {
    manageSubmissions: {
      sort: {
        orderBy: sort?.orderBy || "",
        order: sort?.order || "",
        ...sort,
      },
      pagination: {
        page: pagination?.page || "",
        pageSize: pagination?.pageSize || "",
        ...pagination,
      },
      delete: 0,
      filters: {
        name: filters?.name || {},
        year: filters?.year || "",
        submissionAdmin: filters?.submissionAdmin || "",
        observer: filters?.observer || "",
      },
    },
  };
};

export const ManageSubmissionsProvider = ({ children }) => {
  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  const filterValues = getItem(
    STORAGE_NAMES.TGDS_MANAGE_SUBMISSION_FILTERS,
    Object
  );

  const [submissionPreference, setSubmissionPreferenceInternal] = useState(
    initialSubmissionState({
      filterValues,
    })
  );

  const setSubmissionPreference = (preferences) => {
    preferences = preferences || {};

    setSubmissionPreferenceInternal(preferences);
    saveItem(STORAGE_NAMES.TGDS_MANAGE_SUBMISSION_FILTERS, preferences);
  };

  return (
    <ManageSubmissionsContext.Provider
      value={{ submissionPreference, setSubmissionPreference }}
    >
      {children}
    </ManageSubmissionsContext.Provider>
  );
};

export const useManageSubmissionsContext = () => {
  return useContext(ManageSubmissionsContext);
};
