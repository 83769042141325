import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink, useNavigate, useParams } from "react-router-dom";
import {
  Grid2 as MuiGrid,
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  TextField as MuiTextField,
} from "@mui/material";
import { NavigateNext as MuiNavigateNextIcon } from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
/** Custom Components */
import { FormButton } from "app/shared/ui/Button/FormButton";
/** Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { getGrantsById, updateGrantAction } from "app/services/grantsService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";
import { sharedStyles } from "app/shared/ui/sharedStyles";
/** Styles */

export const EditGrants = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { grantId } = params;
  const [grantDetailsFetched, setGrantDetailsFetched] = useState(false);
  const [getSelectedSubmission] = useSelectedSubmission().value;

  const [grant, setGrant] = useState([]);
  const { setAlert, clearAlert } = useAlerts();
  const navigate = useNavigate();

  useEffect(() => {
    getGrantsById(
      getSelectedSubmission(),
      grantId,
      setGrant,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [getSelectedSubmission, grantId]);
  /**
   * condition when attribute (principalInvestigator)
   * is not coming in api response
   */
  if (
    grant._links &&
    grant._links.editGrantDetails &&
    !grant.principalInvestigator
  ) {
    grant.principalInvestigator = { name: "" };
  }
  const [grantData, setGrantData] = useState(null);

  const [formDataResponse, setFormDataResponse] = useState({
    startDate: new Date(),
    endDate: "",
    principalInvestigator: {},
    fundingSource: {},
    currentYearCost: "",
    grantRoles: [],
    isInstitutional: false,
    namesOfOverlappingFaculty: [],
  });

  const [saveGrantData, setSaveGrantData] = useState([]);
  const titleLabel = `${grant.title}`;
  useEffect(() => {
    updateGrantAction(
      getSelectedSubmission(),
      grantId,
      grantData,
      setSaveGrantData,
      navigate,
      t,
      titleLabel,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [getSelectedSubmission, grantId, grantData, t, props]);

  useEffect(() => {
    if (grant && grant._links) {
      setGrantDetailsFetched(true);
      setFormDataResponse(grant);
    }
  }, [grant, props, saveGrantData.data, t]);
  const GrantValidation = Yup.object().shape({
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
  });

  /** 
  redirect on cancel button click
  */
  const onCancelHandler = () => {
    navigate("/grants");
  };

  const grantListLabel = "grant.edit.grantList";
  const grantActionLabel = "globals.form.actionButtons";
  return grant && grantDetailsFetched
    ? grant._links && grant._links.editGrantDetails && (
        <MuiGrid
          container
          wrap="nowrap"
          justify="flex-start"
          direction="column"
          spacing={2}
        >
          <MuiGrid>
            <MuiBreadcrumbs
              separator={<MuiNavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={sharedStyles.breadcrumb}
            >
              <RouteLink to="/grants">{t("grant.title")}</RouteLink>
              <MuiTypography color="textPrimary">
                {t("grant.edit.title")}
              </MuiTypography>
            </MuiBreadcrumbs>
          </MuiGrid>

          <MuiGrid size={{ xs: 6 }}>
            <MuiTypography variant="h5">
              {grant.title ? `${grant.title}` : ""}
            </MuiTypography>
          </MuiGrid>
          <MuiGrid>
            <Formik
              initialValues={formDataResponse}
              enableReinitialize={true}
              onSubmit={(values, { setSubmitting, setFieldError }) => {
                if (
                  values.fundingSource &&
                  Object.keys(values.fundingSource).length === 0 &&
                  values.fundingSource.constructor === Object
                ) {
                  delete values.fundingSource;
                }
                values.predocPositionsCount = values.predocPositionsCount
                  ? values.predocPositionsCount
                  : 0;

                values.numberOfOverlappingFaculty =
                  values.numberOfOverlappingFaculty
                    ? values.numberOfOverlappingFaculty
                    : 0;

                values.numberOfParticipatingFaculty =
                  values.numberOfParticipatingFaculty
                    ? values.numberOfParticipatingFaculty
                    : 0;

                values.numberOfShortTermPositions =
                  values.numberOfShortTermPositions
                    ? values.numberOfShortTermPositions
                    : 0;

                values.postdocPositionsCount = values.postdocPositionsCount
                  ? values.postdocPositionsCount
                  : 0;

                setGrantData(values);
              }}
              validationSchema={GrantValidation}
            >
              {(props) => {
                const {
                  values,
                  isSubmitting,
                  handleBlur,
                  handleSubmit,
                  isValid,
                  dirty,
                } = props;
                return (
                  <form onSubmit={handleSubmit} style={{ display: "flex" }}>
                    <MuiGrid
                      container
                      direction="column"
                      justify="space-between"
                      spacing={2}
                      sx={{ flex: "0.2 0 auto" }}
                    >
                      <MuiGrid size={{ xs: 8 }}>
                        <MuiTextField
                          id="preDocsPositions"
                          name="predocPositionsCount"
                          label={t(`${grantListLabel}.preDocsPositions`)}
                          value={
                            isNaN(values.predocPositionsCount)
                              ? ""
                              : values.predocPositionsCount
                          }
                          maxValue={999}
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value < 1000) {
                              props.setFieldValue(
                                "predocPositionsCount",
                                parseInt(e.target.value)
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                        />
                      </MuiGrid>
                      <MuiGrid size={{ xs: 8 }}>
                        <MuiTextField
                          id="postDocsPositions"
                          name="postdocPositionsCount"
                          label={t(`${grantListLabel}.postDocsPositions`)}
                          value={
                            isNaN(values.postdocPositionsCount)
                              ? ""
                              : values.postdocPositionsCount
                          }
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value < 1000) {
                              props.setFieldValue(
                                "postdocPositionsCount",
                                parseInt(e.target.value)
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                        />
                      </MuiGrid>
                      <MuiGrid size={{ xs: 8 }}>
                        <MuiTextField
                          id="numberOfShortTermPositions"
                          name="numberOfShortTermPositions"
                          label={t(`${grantListLabel}.shortTermPositions`)}
                          value={
                            isNaN(values.numberOfShortTermPositions)
                              ? ""
                              : values.numberOfShortTermPositions
                          }
                          onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value < 1000) {
                              props.setFieldValue(
                                "numberOfShortTermPositions",
                                parseInt(e.target.value)
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                        />
                      </MuiGrid>

                      <MuiGrid size={{ xs: 8 }}>
                        <MuiGrid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          spacing={2}
                        >
                          <MuiGrid>
                            <FormButton
                              onClick={onCancelHandler}
                              name={t(`${grantActionLabel}.cancel`)}
                              variant="outlined"
                            />
                          </MuiGrid>
                          <MuiGrid>
                            <FormButton
                              name={t(`${grantActionLabel}.save`)}
                              variant="contained"
                              type="submit"
                              disabled={isSubmitting || !dirty || !isValid}
                            />
                          </MuiGrid>
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  </form>
                );
              }}
            </Formik>
          </MuiGrid>
        </MuiGrid>
      )
    : null;
};
