import { isValidElement } from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { Typography as MuiTypography } from "@mui/material";

export const moreItemsOption = (nResults, t) => ({
  value: {},
  displayText: t("globals.autocomplete.moreItemsText", {
    nResults: nResults,
  }),
  isDummy: true,
});

export const searchTooShortOption = (nMinChars, t) => ({
  value: {},
  displayText: t("globals.autocomplete.searchTooShortText", {
    nMinChars: nMinChars,
  }),
  isDummy: true,
});

export const AutocompleteOption = ({ option, inputValue, state, children }) => {
  const parts = getMatchedParts(state, inputValue);
  const secondaryParts =
    children && isValidElement(children)
      ? children
      : getMatchedChildrenParts(children, inputValue);

  const { key, ...optionProps } = option;

  return (
    <li
      key={key}
      {...optionProps}
      style={{
        padding: "4px 16px",
        lineHeight: "18px",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>
            {parts.map((part, index) => {
              return (
                <MuiTypography
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                  variant="body2"
                  component="span"
                >
                  {part.text}
                </MuiTypography>
              );
            })}
          </span>
          {isValidElement(secondaryParts) && <span>{secondaryParts}</span>}
        </div>
        <div>
          {!isValidElement(secondaryParts) &&
            secondaryParts?.map((part, index) => {
              return (
                <MuiTypography
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    whiteSpace: "pre",
                  }}
                  variant="caption"
                  component="span"
                >
                  {part.text}
                </MuiTypography>
              );
            })}
        </div>
      </div>
    </li>
  );
};

const getMatchedChildrenParts = (children, inputValue) => {
  let childrenMatches;

  if (children) {
    childrenMatches = match(children, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });

    return parse(children, childrenMatches);
  }
};

const getMatchedParts = (state, inputValue) => {
  let matches;

  if (!!state.isDummy) {
    return [
      {
        highlight: false,
        text: state.displayText,
      },
    ];
  }

  if (!state.value.prefferedFullName) {
    matches = match(state.displayText, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });
    return parse(state.displayText, matches);
  }

  // [TGDS-3306] added highlighting on preferred name
  if (state.value.prefferedFullName) {
    matches = match(state.value.prefferedFullName, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });
    return parse(state.value.prefferedFullName, matches);
  }
};
