import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid2 as MuiGrid,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Tooltip as MuiTooltip,
} from "@mui/material";
import {
  NavigateNext as MuiNavigateNextIcon,
  Warning as MuiWarningIcon,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";

/** Custom Components */
import { FormButton } from "app/shared/ui/Button/FormButton";
import { Spinner } from "app/shared/ui/Loading/Spinner";
import {
  submissionType,
  monthOptions,
  operationStatusEnums,
  SUB_OPERATION_STATUS,
} from "app/shared/constants";
import { buildYearRange } from "app/shared/utils";
import { Autocomplete } from "app/shared/ui/Autocomplete/Autocomplete";
import { AutocompleteOption } from "app/shared/ui/Autocomplete";
import { SubmissionAutocomplete } from "./SubmissionAutocomplete";
/** Services */
import { useSubmissionsAPI } from "app/services/useSubmissionsAPI";
import { sharedStyles } from "app/shared/ui/sharedStyles";

export const CreateEditSubmissionForm = (props) => {
  const navigate = useNavigate();
  const submissionId = useParams().submissionId;
  const { loadSubmissionValues, saveSubmissionValues, isLoading, isSaving } =
    useSubmissionsAPI();
  const { t } = useTranslation();
  const yearOptions = buildYearRange(5, 5);
  // state to track if grant is already assigned or not
  const [isGrantAssigned, setIsGrantAssigned] = useState(false);
  // state to store initial submission values
  const [submissionValues, setSubmissionValues] = useState({
    name: "",
    year: "",
    month: "",
    admins: [],
    observers: [],
    grant: null,
  });

  useEffect(() => {
    if (submissionId) {
      loadSubmissionValues(
        submissionId,
        setSubmissionValues,
        setIsGrantAssigned
      );
    }
  }, [submissionId, loadSubmissionValues]);

  const formik = useFormik({
    initialValues: submissionValues,
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        .trim()
        .required(
          t("submission.create.form.fields.submissionName.validation.isEmpty")
        ),
      year: yup
        .string()
        .required(
          t("submission.create.form.fields.submissionYear.validation.isEmpty")
        ),
      month: yup
        .string()
        .required(
          t("submission.create.form.fields.submissionMonth.validation.isEmpty")
        ),
    }),
    onSubmit: (values, { setFieldError }) => {
      saveSubmissionValues(props.type, submissionId, values, setFieldError);
    },
  });

  const handleCancel = () => {
    navigate("/admin/submissions");
  };

  // Returns the value of the grant attribute
  const getGrantAttributeValue = (attribute) => {
    const grant = formik.values.grant;
    if (!grant) return "";
    return isGrantAssigned
      ? grant[attribute] ?? ""
      : grant.value[attribute] ?? "";
  };

  return (
    <MuiGrid
      container
      wrap="nowrap"
      justify="flex-start"
      direction="column"
      spacing={2}
    >
      <Spinner visible={isLoading || isSaving} />
      <MuiGrid>
        <MuiBreadcrumbs
          separator={<MuiNavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={sharedStyles.breadcrumb}
        >
          <RouteLink to="/admin/submissions">
            {t("submission.manage.title")}
          </RouteLink>
          <MuiTypography color="textPrimary">
            {props.type === submissionType.create
              ? t("submission.create.title")
              : t("submission.edit.title")}
          </MuiTypography>
        </MuiBreadcrumbs>
      </MuiGrid>
      <MuiGrid>
        <MuiTypography variant="h5">
          {props.type === submissionType.create
            ? t("submission.create.title")
            : t("submission.edit.title")}
        </MuiTypography>
      </MuiGrid>
      <MuiGrid>
        <form onSubmit={formik.handleSubmit} style={{ display: "flex" }}>
          <MuiGrid
            container
            direction="column"
            justify="space-between"
            spacing={2}
            sx={{ flex: "0.2 0 auto" }}
          >
            {!isGrantAssigned && (
              <MuiGrid size={11}>
                <Autocomplete
                  id="grantAutoComplete"
                  key="submission-grant"
                  size="medium"
                  getOptionLabel={(option) =>
                    option &&
                    (option?.displayText ??
                      [
                        option?.spoNumber,
                        option?.grantNumber,
                        option?.title,
                      ].join(", "))
                  }
                  value={formik.values.grant}
                  label={t(`submission.create.form.fields.grant.label`)}
                  noOptionsText={t(
                    `submission.create.form.fields.grant.noOptionText`
                  )}
                  onChange={(e, value) => {
                    formik.setFieldValue("grant", value);
                    if (!formik?.values?.name) {
                      formik.setFieldValue("name", value?.value?.title ?? "");
                    }
                  }}
                  disabled={
                    props.type === submissionType.edit &&
                    (Boolean(formik.initialValues.grant) ||
                      formik.values?.operationStatus !==
                        SUB_OPERATION_STATUS.READY)
                  }
                  autoCompleteUrlPrefix={`/grants/autocomplete?`}
                  filterOptions={(options) => options}
                  renderOption={(props, state, { inputValue }) => (
                    <AutocompleteOption
                      key={state.displayText}
                      option={props}
                      inputValue={inputValue}
                      state={state}
                    />
                  )}
                />
              </MuiGrid>
            )}
            <MuiGrid container>
              <MuiGrid size={5.5}>
                <MuiTextField
                  name="SPONumber"
                  label={t("submission.create.form.fields.spoNumber.label")}
                  value={getGrantAttributeValue("spoNumber")}
                  variant="outlined"
                  disabled
                  fullWidth
                />
              </MuiGrid>
              <MuiGrid size={5.5}>
                <MuiTextField
                  name="AwardNumber"
                  label={t("submission.create.form.fields.awardNumber.label")}
                  value={getGrantAttributeValue("grantNumber")}
                  variant="outlined"
                  disabled
                  fullWidth
                />
              </MuiGrid>
            </MuiGrid>
            <MuiGrid size={11}>
              <MuiTextField
                name="GrantTitle"
                label={t("submission.create.form.fields.grantTitle.label")}
                value={getGrantAttributeValue("title")}
                variant="outlined"
                disabled
                fullWidth
              />
            </MuiGrid>
            <MuiGrid size={11}>
              <MuiTextField
                name="name"
                label={t("submission.create.form.fields.submissionName.label")}
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{ maxLength: 200 }}
                autoComplete="off"
                variant="outlined"
                fullWidth
              />
            </MuiGrid>
            <MuiGrid container>
              <MuiGrid size={5.5}>
                <MuiTextField
                  name="year"
                  select
                  label={t(
                    "submission.create.form.fields.submissionYear.label"
                  )}
                  required
                  value={formik.values.year}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && Boolean(formik.errors.year)}
                  helperText={formik.touched.year && formik.errors.year}
                  variant="outlined"
                  disabled={props.type === submissionType.edit}
                  fullWidth
                >
                  {yearOptions.map((option) => (
                    <MuiMenuItem key={option} value={option}>
                      {option}
                    </MuiMenuItem>
                  ))}
                </MuiTextField>
              </MuiGrid>
              <MuiGrid size={5.5}>
                <MuiTextField
                  name="month"
                  select
                  label={t(
                    "submission.create.form.fields.submissionMonth.label"
                  )}
                  required
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && Boolean(formik.errors.month)}
                  helperText={formik.touched.month && formik.errors.month}
                  variant="outlined"
                  disabled={props.type === submissionType.edit}
                  fullWidth
                >
                  {monthOptions.map((option) => {
                    const submissionMonth =
                      option.toString().length < 2 ? `0${option}` : option;
                    return (
                      <MuiMenuItem key={option} value={option}>
                        {submissionMonth}
                      </MuiMenuItem>
                    );
                  })}
                </MuiTextField>
              </MuiGrid>
            </MuiGrid>

            <MuiGrid size={11}>
              <SubmissionAutocomplete formik={formik} fieldName="admins" />
            </MuiGrid>
            <MuiGrid size={11}>
              <SubmissionAutocomplete formik={formik} fieldName="observers" />
            </MuiGrid>
            <MuiGrid size={12}>
              <MuiGrid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                spacing={1}
              >
                <MuiGrid size={11}>
                  <MuiTextField
                    fullWidth
                    disabled
                    variant="outlined"
                    name="operationStatus"
                    label={t(
                      "submission.create.form.fields.operationStatus.label"
                    )}
                    value={t(
                      operationStatusEnums[formik.values.operationStatus]
                    )}
                  />
                </MuiGrid>
                <MuiGrid
                  size={1}
                  sx={{
                    "&&": {
                      paddingTop: "16px",
                    },
                  }}
                >
                  {formik.values.operationStatus ===
                    SUB_OPERATION_STATUS.FAILED && (
                    <MuiTooltip title={formik.values.failureReason}>
                      <MuiWarningIcon fontSize="medium" color="warning" />
                    </MuiTooltip>
                  )}
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid size={11}>
              <MuiGrid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <MuiGrid>
                  <FormButton
                    sx={{ fontSize: "16px" }}
                    onClick={handleCancel}
                    name={t("globals.form.actionButtons.cancel")}
                    variant="outlined"
                  />
                </MuiGrid>
                <MuiGrid>
                  <FormButton
                    name={t("globals.form.actionButtons.save")}
                    type="submit"
                    sx={{ fontSize: "16px" }}
                    variant="contained"
                    disabled={isSaving || !formik.isValid || !formik.dirty}
                  />
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </form>
      </MuiGrid>
    </MuiGrid>
  );
};
