import { Theme } from "common";
export const facultyDetailsStyles = {
  actionButton: {
    padding: "5px 0px 0px 0px",
    "& .MuiIconButton-root": {
      fontSize: "0.5rem",
    },
  },
  actionContent: {
    display: "flex",
  },
  hideVisiblity: {
    visibility: "hidden",
  },
  noData: {
    textAlign: "center",
    color: Theme.palette.grey[500],
  },
  collapseWidth: {
    width: "92%",
  },
  collapseIcon: {
    padding: "0px 0px 2px 0px",
  },
  collapseRowBackgroundColor: {
    backgroundColor: Theme.palette.primary.light,
    "& .MuiTableCell-head": {
      color: Theme.palette.grey[700],
      fontSize: 13,
      fontWeight: 600,
    },
    "& .MuiTableCell-body": {
      borderBottom: "unset",
      fontSize: 13,
      fontWeight: 500,
    },
  },
  waitToSyncIcon: {
    bottom: "-.2em",
    position: "relative",
    marginLeft: "1em",
    "&.MuiSvgIcon-colorError": {
      color: "red",
    },
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  rotate: {
    animation: "$spin 2s linear infinite",
  },
};
