import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { grantType, submissionType, userRoles } from "app/shared/constants";
import { useAlerts } from "common";

let timeoutID;
export const useSubmissionsAPI = () => {
  const { clearAlert, setAlert } = useAlerts();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const loadSubmissionValues = useCallback(
    async (id, setSubmissionValues, setIsGrantAssigned) => {
      try {
        clearAlert();
        setIsLoading(true);

        const response = await axios.get(`/submissions/${id}`);
        // Update state to reflect if grant is assigned to submission
        if (response.data.grant) {
          setIsGrantAssigned(true);
        }

        setSubmissionValues({
          name: response.data.name,
          year: response.data.year,
          month: response.data.month,
          admins:
            response.data.assignees?.filter(
              (assignee) => assignee.role === userRoles.submissionAdmin
            ) ?? [],
          observers:
            response.data.assignees?.filter(
              (assignee) => assignee.role === userRoles.observer
            ) ?? [],
          grant: response.data.grant ?? null,
          operationStatus: response.data.operationStatus,
          failureReason: response.data.failureReason,
        });
      } catch (error) {
        setAlert("error", error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [clearAlert, setAlert]
  );

  const saveSubmissionValues = async (action, id, values, setFieldError) => {
    try {
      clearAlert();
      setIsSaving(true);

      const assignees = [...values.admins, ...values.observers];

      const data = {
        id,
        grantType,
        name: values.name.trim(),
        year: values.year,
        month: values.month,
        grant: values.grant?.value ?? values.grant,
        ...(assignees.length > 0 && { assignees }),
      };

      if (action === submissionType.create) {
        await axios.post("/submissions", data);
        navigate({ pathname: "/admin/submissions" });
        clearTimeout(timeoutID); // Cancel current debounce timer

        // Start debounce timer and call API when timer finishes
        timeoutID = setTimeout(() => {
          setAlert(
            "success",
            t("submission.create.notification.success", {
              submissionName: values.name.trim(),
            }),
            true
          );
        }, 500);
      } else if (action === submissionType.edit) {
        console.log({ data });
        await axios.put(`/submissions/${id}`, data);
        navigate({ pathname: "/admin/submissions" });
        clearTimeout(timeoutID); // Cancel current debounce timer

        // Start debounce timer and call API when timer finishes
        timeoutID = setTimeout(() => {
          setAlert(
            "success",
            t("submission.create.notification.update", {
              submissionName: values.name.trim(),
            }),
            true
          );
        }, 500);
      }
    } catch (error) {
      setIsSaving(false);
      if (error.status === 409) {
        setFieldError(
          "name",
          t(
            "submission.create.form.fields.submissionName.validation.isDuplicate"
          )
        );
      } else {
        setAlert("error", error.message);
      }
    }
  };

  return { loadSubmissionValues, saveSubmissionValues, isLoading, isSaving };
};
