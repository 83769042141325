import { useTranslation } from "react-i18next";
import {
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from "@mui/material";
import { grantDetailsStyles } from "./GrantDetails.styles";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";

const GrantsDetailsTableHead = ({ order, orderBy, onRequestSort }) => {
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead sx={whiteTableHeaderLabel}>
      <MuiTableRow
        sx={{
          verticalAlign: "top",
        }}
      >
        <MuiTableCell sx={grantDetailsStyles.scroll30} />
        <MuiTableCell
          align="left"
          key="spoNumber"
          sx={grantDetailsStyles.scroll90}
          sortDirection={orderBy === "spoNumber" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("spoNumber")}
            active={orderBy === "spoNumber"}
          >
            {t("grant.mainView.list.tableColumns.spoNumber")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="title"
          sx={grantDetailsStyles.scroll154}
          sortDirection={orderBy === "title" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "title" ? order : "asc"}
            onClick={createSortHandler("title")}
            active={orderBy === "title"}
          >
            {t("grant.mainView.list.tableColumns.title")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="awardNumber"
          sx={grantDetailsStyles.scroll90}
          sortDirection={orderBy === "awardNumber" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("grantNumber")}
            active={orderBy === "grantNumber"}
          >
            {t("grant.mainView.list.tableColumns.awardNumber")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="projectPeriod"
          sortDirection={orderBy === "projectPeriod" ? order : "asc"}
          sx={grantDetailsStyles.scroll80}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("startDate")}
            active={orderBy === "startDate"}
          >
            {t("grant.mainView.list.tableColumns.projectPeriod")}
          </MuiTableSortLabel>
        </MuiTableCell>

        <MuiTableCell
          align="left"
          key="preDocsPositions"
          sortDirection={orderBy === "preDocsPositions" ? order : "asc"}
          sx={grantDetailsStyles.scroll80}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("predocPositionsCount")}
            active={orderBy === "predocPositionsCount"}
          >
            {t("grant.mainView.list.tableColumns.preDocsPositions")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="postDocsPositions"
          sortDirection={orderBy === "postDocsPositions" ? order : "asc"}
          sx={grantDetailsStyles.scroll84}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("postdocPositionsCount")}
            active={orderBy === "postdocPositionsCount"}
          >
            {t("grant.mainView.list.tableColumns.postDocsPositions")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="shortTermPositions"
          sortDirection={orderBy === "shortTermPositions" ? order : "asc"}
          sx={grantDetailsStyles.scroll84}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("numberOfShortTermPositions")}
            active={orderBy === "numberOfShortTermPositions"}
          >
            {t("grant.mainView.list.tableColumns.shortTermPositions")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="budgetPeriodStartDate"
          sortDirection={orderBy === "budgetPeriodStartDate" ? order : "asc"}
          sx={grantDetailsStyles.scroll80}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("budgetPeriodStartDate")}
            active={orderBy === "budgetPeriodStartDate"}
          >
            {t("grant.mainView.list.tableColumns.currentBudgetPeriod")}
          </MuiTableSortLabel>
        </MuiTableCell>

        <MuiTableCell
          align="left"
          key="budgetPeriodDirectCost"
          sortDirection={orderBy === "budgetPeriodDirectCost" ? order : "asc"}
          sx={grantDetailsStyles.scroll90}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("budgetPeriodDirectCost")}
            active={orderBy === "budgetPeriodDirectCost"}
          >
            {t("grant.mainView.list.tableColumns.budgetPeriodDirectCost")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="budgetPeriodTotalCost"
          sortDirection={orderBy === "budgetPeriodTotalCost" ? order : "asc"}
          sx={grantDetailsStyles.scroll90}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("budgetPeriodTotalCost")}
            active={orderBy === "budgetPeriodTotalCost"}
          >
            {t("grant.mainView.list.tableColumns.budgetPeriodTotalCost")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="department"
          sx={grantDetailsStyles.scroll154}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("department")}
            active={orderBy === "department"}
          >
            {t("grant.mainView.list.tableColumns.department")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="grantYear"
          sx={grantDetailsStyles.scroll60}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("grantYear")}
            active={orderBy === "grantYear"}
          >
            {t("grant.mainView.list.tableColumns.grantYear")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          sx={grantDetailsStyles.scroll108}
          align="left"
          key="participatingFaculty"
          sortDirection={orderBy === "participatingFaculty" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("numberOfParticipatingFaculty")}
            active={orderBy === "numberOfParticipatingFaculty"}
          >
            {t("grant.mainView.list.tableColumns.participatingFaculty")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="overLappingFaculty"
          sortDirection={orderBy === "overLappingFaculty" ? order : "asc"}
          sx={grantDetailsStyles.scroll103}
        >
          {t("grant.mainView.list.tableColumns.overLappingFaculty")}
        </MuiTableCell>
        <MuiTableCell
          key="actions"
          align={"left"}
          sx={grantDetailsStyles.scroll90}
        >
          {t("grant.mainView.list.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default GrantsDetailsTableHead;
