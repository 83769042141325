import { useState, createContext, useContext, useCallback } from "react";

const SelectedSubmissionContext = createContext();

export const SelectedSubmissionProvider = ({ children }) => {
  const [metaData, setMetaData] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [submissionUpdate, setSubmissionUpdate] = useState(false);

  const getSelectedSubmission = () => {
    return localStorage.getItem("selectedSubmissionId");
  };

  const setSelectedSubmission = (value) => {
    localStorage.setItem("selectedSubmissionId", value);
  };

  const getSelectedSubmissionYear = () => {
    return localStorage.getItem("selectedSubmissionYear");
  };

  const setSelectedSubmissionYear = (value) => {
    localStorage.setItem("selectedSubmissionYear", value);
  };

  const getSelectedSubmissionName = () => {
    return localStorage.getItem("selectedSubmissionName");
  };

  const setSelectedSubmissionName = (value) => {
    localStorage.setItem("selectedSubmissionName", value);
  };

  const getSelectedSubmissionMonth = () => {
    return localStorage.getItem("selectedSubmissionMonth");
  };

  const setSelectedSubmissionMonth = (value) => {
    localStorage.setItem("selectedSubmissionMonth", value);
  };

  /* Function to handle the Value change on Submission Dropdown, so as to reload the filter Panel */
  function handleSubmissionUpdate() {
    setSubmissionUpdate(true);
  }
  const changeFilterData = useCallback((data) => {
    setSubmissionUpdate(false);
    setFilterData(data);
  }, []);

  return (
    <SelectedSubmissionContext.Provider
      value={{
        value: [getSelectedSubmission, setSelectedSubmission],
        metaData,
        setMetaData,
        year: [getSelectedSubmissionYear, setSelectedSubmissionYear],
        name: [getSelectedSubmissionName, setSelectedSubmissionName],
        month: [getSelectedSubmissionMonth, setSelectedSubmissionMonth],
        changeFilterData,
        handleSubmissionUpdate,
        filterData,
        submissionUpdate,
      }}
    >
      {children}
    </SelectedSubmissionContext.Provider>
  );
};

export const useSelectedSubmission = () => {
  return useContext(SelectedSubmissionContext);
};
