import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { whiteTableHeaderLabel } from "app/shared/ui/sharedStyles";

export const ManageSubmissionTableHeader = ({
  order,
  orderBy,
  onRequestSort,
}) => {
  const { t } = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <MuiTableHead sx={whiteTableHeaderLabel}>
      <MuiTableRow>
        <MuiTableCell
          align="left"
          key="name"
          sortDirection={orderBy === "name" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "name"}
            direction={orderBy === "name" ? order : "asc"}
            onClick={createSortHandler("name")}
          >
            {t("submission.manage.submissionList.tableColumns.submissionName")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="year"
          sortDirection={orderBy === "year" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "year"}
            direction={orderBy === "year" ? order : "asc"}
            onClick={createSortHandler("year")}
          >
            {t(
              "submission.manage.submissionList.tableColumns.submissionYearMonth"
            )}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="operationStatus"
          sortDirection={orderBy === "operationStatus" ? order : false}
        >
          <MuiTableSortLabel
            active={orderBy === "operationStatus"}
            direction={orderBy === "operationStatus" ? order : "asc"}
            onClick={createSortHandler("operationStatus")}
          >
            {t("submission.manage.submissionList.tableColumns.operationStatus")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="spoNumbers">
          {t("submission.manage.submissionList.tableColumns.spoNumber")}
        </MuiTableCell>
        <MuiTableCell align="left" key="submissionAdmins">
          {t("submission.manage.submissionList.tableColumns.submissionAdmin")}
        </MuiTableCell>
        <MuiTableCell align="left" key="observers">
          {t("submission.manage.submissionList.tableColumns.observer")}
        </MuiTableCell>
        <MuiTableCell align="left" key="actions">
          {t("submission.manage.submissionList.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};
