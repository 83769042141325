import { Theme } from "common";

export const outputTableStyles = {
  actionButton: {
    "& button": {
      height: "40px",
    },
  },
  root: {
    margin: 3,
    backgroundColor: Theme.palette.background.paper,
    "& .MuiButtonRoot": {
      marginRight: 0,
      marginBottom: 15,
    },
    "& *": {
      fontFamily: "Source Sans Pro, sans-serif",
    },
  },
  tableOne: {
    minWidth: 650,
    "& thead tr th:first-of-type": {
      minWidth: 280,
      fontWeight: 700,
    },
    "& thead tr th:not(:first-of-type)": {
      textAlign: "right",
      fontWeight: 700,
    },
    "& tbody td:not(:first-of-type) ": {
      textAlign: "right",
    },
  },
  container: {
    marginTop: "16px",
    width: "100%",
    minWidth: "95em",
    overflowX: "hidden",

    backgroundColor: Theme.palette.background.paper,
    "& .MuiTableContainerRoot": {
      boxShadow: "-1px 2px 3px 2px #c4c4c466",
      border: "1px solid #e0e0e0",
      overflowX: "inherit",
    },
    "& .MuiButtonRoot": {
      marginRight: 0,
      marginBottom: 15,
    },
    "& .table1": {
      minWidth: 650,
      "& thead tr th:first-of-type": {
        minWidth: 305,
      },
    },
    "& *": {
      fontFamily: "Source Sans Pro, sans-serif",
    },
    "& .MuiTypography-root": {
      whiteSpace: "pre-line",
    },
  },
};
