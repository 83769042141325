import { Tooltip as MuiTooltip } from "@mui/material";
import { Info as MuiInfoIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const AuditInfo = (props) => {
  const { t } = useTranslation();
  /*TGT-1166:
    The undefined condition is added to allow the fields to display audit icon on Faculty tab
    as we do not have an isActive flag in the response to validatw against.
  */
  let auditCondition =
    typeof props.isActive != "undefined"
      ? props.isEdited && props.isActive
      : props.isEdited;
  if (auditCondition) {
    return (
      <MuiTooltip title={t("globals.list.actionIcons.auditIcon.tooltip")}>
        <MuiInfoIcon
          fontSize="small"
          sx={{
            fill: "#e98300",
            position: "inherit",
            marginLeft: "2px",
            verticalAlign: "inherit",
          }}
        />
      </MuiTooltip>
    );
  } else {
    return "";
  }
};
