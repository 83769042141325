import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  Collapse as MuiCollapse,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@mui/material";

const CollapseTable = (props) => {
  let header = null;
  if (props.header) {
    header = Object.entries(props.header).map((resHeader) => {
      return <MuiTableCell key={resHeader[0]}>{resHeader[1]}</MuiTableCell>;
    });
  }

  let body = null;
  if (props.body) {
    body = Object.entries(props.body).map((response, index) => {
      return (
        <MuiTableRow key={response[0]}>
          {Object.entries(response[1]).map((row, rindex) => {
            return <MuiTableCell key={rindex}>{row[1]}</MuiTableCell>;
          })}
        </MuiTableRow>
      );
    });
  }
  return (
    <MuiCollapse
      in={props.open}
      timeout="auto"
      unmountOnExit
      sx={props.tableClass ?? ""}
    >
      <MuiTable size="small" aria-label={props.label}>
        <MuiTableHead>
          <MuiTableRow>{header}</MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>{body}</MuiTableBody>
      </MuiTable>
    </MuiCollapse>
  );
};

export default CollapseTable;
