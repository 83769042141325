import { Theme } from "common";

export const outcomesStyles = {
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  button: {
    borderRadius: "5px",
    backgroundColor: "#009abb",
    fontSize: "16px",
    color: "#ffffff",
    marginLeft: "8px",
    "&:hover": {
      background: Theme.palette.primary.dark,
    },
  },
  exportButton: {
    borderRadius: "5px",
    marginLeft: "8px",
    "&:hover": {
      background: Theme.palette.primary.light,
    },
  },
  inputPanelWrapper: {
    width: "100%",
  },
  autocompleteOption: {},
  dummyAutocompleteOption: {
    color: Theme.palette.grey[800],
  },
};
