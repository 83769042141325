// NOTE: This file is maintained in the parent project AS Common.
//       Your application should avoid modifying this file.

import { AlertsProvider, useAuth, UsersProvider, Page } from "common";
import { CssBaseline as MuiCssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { App } from "app/App";
import { appTheme } from "app/theme";

export const AppRoot = () => {
  const auth = useAuth();

  return (
    <div>
      {(process.env.REACT_APP_ENVIRONMENT === "test" ||
        auth.isAuthenticated) && (
        <MuiThemeProvider theme={appTheme}>
          <MuiCssBaseline />
          <BrowserRouter>
            <AlertsProvider>
              <UsersProvider>
                <Page>
                  <App />
                </Page>
              </UsersProvider>
            </AlertsProvider>
          </BrowserRouter>
        </MuiThemeProvider>
      )}
    </div>
  );
};
