import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Info as MuiInfoIcon } from "@mui/icons-material";
import CustomDialog from "app/shared/ui/Dialog/Dialog";
import CustomTable from "app/shared/ui/Table/Table";
import { DataSourceEnums } from "app/shared/constants";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { formatDate } from "app/shared/utils";

export const MetaDataInformation = (props) => {
  const location = useLocation();
  const { metaData } = useSelectedSubmission();
  let dialogBox = null;
  const [open, setOpen] = useState(false);
  const handleCloseHandler = () => {
    setOpen(false);
  };
  const modalTitle = "Data Source(s)";
  const modalHeader = {
    dataSource: "Data Source",
    updated: "Updated",
  };
  const { t } = useTranslation();
  const filterTable1PopUpData = (data) => {
    const response = {};
    data?.forEach((record, index) => {
      response[index] = {
        dataSource: t(DataSourceEnums[record["name"]]) ?? "",
        updated: formatDate(record["lastSynced"], "MM/dd/yyyy HH:mm a z"),
      };
    });
    return response;
  };
  const modalBoxBody = filterTable1PopUpData(metaData.sources);
  dialogBox = (
    <CustomDialog
      closeButtonText="CLOSE"
      title={modalTitle}
      description={!!props.modalDescription && props.modalDescription}
      open={open}
      handleClose={handleCloseHandler}
      showGeneralHelpSection={true}
      extraClass="applicantDialogBox"
    >
      <CustomTable
        sticky
        label={modalTitle}
        header={modalHeader}
        body={modalBoxBody}
      />
    </CustomDialog>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      {metaData &&
        !(
          location.pathname.match("output") ||
          location.pathname.match("submissions")
        ) && (
          <span>
            <MuiInfoIcon
              fontSize="small"
              onClick={handleClickOpen}
              sx={{
                cursor: "pointer",
                paddingTop: "4px",
                marginLeft: "4px",
              }}
            />
            {dialogBox}
          </span>
        )}
    </>
  );
};
