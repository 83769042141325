import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { Typography as MuiTypography } from "@mui/material";
/**  Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useOutcomesContext } from "app/services/outcomesContext";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "app/services/outcomesSelectedContext";
import { deleteSelectedOutcomes } from "app/services/outcomesService";
/* Context and Component to show the error on UI */
import { useAlerts } from "common";

export const DeleteOutcomes = (props) => {
  const {
    openDeleteOutcomesDialog,
    setOpenDeleteOutcomesDialog,
    toggleDeleteOutcomes,
    totalCount,
  } = props;

  const { setAlert, clearAlert } = useAlerts();

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const submissionId = getSelectedSubmission();

  const { outcomesPreferences, setOutcomesPreferences } = useOutcomesContext();
  const { resetAllSelections, outcomesSelected } = useOutcomesSelectedContext();

  const { t } = useTranslation();

  const handleCancel = () => {
    clearAlert();
    setOpenDeleteOutcomesDialog(false);
  };

  const [countToDelete, setCountToDelete] = useState(0);

  useEffect(() => {
    if (outcomesSelected.selectAllState === SelectAllStates.NONE_SELECTED) {
      setCountToDelete(
        Object.keys(outcomesSelected.individualSelections).filter(
          (key) => outcomesSelected.individualSelections[key] === true
        ).length
      );
    } else {
      setCountToDelete(
        totalCount -
          Object.keys(outcomesSelected.individualSelections).filter(
            (key) => outcomesSelected.individualSelections[key] === false
          ).length
      );
    }
  }, [totalCount, outcomesSelected, setCountToDelete]);

  return (
    <ConfirmationDialog
      open={openDeleteOutcomesDialog}
      message={
        <>
          <MuiTypography variant="body1" style={{ fontWeight: 700 }}>
            {t("outcomes.mainView.delete.confirmationBox.body", {
              count: countToDelete,
              plural: countToDelete !== 1 ? "s" : "",
            })}
          </MuiTypography>
          <MuiTypography variant="body1">
            {t("globals.list.messages.wantToContinue")}
          </MuiTypography>
        </>
      }
      title={t("outcomes.mainView.delete.confirmationBox.title")}
      handleCancel={() => {
        handleCancel();
      }}
      handleOk={() =>
        deleteSelectedOutcomes(
          submissionId,
          setOpenDeleteOutcomesDialog,
          setAlert,
          clearAlert,
          resetAllSelections,
          toggleDeleteOutcomes,
          setOutcomesPreferences,
          outcomesPreferences,
          outcomesSelected,
          t
        )
      }
      okLabel={t("outcomes.mainView.delete.confirmationBox.okLabel")}
      cancelLabel={t("outcomes.mainView.delete.confirmationBox.cancelLabel")}
    />
  );
};
