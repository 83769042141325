import { Theme } from "common";

export const traineeDetailsStyles = {
  noDataTableCell: {
    verticalAlign: "middle",
    textAlign: "center",
    color: Theme.palette.grey[500],
  },
  noDataTableheading: {
    color: Theme.palette.grey[500],
  },
  actionButton: {
    padding: "5px 0px 0px 0px",
    "& .MuiIconButton-root": {
      fontSize: "0.5rem",
      padding: "8px",
    },
  },
  disableRow: {
    color: Theme.palette.grey[300],
  },
};
