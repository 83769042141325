import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { Typography as MuiTypography } from "@mui/material";
/**  Services */
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import { useTraineeContext } from "app/services/traineeContext";
import {
  useTraineesSelectedContext,
  SelectAllStates,
} from "app/services/traineesSelectedContext";
import { removeSelectedTrainees } from "app/services/traineeService";
/** Context and Component to show the error on UI */
import { useAlerts } from "common";

export const RemoveTrainees = (props) => {
  const {
    openRemoveTraineesDialog,
    setOpenRemoveTraineesDialog,
    toggleTraineesRemoved,
    totalCount,
  } = props;

  const [setTraineeList] = useState([]);
  const { setAlert, clearAlert } = useAlerts();

  const { setTraineePreferences, traineePreferences } = useTraineeContext();

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const submissionId = getSelectedSubmission();

  const { resetAllSelections, traineesSelected } = useTraineesSelectedContext();

  const { t } = useTranslation();
  const [countToDelete, setCountToDelete] = useState(0);

  useEffect(() => {
    if (traineesSelected.selectAllState === SelectAllStates.NONE_SELECTED) {
      setCountToDelete(
        Object.keys(traineesSelected.individualSelections).filter(
          (key) => traineesSelected.individualSelections[key] === true
        ).length
      );
    } else {
      setCountToDelete(
        totalCount -
          Object.keys(traineesSelected.individualSelections).filter(
            (key) => traineesSelected.individualSelections[key] === false
          ).length
      );
    }
  }, [totalCount, traineesSelected, setCountToDelete]);

  const handleCancel = () => {
    clearAlert();
    setOpenRemoveTraineesDialog(false);
  };

  return (
    <ConfirmationDialog
      open={openRemoveTraineesDialog}
      message={
        <>
          <MuiTypography variant="body1" style={{ fontWeight: 700 }}>
            {t("trainee.mainView.delete.confirmationBox.body", {
              count: countToDelete,
              plural: countToDelete !== 1 ? "s" : "",
            })}
          </MuiTypography>
          <MuiTypography variant="body1">
            {t("globals.list.messages.wantToContinue")}
          </MuiTypography>
        </>
      }
      title={t("trainee.mainView.delete.confirmationBox.title")}
      handleCancel={() => handleCancel()}
      handleOk={() =>
        removeSelectedTrainees(
          submissionId,
          setOpenRemoveTraineesDialog,
          setAlert,
          clearAlert,
          resetAllSelections,
          toggleTraineesRemoved,
          setTraineeList,
          setTraineePreferences,
          traineePreferences,
          traineesSelected,
          t
        )
      }
      okLabel={t("trainee.mainView.delete.confirmationBox.okLabel")}
      cancelLabel={t("trainee.mainView.delete.confirmationBox.cancelLabel")}
    />
  );
};
