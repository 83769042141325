import { useTranslation } from "react-i18next";
import {
  TableBody as MuiTableBody,
  Typography as MuiTypography,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { traineeTypeDropDown } from "app/shared/constants";
import AddRemoveActionIcons from "app/shared/ui/Layout/AddRemoveActionIcons/AddRemoveActionIcons";
import {
  useTraineesSelectedContext,
  SelectAllStates,
} from "app/services/traineesSelectedContext";
import { AuditInfo } from "app/shared/ui/AuditInfo/AuditInfo";
/** Styles */
import { traineeDetailsStyles } from "./TraineeDetails.styles";
import { Theme } from "common";

const TraineeDetailsTableBody = ({
  traineeList,
  handleConfirmation,
  toggleTrainee,
}) => {
  const { t } = useTranslation();

  const traineeDetailsTableColumns = "trainee.mainView.list.tableColumns";
  const notAvailableValue = t(`${traineeDetailsTableColumns}.notAvailable`);

  // **** Get the Value for Boolean Columns ****
  const booleanColumnValue = (columnValue) =>
    columnValue
      ? t(`${traineeDetailsTableColumns}.yes`)
      : t(`${traineeDetailsTableColumns}.no`);

  // If row is inactive, put the disableRow class
  const getRowClass = (isRowActive) =>
    !isRowActive ? traineeDetailsStyles.disableRow : {};
  const isSelected = (traineesSelected, row) => {
    if (!row.isActive) return false;

    const rowId = row._links.self.href;

    if (traineesSelected.selectAllState === SelectAllStates.ALL_SELECTED) {
      return true;
    } else if (
      traineesSelected.selectAllState === SelectAllStates.NONE_SELECTED
    ) {
      return !!traineesSelected.individualSelections[rowId];
    } else {
      return traineesSelected.individualSelections[rowId] !== false;
    }
  };
  const { traineesSelected, handleSelectionChange } =
    useTraineesSelectedContext();
  return (
    <MuiTableBody>
      {traineeList.values && traineeList.values.length > 0 ? (
        traineeList.values.map((row, i) => (
          <MuiTableRow
            key={row._links.self.href}
            sx={{
              verticalAlign: "top",
              color: row.isActive && Theme.palette.grey[300],
              "& .MuiIconButton-root": {
                height: "auto",
              },
            }}
          >
            <MuiTableCell align="left">
              <MuiCheckbox
                color="secondary"
                checked={isSelected(traineesSelected, row)}
                size="small"
                onChange={(event) => handleSelectionChange(event, row)}
                disabled={!row._links.addTrainee}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {row.trainee ? row.trainee.fullName : ""}
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {row.commonsId}
              <AuditInfo isEdited={row.commonsIdMod} isActive={row.isActive} />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {row.submissionTraineeFaculty &&
                row.submissionTraineeFaculty
                  .map((traineeFaculty) => traineeFaculty["fullName"])
                  .join("; ")}{" "}
              <AuditInfo
                isEdited={row.submissionTraineeFacultyMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {row.department.name}{" "}
              <AuditInfo
                isEdited={row.departmentsMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {row.appointmentStartDate &&
                format(parseISO(row.appointmentStartDate), "MM/dd/yyyy")}

              {row.appointmentStartDate || row.appointmentEndDate
                ? " - "
                : notAvailableValue}
              {row.appointmentEndDate &&
                format(parseISO(row.appointmentEndDate), "MM/dd/yyyy")}
              <AuditInfo
                isEdited={
                  row.appointmentStartDateMod || row.appointmentEndDateMod
                }
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {t(traineeTypeDropDown[row.traineeType])}{" "}
              <AuditInfo
                isEdited={row.traineeTypeMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {booleanColumnValue(row.currentTrainee)}{" "}
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {booleanColumnValue(row.grantEligible)}{" "}
              <AuditInfo
                isEdited={row.grantEligibleMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {booleanColumnValue(row.hhsSupport)}{" "}
              <AuditInfo isEdited={row.hhsSupportMod} isActive={row.isActive} />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {booleanColumnValue(row.tgeSupport)}{" "}
              <AuditInfo isEdited={row.tgeSupportMod} isActive={row.isActive} />
            </MuiTableCell>
            <MuiTableCell align="left" sx={getRowClass(row.isActive)}>
              {booleanColumnValue(row.r90Support)}{" "}
              <AuditInfo isEdited={row.r90SupportMod} isActive={row.isActive} />
            </MuiTableCell>
            <MuiTableCell align="left" sx={traineeDetailsStyles.actionButton}>
              <AddRemoveActionIcons
                row={row}
                isRowActive={row.isActive}
                rowId={row._links.self.href.split("/").pop()}
                editPermission={row._links.editSubmissionTraineeDetails}
                removePermission={row._links.removeSubmissionTrainee}
                addPermission={row._links.addTrainee}
                toggleActive={toggleTrainee}
                handleConfirmation={handleConfirmation}
                editPageUrl={`/trainees/${row._links.self.href
                  .split("/")
                  .pop()}/edit`}
              />
            </MuiTableCell>
          </MuiTableRow>
        ))
      ) : (
        <MuiTableRow sx="noData">
          <MuiTableCell colSpan="12" sx={traineeDetailsStyles.noDataTableCell}>
            <MuiTypography
              variant="h2"
              color="inherit"
              align="center"
              sx={traineeDetailsStyles.noDataTableheading}
            >
              {t("trainee.mainView.list.noData")}
            </MuiTypography>
          </MuiTableCell>
        </MuiTableRow>
      )}
    </MuiTableBody>
  );
};

export default TraineeDetailsTableBody;
