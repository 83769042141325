import { useEffect, useState } from "react";
import {
  Grid2 as MuiGrid,
  Dialog as MuiDialog,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from "@mui/material";
import { Help as MuiHelpIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
/***************** Service Imports ******************/
import { useAlerts } from "common";
import { copySubmission } from "app/services/submissionService";
/**************** Components Imports ***************/
import { Spinner } from "app/shared/ui/Loading/Spinner";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { Autocomplete } from "app/shared/ui/Autocomplete/Autocomplete";
import { SourceSubmissionTable } from "./SourceSubmissionTable";
import { DestinationSubmissionTable } from "./DestinationSubmissionTable";

// Copy Administrative Changes Dialog
export const CopySubmissionDialog = ({
  open,
  onClose,
  loading,
  setTableData,
  setTotalCount,
  submissionsList,
  fetchSubmissionsAfterCopy,
}) => {
  const { t } = useTranslation();
  const { clearAlert, setAlert } = useAlerts();

  // State variables
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [sourceSubValue, setSourceSubValue] = useState(null);
  const [sourceSubInputValue, setSourceSubInputValue] = useState("");

  const [destinationSubOptions, setDestinationSubOptions] = useState([]);
  const [destinationSubValue, setDestinationSubValue] = useState(null);
  const [destinationSubInputValue, setDestinationSubInputValue] = useState("");

  const [isCopyingSubmission, setIsCopyingSubmission] = useState(false);

  // Function to reset the values on close
  const resetValues = () => {
    setSourceSubValue(null);
    setSourceSubInputValue("");
    setDestinationSubValue(null);
    setDestinationSubInputValue("");
    setDestinationSubOptions([]);
  };
  // Function to handle the Yes button on confirmation dialog
  const handleConfirmationOk = async () => {
    setOpenConfirmation(false);
    await copySubmission(
      t,
      setAlert,
      clearAlert,
      sourceSubValue,
      destinationSubValue,
      setIsCopyingSubmission,
      fetchSubmissionsAfterCopy
    );
    onClose();
    resetValues();
  };
  // Function to handle the No button on confirmation dialog
  const handleConfirmationCancel = () => {
    setOpenConfirmation(false);
  };

  // useEffect to update the destination submission options based on the selected source submission
  useEffect(() => {
    if (sourceSubValue !== null) {
      const newDestinationSubOptions = submissionsList.filter(
        (sub) => sub.id !== sourceSubValue.id
      );
      setDestinationSubOptions(newDestinationSubOptions);
    } else {
      setDestinationSubOptions([]);
    }
  }, [submissionsList, sourceSubValue]);

  // Custom Tooltip increased max width
  const WiderMuiTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  return (
    <MuiDialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        onClose();
      }}
      PaperProps={{
        sx: {
          width: "1048px",
          maxWidth: "100%",
        },
      }}
      disableEscapeKeyDown={isCopyingSubmission}
    >
      <Spinner
        style={{
          zIndex: 20,
          position: "absolute",
          background: "rgba(255, 255, 255, 0.6)",
        }}
        visible={isCopyingSubmission}
      />
      <MuiDialogTitle>
        <MuiGrid container justifyContent="space-between">
          <MuiTypography variant="h2">
            {t("submission.copy.title")}
          </MuiTypography>
          <WiderMuiTooltip title={t("submission.copy.helpText")}>
            <MuiHelpIcon fontSize="small" />
          </WiderMuiTooltip>
        </MuiGrid>
      </MuiDialogTitle>

      <MuiDialogContent>
        <MuiGrid container direction="column" gap="24px">
          <MuiGrid container gap="12px" paddingTop="8px">
            <Autocomplete
              fullWidth
              clientSide
              autoHighlight
              size="medium"
              noOptionsText={t("globals.list.messages.noOptions")}
              label={t("submission.copy.labels.sourceSubmission")}
              clientSideLoading={loading}
              value={sourceSubValue}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.displayText}
              onChange={(event, newValue) => {
                setSourceSubValue(newValue);
                setDestinationSubValue(null);
                setDestinationSubInputValue("");
              }}
              inputValue={sourceSubInputValue}
              onInputChange={(event, newInputValue) =>
                setSourceSubInputValue(newInputValue)
              }
              options={submissionsList}
              disabled={isCopyingSubmission}
            />
            <SourceSubmissionTable sourceSubValue={sourceSubValue} />
          </MuiGrid>

          <MuiGrid container direction="column" gap="12px">
            <Autocomplete
              fullWidth
              clientSide
              autoHighlight
              size="medium"
              noOptionsText={t("globals.list.messages.noOptions")}
              label={t("submission.copy.labels.destinationSubmission")}
              disabled={!sourceSubValue || isCopyingSubmission}
              options={destinationSubOptions}
              value={destinationSubValue}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.displayText}
              onChange={(event, newValue) => setDestinationSubValue(newValue)}
              inputValue={destinationSubInputValue}
              onInputChange={(event, newInputValue) =>
                setDestinationSubInputValue(newInputValue)
              }
            />
            <DestinationSubmissionTable
              destinationSubValue={destinationSubValue}
            />
          </MuiGrid>
        </MuiGrid>
      </MuiDialogContent>

      <MuiDialogActions sx={{ padding: "0px 24px 16px 24px" }}>
        <MuiButton
          variant="outlined"
          color="primary"
          onClick={() => {
            onClose();
            resetValues();
          }}
          sx={{ minWidth: "100px" }}
          disabled={isCopyingSubmission}
        >
          {t("globals.form.actionButtons.cancel")}
        </MuiButton>
        <MuiButton
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenConfirmation(true);
          }}
          sx={{ minWidth: "100px" }}
          disabled={
            !sourceSubValue || !destinationSubValue || isCopyingSubmission
          }
        >
          {t("globals.form.actionButtons.copy")}
        </MuiButton>
      </MuiDialogActions>
      <ConfirmationDialog
        open={openConfirmation}
        message={t("submission.copy.confirmationMessage")}
        okLabel={t("globals.form.actionButtons.proceed")}
        handleOk={handleConfirmationOk}
        cancelLabel={t("globals.form.actionButtons.cancel")}
        handleCancel={handleConfirmationCancel}
      />
    </MuiDialog>
  );
};
