import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// **** Material UI Components ****
import {
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Link as MuiButtonLink,
} from "@mui/material";
import {
  AddCircle as MuiAddCircleIcon,
  RemoveCircle as MuiRemoveIcon,
  Edit as MuiEditIcon,
} from "@mui/icons-material";
import { Theme } from "common";

// **** Component to Show Add, Remove and Edit Icons in Table Cell ****
const AddRemoveActionIcons = ({
  row,
  isRowActive,
  rowId,
  editPermission,
  removePermission,
  addPermission,
  toggleActive,
  handleConfirmation,
  editPageUrl,
}) => {
  const { t } = useTranslation();
  const actionIconsLabel = "globals.list.actionIcons";

  // Button Icons
  const getAddDeleteButtonIcons = (
    titleText,
    buttonType,
    buttonIcon,
    actionFunction
  ) => (
    <MuiTooltip title={titleText}>
      <MuiIconButton onClick={() => actionFunction(rowId, row, buttonType)}>
        <MuiButtonLink aria-label={buttonType}>{buttonIcon}</MuiButtonLink>
      </MuiIconButton>
    </MuiTooltip>
  );

  return (
    <span
      style={{
        display: "flex",

        "& .MuiSvgIconRoot": {
          "&.MuiSvgIcon-colorDisabled": {
            color: Theme.palette.grey[300],
          },
        },
      }}
    >
      <span>
        <MuiTooltip
          title={isRowActive ? t(`${actionIconsLabel}.edit.tooltip`) : ""}
        >
          <MuiIconButton>
            {editPermission ? (
              isRowActive ? (
                <Link to={editPageUrl}>
                  <MuiEditIcon fontSize="small" />
                </Link>
              ) : (
                <MuiEditIcon color="disabled" fontSize="small" />
              )
            ) : (
              ""
            )}
          </MuiIconButton>
        </MuiTooltip>
      </span>
      <span>
        {row.isActive
          ? removePermission
            ? getAddDeleteButtonIcons(
                t(`${actionIconsLabel}.remove.tooltip`),
                "delete",
                <MuiRemoveIcon fontSize="small" />,
                handleConfirmation
              )
            : ""
          : addPermission
          ? getAddDeleteButtonIcons(
              t(`${actionIconsLabel}.add.tooltip`),
              "add",
              <MuiAddCircleIcon fontSize="small" />,
              toggleActive
            )
          : ""}
      </span>
    </span>
  );
};

export default AddRemoveActionIcons;
